import React from 'react'
import {Trans} from '@lingui/macro'
import {AddCircle, Assignment, Business, Equalizer, EventNote} from '@mui/icons-material'
import EventsPage from 'App/page/event/EventsPage'
import TopicsPage from 'App/page/topic/TopicsPage'
import OrganizationPage from 'App/page/organization/OrganizationsPage'
import {routesMap} from 'config/routesMap'
import CreateEventProposalPage from 'App/page/proposal/ProposalCreatePage'
import OrganizationDetailPage from 'App/page/organization/OrganizationDetailPage'
import EventDetailPage from 'App/page/event/EventDetailPage'
import ProposalDetailPage from 'App/page/proposal/ProposalDetailPage'
import EventCreatePage from 'App/page/event/EventCreatePage'
import EventEditPage from 'App/page/event/EventEditPage'
import StatisticsPage from 'App/page/statistic/StatisticsPage'

export const adminRoutes = [
  {
    path: routesMap.admin.topic,
    name: <Trans>Topics</Trans>,
    icon: Assignment,
    visible: true,
    exact: true,
    authorized: {subject: 'Topics', actions: 'read'},
    component: TopicsPage,
  },
  {
    path: routesMap.admin.statistic,
    name: <Trans>Statistics</Trans>,
    icon: Equalizer,
    visible: true,
    exact: true,
    authorized: {subject: 'Statistic', actions: 'read'},
    component: StatisticsPage,
  },
  {
    path: routesMap.admin.organization,
    name: <Trans>Partner organizations</Trans>,
    icon: Business,
    visible: true,
    exact: true,
    authorized: {subject: 'Organizations', actions: 'read'},
    component: OrganizationPage,
  },
  {
    path: `${routesMap.admin.organization}/:id`,
    name: <Trans>Partner organization</Trans>,
    icon: Business,
    visible: false,
    exact: true,
    authorized: {subject: 'Organization', actions: 'read'},
    component: OrganizationDetailPage,
  },
  {
    path: `${routesMap.admin.calendar}/new`,
    name: <Trans>Create new event</Trans>,
    icon: AddCircle,
    visible: false,
    exact: true,
    authorized: {subject: 'Events', actions: 'create'},
    component: EventCreatePage,
  },
  {
    path: `${routesMap.admin.proposal}/new`,
    name: <Trans>Create event proposal</Trans>,
    icon: AddCircle,
    visible: false,
    exact: true,
    authorized: {subject: 'Proposals', actions: 'create'},
    component: CreateEventProposalPage,
  },
  {
    path: `${routesMap.admin.calendar}/:id([0-9]+)/edit/:date`,
    name: <Trans>Edit event</Trans>,
    icon: Assignment,
    visible: false,
    exact: true,
    authorized: {subject: 'Events', actions: 'update'},
    component: EventEditPage,
  },
  {
    path: `${routesMap.admin.calendar}/:id([0-9]+)/date/:date`,
    name: <Trans>Event</Trans>,
    icon: EventNote,
    visible: false,
    exact: true,
    authorized: {subject: 'Event', actions: 'read'},
    component: EventDetailPage,
  },
  {
    path: `${routesMap.admin.proposal}/:id([0-9]+)`,
    name: <Trans>Proposal</Trans>,
    icon: EventNote,
    visible: false,
    exact: true,
    // authorized: {subject: 'Proposal', actions: 'read'},
    component: ProposalDetailPage,
  },
  {
    path: `${routesMap.admin.event}/:tab`,
    sidebarPath: `${routesMap.admin.event}/proposals`,
    name: <Trans>Events</Trans>,
    icon: EventNote,
    visible: true,
    exact: true,
    // authorized: {subject: 'Events', actions: 'read'}, // events and proposals on one page
    component: EventsPage,
  },
]
