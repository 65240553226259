import React, {useContext} from 'react'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {Add} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import EventCalendarFilters from 'component/projectSpecific/event/eventList/EventCalendarFilters'
import {AbilityContext, Can} from 'App/provider/authorizedAbility'
import {withStyles} from 'tss-react/mui'
import globalButtonStyle from 'asset/global/jss/component/common/button/globalButtonStyle'
import ReservationCalendarFilters from 'component/projectSpecific/event/eventList/ReservationCalendarFilters'
import {Box} from '@mui/material'

const EventPageActionHeader = (props) => {
  const {
    tab,
    openNewForm,
    setEventsFilter,
    selectedYear,
    eventsFilter,
    classes,
    eventsProposalsFilter,
    setEventsProposalsFilter,
  } = props

  const ability = useContext(AbilityContext)

  if (tab === 'proposals') {
    return (
      ability.can('create', 'Proposals') && (
        <GlobalButton
          onClick={openNewForm}
          backgroundColor="primary"
          startIcon={<Add />}
          className={classes.newButton}
        >
          {ability.can('manage', 'Internal') ? (
            <Trans>New reservation</Trans>
          ) : (
            <Trans>New proposal</Trans>
          )}
        </GlobalButton>
      )
    )
  } else if (tab === 'calendar') {
    return (
      <>
        <EventCalendarFilters
          setEventsFilter={setEventsFilter}
          eventsFilter={eventsFilter}
          selectedYear={selectedYear}
        />
        <Can I="create" an="Events">
          <Box pt={'5px'}>
            <GlobalButton
              onClick={openNewForm}
              backgroundColor="primary"
              startIcon={<Add />}
              className={classes.newButton}
            >
              <Trans>New event</Trans>
            </GlobalButton>
          </Box>
        </Can>
      </>
    )
  } else {
    return (
      <ReservationCalendarFilters
        eventsProposalsFilter={eventsProposalsFilter}
        setEventsProposalsFilter={setEventsProposalsFilter}
      />
    )
  }
}

EventPageActionHeader.propTypes = {
  classes: PropTypes.object,
  tab: PropTypes.node,
  proposalSearchSubmit: PropTypes.func,
  openNewForm: PropTypes.func,
  eventSearchSubmit: PropTypes.func,
  selectedYear: PropTypes.number,
  eventsFilter: PropTypes.object,
}

export default withStyles(EventPageActionHeader, globalButtonStyle)
