import React from 'react'
import {Trans} from '@lingui/macro'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators =
  (...validators) =>
  (value) => {
    return validators.reduce((error, validator) => error || validator(value), undefined)
  }

// required validation for input field
export const required = (value) => {
  return value ? undefined : <Trans>Required field</Trans>
}

export const requiredNumber = (value) => {
  const notZeroValue = value === 0 ? true : value
  return notZeroValue ? undefined : <Trans>Required field</Trans>
}

// form level validation of identity of passwords
export const samePasswordValidation = (values) => {
  if (values.password !== values.password_confirmation) {
    return {password_confirmation: <Trans>Passwords must be the same</Trans>}
  }
  return undefined
}

// email validation based on regex from RFC 5322 https://emailregex.com/
// disable warning of unnecessary escape character \[
export const validateEmail = (value) => {
  if (
    !value.match(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  ) {
    return <Trans>The e-mail address is invalid</Trans>
  }
  return undefined
}

export const validateLength = (max) => (value) =>
  value.length > max ? <Trans>The field cannot be longer than {max} characters</Trans> : undefined

export const validateMaxValue = (max) => (value) =>
  value > max ? <Trans>Max value is {max}.</Trans> : undefined

export const validateMinValue = (min) => (value) =>
  value < min ? <Trans>Min value is {min}.</Trans> : undefined
