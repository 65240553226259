import React from 'react'
import DetailGridItem from 'component/global/common/grid/DetailGridItem'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import {Trans} from '@lingui/macro'
import DetailTableWrapper from 'component/global/common/wrapper/DetailTableWrapper'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'

const PaymentDataTable = ({classes, pricingData}) => {
  const paymentData = [
    {
      field: <Trans>Space rent</Trans>,
      value: pricingData?.spaceRent ? `${Math.round(pricingData.spaceRent / 100)} Kč` : '0 Kč',
    },
    {
      field: <Trans>Equipment rent</Trans>,
      value: pricingData?.equipmentRent
        ? `${Math.round(pricingData.equipmentRent / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Video production</Trans>,
      value: pricingData?.videoProduction
        ? `${Math.round(pricingData.videoProduction / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Other payments</Trans>,
      value: pricingData?.otherPayments
        ? `${Math.round(pricingData.otherPayments / 100)} Kč`
        : '0 Kč',
    },
    {
      field: <Trans>Marketing counter</Trans>,
      value: pricingData?.marketingCounter
        ? `${Math.round(pricingData.marketingCounter / 100)} Kč`
        : '',
    },
    {
      field: <Trans>Total price</Trans>,
      value: pricingData?.total ? `${Math.round(pricingData.total / 100)} Kč` : '0 Kč',
    },
  ]

  return (
    <DetailGridItem
      xs={12}
      sm={6}
      icon={<MonetizationOnIcon />}
      title={<Trans>Payments (without dph)</Trans>}
    >
      <DetailTableWrapper classNames={classes.statisticsTable} data={paymentData} />
    </DetailGridItem>
  )
}

export default withStyles(PaymentDataTable, globalWrapperStyles)
