import React, {useState} from 'react'
import {DesktopDateTimePicker} from '@mui/x-date-pickers/DesktopDateTimePicker'
import FormHelperText from '@mui/material/FormHelperText'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import {DATE_TIME_FORMAT} from 'helper/global/constants'
import {Trans} from '@lingui/macro'
import {ensureMoment} from 'helper/projectSpecific/calendarFunctions'

const DateTimeInput = (props) => {
  const {helperText, id, input, meta, label, classes, clearable = false, ...rest} = props

  const [isValid, setIsValid] = useState(true)

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classes.dateTimePicker}>
      <DesktopDateTimePicker
        {...input}
        {...rest}
        format={DATE_TIME_FORMAT}
        label={label}
        value={input.value ? ensureMoment(input.value) : undefined}
        onChange={(value) => {
          if (value?.isValid()) {
            !isValid && setIsValid(true)
            input.onChange?.(value)
          } else {
            isValid && setIsValid(false)
          }
        }}
        slotProps={{
          field: {fullWidth: true},
          textField: {
            fullWidth: true,
            variant: 'standard',
            clearable: clearable,
            error: !!errorMessage || !isValid,
          },
        }}
      />
      <FormHelperText>
        {!isValid ? <Trans>Invalid date format</Trans> : errorMessage || helperText || ' '}
      </FormHelperText>
    </div>
  )
}

export default withStyles(DateTimeInput, globalFormFieldStyles)
