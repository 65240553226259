import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import Cell from 'component/global/common/table/Cell'
import TableActionsButton from 'component/global/common/button/TableActionsButton'
import {TableRow} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import {Close} from '@mui/icons-material'
import {DATE_TIME_FORMAT} from 'helper/global/constants'
import moment from 'moment'
import {eventParticipantRowStyle} from 'asset/projectSpecific/jss/event/eventParticipantRowStyle'
import {Can} from 'App/provider/authorizedAbility'

const EventParticipantRow = (props) => {
  const {row, onParticipantLogoutClick, classes} = props

  return (
    <TableRow>
      <Cell align="left">{row.name}</Cell>
      <Cell>{row.email}</Cell>
      <Cell>{moment(row.createdAt).format(DATE_TIME_FORMAT)}</Cell>
      <Cell>{row.isUnderLine ? <Trans>underline</Trans> : ''}</Cell>
      <Can I="delete" a="Participants">
        <Cell isActions={true} isRowAction>
          <span className={classes.logoutButton}>
            <TableActionsButton onClick={onParticipantLogoutClick} backgroundColor="warning">
              <Close />
            </TableActionsButton>
            <span className={classes.logoutButtonLabel} onClick={onParticipantLogoutClick}>
              <Trans>Logout</Trans>
            </span>
          </span>
        </Cell>
      </Can>
    </TableRow>
  )
}

EventParticipantRow.propTypes = {
  row: PropTypes.object,
  onParticipantLogoutClick: PropTypes.func,
}

export default withStyles(EventParticipantRow, eventParticipantRowStyle)
