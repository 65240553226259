import React from 'react'
import PropTypes from 'prop-types'
import {TableCell} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'
import globalTableStyle from 'asset/global/jss/component/common/table/globalTableStyle'

const Cell = (props) => {
  const {
    children,
    classes,
    isActions,
    isDetailCell,
    isRowAction,
    isSortable,
    alignTop,
    cellWrap,
    ...rest
  } = props

  const cellClasses = {
    root: cx(classes.cell, {
      [classes.cellActions]: isActions,
      [classes.detailCell]: isDetailCell,
      [classes.tableHeadActions]: isRowAction,
      [classes.cellCheckBox]: isSortable,
      [classes.cellAlignTop]: alignTop,
      [classes.cellWrap]: cellWrap,
    }),
  }
  return (
    <TableCell classes={cellClasses} {...rest}>
      {children}
    </TableCell>
  )
}

Cell.propTypes = {
  classes: PropTypes.object.isRequired,
  isActions: PropTypes.bool,
  isDetailCell: PropTypes.bool,
  isRowAction: PropTypes.bool,
  isSortable: PropTypes.bool,
  cellWrap: PropTypes.bool,
  children: PropTypes.node,
}

export default withStyles(Cell, globalTableStyle)
