import React, {useState} from 'react'
import {withStyles} from 'tss-react/mui'
import calendarStyle from 'asset/global/jss/component/calendar/calendarStyle'
import moment from 'moment'
import EventsCalendarHeader from 'component/projectSpecific/event/eventList/EventCalendarHeader'
import EventCalendar from 'component/projectSpecific/event/eventList/EventCalendar'
import PropTypes from 'prop-types'

const EventCalendarTab = (props) => {
  const {filter, selectedYear, setSelectedYear} = props
  let currentDate = moment()
  const [selectedMonth, setSelectedMonth] = useState(currentDate.month())

  const nextMonth = () => {
    // if selected is 11 - December then set 1 - January
    if (selectedMonth === 11) {
      setSelectedMonth(0)
      setSelectedYear((prevYear) => prevYear + 1)
    } else {
      setSelectedMonth((prevState) => prevState + 1)
    }
  }

  const previousMonth = () => {
    // if selected is 1 - January then set 11 - December
    if (selectedMonth === 0) {
      setSelectedMonth(11)
      setSelectedYear((prevYear) => prevYear - 1)
    } else {
      setSelectedMonth((prevState) => prevState - 1)
    }
  }

  return (
    <div>
      <EventsCalendarHeader
        previousMonth={previousMonth}
        nextMonth={nextMonth}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
      />
      <EventCalendar filter={filter} selectedYear={selectedYear} selectedMonth={selectedMonth} />
    </div>
  )
}

EventCalendarTab.propTypes = {
  filter: PropTypes.object,
  selectedYear: PropTypes.number,
  setSelectedYear: PropTypes.func,
}

export default withStyles(EventCalendarTab, calendarStyle)
