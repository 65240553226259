import {
  FETCH_PRICING_STATISTICS_REQUEST,
  FETCH_PRICING_STATISTICS_SUCCESS,
  FETCH_SATISFACTION_STATISTICS_REQUEST,
  FETCH_SATISFACTION_STATISTICS_SUCCESS,
  FETCH_STATISTICS_REQUEST,
  FETCH_STATISTICS_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initState = {
  isLoading: false,
  data: {},

  pricingData: {},
  pricingDataLoading: false,

  satisfactionData: {},
  satisfactionLoading: false,
}

export const EventStatisticsReducer = (state = initState, action = null) => {
  switch (action.type) {
    case FETCH_STATISTICS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_STATISTICS_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }
    }
    case FETCH_PRICING_STATISTICS_REQUEST: {
      return {
        ...state,
        pricingDataLoading: true,
      }
    }
    case FETCH_PRICING_STATISTICS_SUCCESS: {
      return {
        ...state,
        pricingData: action.data,
        pricingDataLoading: false,
      }
    }
    case FETCH_SATISFACTION_STATISTICS_REQUEST: {
      return {
        ...state,
        satisfactionLoading: true,
      }
    }
    case FETCH_SATISFACTION_STATISTICS_SUCCESS: {
      return {
        ...state,
        satisfactionLoading: false,
        satisfactionData: action.data,
      }
    }
    default:
      return state
  }
}
