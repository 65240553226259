import React from 'react'
import {Assignment, Business} from '@mui/icons-material'
import {getTime} from 'helper/projectSpecific/calendarFunctions'
import {EVENT_SPACE_CONFIGURATIONS, EVENT_ZONES} from 'helper/projectSpecific/constants'
import moment from 'moment'
import {TIME_FORMAT} from 'helper/global/constants'

const CalendarDayEvents = (props) => {
  const {event, openEventDetail} = props

  const currentParticipants = (event) => {
    const participants =
      event.participantsCount || event.participantsCount === 0 ? event.participantsCount : ''
    const capacity = event.capacity ? `/${event.capacity}` : ''
    return `${participants}${capacity}`
  }

  const getPreparationFromTime = moment(event.from)
    .subtract(Math.round(event.preparationLength * 60), 'minute')
    .format(TIME_FORMAT)

  return (
    <div className="calendar-event-wrapper" onClick={openEventDetail(event)}>
      <div className="time-name">
        {!!event.preparationLength && <span>{`(${getPreparationFromTime})`}</span>}
        <span>{event.from && getTime(event.from)}</span>
        <span>{event.name && event.name}</span>
      </div>
      {event.topic && (
        <div className="topic">
          <Assignment />
          {event.topic.name}
        </div>
      )}
      <div className="organization">
        <Business />
        {(event.organization && event.organization.name) || event.organizationName}
      </div>
      <div className="configuration">
        <span>
          {event.spaceConfiguration
            ? EVENT_SPACE_CONFIGURATIONS.find((config) => config.id === event.spaceConfiguration)
                ?.configuration
            : EVENT_ZONES.find((zone) => zone.value === event.zone)?.label}
        </span>
        <span>{currentParticipants(event)}</span>
      </div>
    </div>
  )
}

export default CalendarDayEvents
