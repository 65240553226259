import React from 'react'
import {Field} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMinValue} from 'helper/projectSpecific/validations'
import {withStyles} from 'tss-react/mui'
import {stepperFormStyle} from 'asset/projectSpecific/jss/common/stepperFormStyle'
import GridItem from 'component/global/common/grid/GridItem'
import {RR_CONSTANTS} from 'helper/projectSpecific/constants'
import SelectField from 'component/global/common/formField/SelectField'
import FormCondition from 'component/global/form/FormCondition'
import DateInput from 'component/global/common/formField/DateInput'
import NumberInput from 'component/global/common/formField/NumberInput'

const RecurrenceEndFields = (props) => {
  const {classes, havePartnerPermission} = props

  return (
    <>
      <GridItem xs={12}>
        <h3 className={classes.recurrenceHeaders}>
          <Trans>End of recurrence</Trans>
        </h3>
      </GridItem>
      <GridItem xs={12} sm={6}>
        <Field
          name="rruleConfig.help.endType"
          label={<Trans>End of recurrence</Trans>}
          component={SelectField}
          options={RR_CONSTANTS.endOptions}
          validate={required}
          disabled={havePartnerPermission}
        />
      </GridItem>

      <FormCondition when="rruleConfig.help.endType" is={'after'}>
        <GridItem xs={12} sm={6}>
          <Field
            name={`rruleConfig.count`}
            label={<Trans>Executions count</Trans>}
            component={NumberInput}
            type="number"
            min={1}
            validate={composeValidators(required, validateMinValue(1))}
            disabled={havePartnerPermission}
          />
        </GridItem>
      </FormCondition>

      <FormCondition when="rruleConfig.help.endType" is={'until'}>
        <GridItem xs={12} sm={6}>
          <Field
            name={`rruleConfig.until`}
            label={<Trans>End Date</Trans>}
            component={DateInput}
            validate={required}
            disabled={havePartnerPermission}
          />
        </GridItem>
      </FormCondition>
    </>
  )
}

RecurrenceEndFields.propTypes = {
  classes: PropTypes.object,
  havePartnerPermission: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
}

export default withStyles(RecurrenceEndFields, stepperFormStyle)
