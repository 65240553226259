import React from 'react'
import {List, ListItem, ListItemIcon, ListItemText, SvgIcon} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'
import globalLayoutElementsStyle from 'asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import {adminRoutes} from 'config/adminRoutes'
import PropTypes from 'prop-types'
import {Can} from 'App/provider/authorizedAbility'
import {useLocation, useNavigate} from 'react-router-dom'

const DrawerMenu = ({classes, disabled = false, openedDrawer}) => {
  let location = useLocation()
  const navigate = useNavigate()

  const handleLinkClick = (route) => {
    navigate(route)
  }

  const hiddenMenuItem = cx({
    [classes.hiddenElement]: !openedDrawer,
  })

  const isMenuActive = (path) => {
    let historyPath = location.pathname
    const baseHistoryPath = historyPath.split('/').slice(0, 3).join('/')
    const basePath = path.split('/').slice(0, 3).join('/')
    if (baseHistoryPath === basePath) {
      return classes.activeMenu
    }
    return null
  }

  return (
    <List>
      {adminRoutes
        .filter((adminRoute) => adminRoute.visible)
        .map((route, index) => {
          const linkRoute = route.sidebarPath ?? route.path
          const linkItem = (
            <ListItem
              key={index}
              className={isMenuActive(route.path)}
              button
              onClick={() => !disabled && handleLinkClick(linkRoute)}
            >
              <ListItemIcon>
                <SvgIcon component={route.icon} />
              </ListItemIcon>
              <ListItemText primary={route.name} className={hiddenMenuItem} />
            </ListItem>
          )
          if (route.authorized) {
            return (
              <Can key={index} I={route.authorized.actions} a={route.authorized.subject}>
                {linkItem}
              </Can>
            )
          } else {
            return linkItem
          }
        })}
    </List>
  )
}

DrawerMenu.propTypes = {
  classes: PropTypes.object,
  openedDrawer: PropTypes.bool,
}

export default withStyles(DrawerMenu, globalLayoutElementsStyle)
