import {lighten} from '@mui/material/styles'
import {
  BORDER_RADIUS,
  cardTitle,
  darkText,
  dividerColor,
  grayColor,
  primaryColor,
  secondaryColor,
} from 'asset/global/jss/styleHelpers'

export const globalWrapperStyles = (theme) => ({
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '0px',
    paddingLeft: '82px',
    paddingRight: '10px',
    marginBottom: '0px',
    fontSize: '1.2rem',

    '& small': {
      fontSize: '80%',
      fontWeight: '400',
    },
  },
  adminPageTitleWrapper: {
    margin: '6px 0px 60px 0px',
    '& .MuiDivider-root': {
      borderColor: dividerColor,
    },
    '& .MuiDivider-middle': {
      marginLeft: '0px',
      marginRight: '0px',
    },
    '& .MuiTabs-fixed': {
      marginRight: '0px',
    },
  },
  adminPageTitle: {
    color: darkText,
    fontWeight: '300',
    fontSize: '1.4rem',
    padding: '6px 2rem',
    minHeight: '50px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    '& .page-title': {
      paddingBottom: '4px',
    },
    '& .page-title-button': {
      margin: '0',
    },
  },
  adminPageTitleWithBackButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardWrapper: {
    position: 'relative',
    borderRadius: BORDER_RADIUS,
  },
  contentCardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  actionCardButtons: {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'flex-end',
    '& > span': {
      paddingLeft: '0.8rem',
    },
    '& svg, button': {
      margin: '0px',
      display: 'flex',
      justifyContent: 'inherit',
    },
  },
  tableMargin: {
    marginTop: '30px',
  },
  tableWrapper: {
    overflowX: 'auto',

    '& .MuiTableCell-head, .MuiTableSortLabel-active': {
      color: primaryColor[0],
    },

    '& .MuiTableCell-head': {
      paddingBottom: '4px',
    },
  },
  tableBody: {
    borderTop: '4px solid ' + primaryColor[0],
  },
  paperFullScreen: {
    margin: '30px',
    padding: '30px',
    height: 'calc(100vh - 60px)',
    [theme.breakpoints.down('lg')]: {
      margin: '25px',
      padding: '20px',
      height: 'calc(100vh - 50px)',
    },
    [theme.breakpoints.down('md')]: {
      margin: '10px',
      padding: '5px',
      height: 'calc(100vh - 20px)',
    },
  },
  paperWidthMd: {
    [theme.breakpoints.up('md')]: {
      minWidth: '600px',
    },
  },
  dialogContent: {
    margin: '26px 30px',
    padding: 0,
  },
  dialogActions: {
    padding: theme.spacing(2),
    borderTop: '1px solid #D9D8D8',
    '& button': {
      margin: 0,
    },
  },
  dialogTitle: {
    borderBottom: '1px solid #D9D8D8',
  },
  toolbar: {
    padding: 0,
  },
  toolbarSelected: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbarContent: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  toolbarTitle: {
    flex: '0 0 auto',
  },
  toolbarActions: {
    color: theme.palette.text.secondary,
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.primary.main,
          backgroundColor: lighten(theme.palette.primary.light, 0.85),
          paddingLeft: '10px',
          paddingRight: '10px',
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    position: 'relative',
    '& button': {
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        top: '15px',
        right: '-80px',
      },
    },
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& svg': {
      margin: '0px !important',
    },
  },
  smallIcons: {
    padding: '5px !important',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  buttonWithIcon: {
    marginLeft: '0px !important',
    marginRight: '0px !important',
  },
  actionButtons: {
    '& svg': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '1.1rem',
      fill: grayColor[0],
      cursor: 'pointer',
    },
  },
  detailIcon: {
    fill: secondaryColor[0],
  },
  tabBar: {
    marginTop: '30px',
  },
  formActions: {
    borderTop: '3px solid #fff',
    padding: theme.spacing(3),
    paddingTop: '40px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formLink: {
    color: secondaryColor[0],
    textDecoration: 'none',
    backgroundColor: 'transparent',
    marginLeft: '5px',
    '&:hover': {
      color: secondaryColor[5],
    },
  },
  hidden: {
    display: 'none',
  },
  gridContainer: {},
  gridItem: {
    padding: '0 15px !important',
  },
  activeTableIcon: {
    fontSize: '10px',
  },
  detailTableWrapper: {
    overflowX: 'auto',
    padding: '20px 0px',
    background: 'transparent',
    width: '100%',
  },
  authPageWrapper: {
    height: 'calc(100vh - 60px)',
  },
  authHeader: {
    padding: '20px',
    boxSizing: 'border',
  },
  authCardHeader: {
    paddingBottom: '0px',
    paddingTop: '20px',
  },
  cardAuthHeaderWrapper: {
    textAlign: 'center',
    justifyContent: 'center',
    '& object': {
      width: 'auto',
      maxWidth: '100%',
      height: 'auto',
    },
    '& img': {
      width: '55%',
    },
  },
  cardAuthHeaderCompanyName: {
    fontWeight: 'bold',
    fontSize: '1.8rem',
    color: primaryColor[0],
    marginRight: '10px',
  },
  cardAuthHeaderTitle: {
    color: grayColor[7],
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  cardBodyWithoutToolbar: {
    marginTop: '-40px',
  },
  detailItemWrapper: {
    padding: '20px 0px',
  },
  qrCode: {
    textAlign: 'center',
  },
  configurationMap: {
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  mediaServerDialog: {
    minWidth: '900px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',

    '& .visual': {
      flex: 1,
      maxWidth: '400px',
      maxHeight: '400px',
      width: 'auto',
      height: 'auto',

      '& .container': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      },

      '&:not(:last-child)': {
        paddingRight: '10px',
      },

      '& .title': {
        fontSize: '16px',
      },

      '& .buttons': {
        display: 'flex',
        flexDirection: 'row',

        '& button': {
          padding: '6px 20px',
        },
      },

      '& .image-placeholder': {
        backgroundColor: primaryColor[0] + 50,
        border: '1px solid ' + primaryColor[0] + 'ee',
        borderRadius: '3px',
        color: primaryColor[0] + 'cc',
        padding: '5px',
        fontWeight: '700',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
      },

      '& .vertical': {
        padding: '10px 0',
        minWidth: '90px',
        '& .image-placeholder': {
          height: '250px',
          width: '180px',
        },
      },
      '& .horizontal': {
        padding: '10px 0',
        minWidth: '150px',
        '& .image-placeholder': {
          height: '250px',
          width: '300px',
        },
      },
      '& .event': {
        padding: '10px 0',
        minWidth: '150px',
        '& .image-placeholder': {
          height: '250px',
          width: '250px',
        },
      },
    },
  },
  saveClose: {
    paddingTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',

    '& span:first-of-type': {
      '& button': {
        marginRight: '10px',
      },
    },
  },
  fieldWidth: {
    width: 160,
    boxSizing: 'border-box',

    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #D2D2D2',
    },

    '& .MuiInput-root': {
      width: 160,
    },
  },
  statisticsTable: {
    padding: '10px 0px 20px 0px',

    '& .Cell-detailCell-393:first-of-type': {
      padding: '6px 5px 6px 0 !important',
    },

    '& .Cell-cell-389:last-of-type': {
      padding: '0 5px 6px 5px !important',
    },
    '& tr': {
      '& td:last-of-type': {
        textAlign: 'end',
        verticalAlign: 'bottom',
      },
    },

    '& p': {
      margin: 0,
    },
  },
})
