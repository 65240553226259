import React, {lazy} from 'react'
import {createBrowserRouter, Navigate} from 'react-router-dom'

import AdminLayout from 'App/layout/AdminLayout'
import AuthLayout from 'App/layout/AuthLayout'

const LoginPage = lazy(() => import('App/page/auth/LoginPage'))
const ForgottenPasswordPage = lazy(() => import('App/page/auth/ForgottenPasswordPage'))
const ResetPasswordPage = lazy(() => import('App/page/auth/ResetPasswordPage'))
const FeedbackPage = lazy(() => import('App/page/auth/FeedbackPage'))

const TopicsPage = lazy(() => import('App/page/topic/TopicsPage'))
const StatisticsPage = lazy(() => import('App/page/statistic/StatisticsPage'))
const OrganizationsPage = lazy(() => import('App/page/organization/OrganizationsPage'))
const OrganizationDetailPage = lazy(() => import('App/page/organization/OrganizationDetailPage'))
const EventsPage = lazy(() => import('App/page/event/EventsPage'))
const EventCreatePage = lazy(() => import('App/page/event/EventCreatePage'))
const EventEditPage = lazy(() => import('App/page/event/EventEditPage'))
const EventDetailPage = lazy(() => import('App/page/event/EventDetailPage'))
const ProposalCreatePage = lazy(() => import('App/page/proposal/ProposalCreatePage'))
const ProposalDetailPage = lazy(() => import('App/page/proposal/ProposalDetailPage'))

export const router = createBrowserRouter([
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'forgotten-password',
        element: <ForgottenPasswordPage />,
      },
      {
        path: 'reset-password/:token',
        element: <ResetPasswordPage />,
      },
      {
        path: 'satisfaction/:token',
        element: <FeedbackPage />,
      },
    ],
  },
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      {
        path: 'topics',
        element: <TopicsPage />,
      },
      {
        path: 'statistics',
        element: <StatisticsPage />,
      },
      {
        path: 'organizations',
        element: <OrganizationsPage />,
      },
      {
        path: 'organizations/:id',
        element: <OrganizationDetailPage />,
      },
      {
        path: 'events',
        children: [
          {
            path: '',
            element: <EventsPage tab={'calendar'} />,
          },
          {
            path: 'calendar',
            element: <EventsPage tab={'calendar'} />,
          },
          {
            path: 'calendar/new',
            element: <EventCreatePage />,
          },
          {
            path: 'calendar/:id/edit/:date',
            element: <EventEditPage />,
          },
          {
            path: 'calendar/:id/date/:date',
            element: <EventDetailPage />,
          },
          {
            path: 'proposals',
            element: <EventsPage tab={'proposals'} />,
          },
          {
            path: 'proposals/new',
            element: <ProposalCreatePage />,
          },
          {
            path: 'proposals/:id',
            element: <ProposalDetailPage />,
          },
          {
            path: 'reservations',
            element: <EventsPage tab={'reservations'} />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to={'/admin/events/calendar'} />,
  },
])
