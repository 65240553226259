import React, {useState} from 'react'
import organizationDetailStyle from 'asset/projectSpecific/jss/organization/organizationDetailStyle'
import {withStyles} from 'tss-react/mui'
import PropTypes from 'prop-types'
import {ORGANIZATION_USERS_LIST_COLUMNS} from 'component/projectSpecific/organization/organizationDetail/_organizationDetailUsersTableColumns'
import Pagination from 'component/global/common/table/Pagination'
import OrganizationUserRow from 'component/projectSpecific/organization/organizationList/OrganizationUserRow'
import TableWrapper from 'component/global/common/wrapper/TableWrapper'
import OrganizationContactDialog from 'component/projectSpecific/organization/organizationDetail/OrganizationContactDialog'
import {fireSuccessToast} from 'helper/global/functions'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import {
  createOrganizationContact,
  editOrganizationContact,
} from 'redux/action/projectSpecific/organizationContactAction'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'

const OrganizationUsersDetailTab = (props) => {
  const {order, orderBy, countOrganizationUsers, organizationPage, users, rowsPerOrganizationPage} =
    props
  const {
    requestSort,
    onOrganizationChangePage,
    handleUserEdit,
    handleUserDelete,
    handleResetPasswordClick,
    handleSendWelcomeEmail,
    createOrganizationContact,
    editOrganizationContact,
    fetchOrganizationUsers,
    fetchOrganizationContacts,
  } = props

  const {id} = useParams()

  const [contactDialog, setContactDialog] = useState({type: null, row: null, visible: false})

  const handleOpenContactDialog = (row, type) => (e) => {
    setContactDialog({type: type, row: row, visible: true})
  }

  const handleCloseContactDialog = () => {
    setContactDialog({type: null, row: null, visible: false})
  }

  const handleSubmitContact = (values) => {
    const data = {
      name: values.name ? values.name : '',
      description: values.description ? values.description : '',
      email: values.email ? values.email : '',
      userId: values.userId ? values.userId : '',
      phone: values.phone ? values.phone : '',
    }
    if (contactDialog?.type === 'custom') {
      return createOrganizationContact(id, data).then(() => {
        fetchOrganizationUsers()
        fetchOrganizationContacts()
        fireSuccessToast(<Trans>Contact {values?.name} created.</Trans>)
        setContactDialog({type: null, row: null, visible: false})
      })
    } else {
      return editOrganizationContact(id, contactDialog.row?.contact?.id, data).then(() => {
        fetchOrganizationUsers()
        fetchOrganizationContacts()
        fireSuccessToast(<Trans>Contact {values?.name} edited.</Trans>)
        setContactDialog({type: null, row: null, visible: false})
      })
    }
  }

  return (
    <>
      {/* CONTACT DIALOGS*/}
      <OrganizationContactDialog
        open={contactDialog?.visible}
        close={handleCloseContactDialog}
        handleSubmit={handleSubmitContact}
        form={contactDialog?.type}
        disabledUser={true}
        contact={contactDialog?.row?.contact ? contactDialog.row.contact : contactDialog.row}
      />

      <TableWrapper
        onRequestSort={requestSort}
        columns={ORGANIZATION_USERS_LIST_COLUMNS}
        orderDirection={order}
        orderBy={orderBy}
        tablePagination={
          <Pagination
            count={countOrganizationUsers}
            page={organizationPage}
            onChangePage={onOrganizationChangePage}
            rowsPerPage={rowsPerOrganizationPage}
          />
        }
      >
        {users?.map((row) => (
          <OrganizationUserRow
            key={row.id}
            row={row}
            onEditUserClick={handleUserEdit(row)}
            onUserDeleteClick={handleUserDelete(row)}
            onResetPasswordClick={handleResetPasswordClick(row)}
            handleSendWelcomeEmail={handleSendWelcomeEmail(row)}
            openContactDialog={handleOpenContactDialog}
          />
        ))}
      </TableWrapper>
    </>
  )
}

OrganizationUsersDetailTab.propTypes = {
  users: PropTypes.array,
  requestSort: PropTypes.func,
  onOrganizationChangePage: PropTypes.func,
  handleUserEdit: PropTypes.func,
  handleUserDelete: PropTypes.func,
  handleResetPasswordClick: PropTypes.func,
  handleSendWelcomeEmail: PropTypes.func,
  createOrganizationContact: PropTypes.func,
  editOrganizationContact: PropTypes.func,
  fetchOrganizationUsers: PropTypes.func,
  fetchOrganizationContacts: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createOrganizationContact,
      editOrganizationContact,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(withStyles(OrganizationUsersDetailTab, organizationDetailStyle))
