import React, {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import Footer from 'component/global/layoutElement/Footer'
import Gradients from 'component/projectSpecific/common/Gradients'
import globalLayoutStyle from 'asset/global/jss/layout/globalLayoutStyle'
import AuthLoadingPage from 'App/page/loading/AuthLoadingPage'
import {withStyles} from 'tss-react/mui'

const AuthLayout = ({classes}) => {
  return (
    <div className={classes.authWrapper} id="content-wrapper">
      <Suspense fallback={<AuthLoadingPage />}>
        <Gradients />
        <Outlet />
        <Footer />
      </Suspense>
    </div>
  )
}

export default withStyles(AuthLayout, globalLayoutStyle)
