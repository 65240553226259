export const routesMap = {
  admin: {
    event: '/admin/events',
    calendar: '/admin/events/calendar',
    proposal: '/admin/events/proposals',

    organization: '/admin/organizations',
    topic: '/admin/topics',
    statistic: '/admin/statistics',
  },
  auth: {
    login: '/auth/login',
    forgottenPassword: '/auth/forgotten-password',
    resetPassword: '/auth/reset-password',
    satisfaction: '/auth/satisfaction',
  },
}
