import React from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {darkGradient, lightGradient} from 'asset/global/jss/styleHelpers'

const Gradients = (props) => {
  const {size = [200, 800], position = 'circle at top right'} = props

  return (
    <>
      <div
        className="gradient"
        style={{
          backgroundImage: `
        radial-gradient(
        ${position},
        ${darkGradient},
        rgba(255, 255, 255, 0) ${size[0]}px
        `,
          zIndex: '-1',
        }}
      />
      <div
        className="gradient"
        style={{
          backgroundImage: `
        radial-gradient(
        ${position},
        ${lightGradient},
        rgba(255, 255, 255, 0) ${size[1]}px
        `,
          zIndex: '-2',
        }}
      />
    </>
  )
}

Gradients.propTypes = {
  size: PropTypes.array,
  position: PropTypes.string,
  globalSettings: PropTypes.object.isRequired,
}

export default connect((store) => {
  return {
    globalSettings: store.GlobalSettings,
  }
})(Gradients)
