import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import Cell from 'component/global/common/table/Cell'
import TableActionsButton from 'component/global/common/button/TableActionsButton'
import {TableRow, Tooltip} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import {DeleteForever, Edit, EmojiPeople, LockOpen} from '@mui/icons-material'
import GlobalChip from 'component/global/common/table/GlobalChip'
import {Can} from 'App/provider/authorizedAbility'
import globalTableStyle from 'asset/global/jss/component/common/table/globalTableStyle'

const OrganizationUserRow = (props) => {
  const {
    classes,
    row,
    onEditUserClick,
    onUserDeleteClick,
    onResetPasswordClick,
    handleSendWelcomeEmail,
    openContactDialog,
  } = props

  const translateRoleName = (name) => {
    if (name === 'technical_manager') {
      return <Trans>Technical manager</Trans>
    } else if (name === 'podcast_manager') {
      return <Trans>Podcast manager</Trans>
    } else if (name === 'admin') {
      return <Trans>Admin</Trans>
    } else if (name === 'partner') {
      return <Trans>Partner</Trans>
    }
    return name
  }

  return (
    <TableRow>
      <Cell align="left">{row.name}</Cell>
      <Cell>{row.email}</Cell>
      <Cell>
        {row.roles?.map((role) => (
          <GlobalChip
            key={role.id}
            label={translateRoleName(role.name)}
            className={classes.smallChipPadding}
          />
        ))}
      </Cell>
      <Cell>
        {row.contact ? (
          <div className={classes.contactText} onClick={openContactDialog(row, 'edit')}>
            {row.contact.name}
          </div>
        ) : (
          <div className={classes.addContactText} onClick={openContactDialog(row, 'custom')}>
            <Trans>add contact</Trans>
          </div>
        )}
      </Cell>
      <Cell isActions={true} isRowAction>
        <TableActionsButton
          className={classes.welcome}
          onClick={handleSendWelcomeEmail}
          backgroundColor="success"
        >
          <Tooltip title={<Trans>Send welcome e-mail</Trans>}>
            <span className={classes.welcomeText}>
              <Trans>Welcome</Trans>
              <EmojiPeople />
            </span>
          </Tooltip>
        </TableActionsButton>
        <Can I="resetPassword" an="Users">
          <TableActionsButton onClick={onResetPasswordClick} backgroundColor="warning">
            <Tooltip title={<Trans>Reset password</Trans>}>
              <LockOpen />
            </Tooltip>
          </TableActionsButton>
        </Can>
        <Can I="update" an="Users">
          <TableActionsButton onClick={onEditUserClick} backgroundColor="success">
            <Tooltip title={<Trans>Edit</Trans>}>
              <Edit />
            </Tooltip>
          </TableActionsButton>
        </Can>
        <Can I="delete" an="Users">
          <TableActionsButton onClick={onUserDeleteClick} backgroundColor="warning">
            <Tooltip title={<Trans>Delete</Trans>}>
              <DeleteForever />
            </Tooltip>
          </TableActionsButton>
        </Can>
      </Cell>
    </TableRow>
  )
}

OrganizationUserRow.propTypes = {
  row: PropTypes.object,
  classes: PropTypes.object,
  onEditUserClick: PropTypes.func,
  onUserDeleteClick: PropTypes.func,
  onResetPasswordClick: PropTypes.func,
}

export default withStyles(OrganizationUserRow, globalTableStyle)
