import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import SelectField from 'component/global/common/formField/SelectField'
import HorizontalStepFormWrapper from 'component/global/common/stepper/HorrizontalStepFormWrapper'
import GlobalStepperButtons from 'component/global/common/button/GlobalStepperButtons'
import SpaceConfiguration from 'component/projectSpecific/proposal/proposalForm/SpaceConfiguration'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {required} from 'helper/projectSpecific/validations'
import {routesMap} from 'config/routesMap'
import {Can} from 'App/provider/authorizedAbility'
import {useNavigate} from 'react-router-dom'

const ProposalAvailabilityForm = (props) => {
  const {onSubmitAvailability, initialValues, topics, organizations} = props

  const navigate = useNavigate()

  const [selected, setSelected] = useState(initialValues['spaceConfiguration'] || null)

  return (
    <HorizontalStepFormWrapper mediumWidth={8} largeWidth={6}>
      <Form onSubmit={onSubmitAvailability} initialValues={initialValues}>
        {(formProps) => {
          const handleSelectConfigurations = (id) => () => {
            if (selected === id) {
              setSelected(null)
              formProps.form.change('spaceConfiguration', null)
            } else {
              setSelected(id)
              formProps.form.change('spaceConfiguration', id)
            }
          }

          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer spacing={4}>
                <Can I="manage" an="Internal">
                  <GridItem xs={12}>
                    <Field
                      name="organizationId"
                      label={<Trans>Organization</Trans>}
                      component={SelectField}
                      options={organizations}
                      validate={required}
                    />
                  </GridItem>
                </Can>
                <GridItem xs={12}>
                  <Field
                    name="topicId"
                    label={<Trans>Topic</Trans>}
                    component={SelectField}
                    options={topics}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <SpaceConfiguration
                    requiredConfiguration={false}
                    selected={selected}
                    selectConfigurations={handleSelectConfigurations}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <GlobalStepperButtons
                    showBack={true}
                    backButtonLabel={<Trans>Cancel</Trans>}
                    handleBack={() => navigate(routesMap.admin.proposal)}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

ProposalAvailabilityForm.propTypes = {
  onSubmitAvailability: PropTypes.func,
}

export default ProposalAvailabilityForm
