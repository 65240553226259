import React from 'react'
import PropTypes from 'prop-types'
import {proposalDetailStyle} from 'asset/projectSpecific/jss/proposal/proposalDetailStyle'
import {withStyles} from 'tss-react/mui'

const ParticipantsDetailCell = (props) => {
  const {participants, total} = props

  return (
    <div>
      <span>{participants}</span>
      {(total || total === 0) && <strong>&nbsp;&#47;&nbsp;{total}</strong>}
    </div>
  )
}

ParticipantsDetailCell.propTypes = {
  estimatedCapacity: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(ParticipantsDetailCell, proposalDetailStyle)
