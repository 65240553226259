import React, {useContext, useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {AddCircle} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import HorizontalStepperWrapper from 'component/global/common/wrapper/HorizontalStepperWrapper'
import ProposalAvailabilityForm from 'component/projectSpecific/proposal/proposalForm/ProposalAvailabilityForm'
import ProposalDateTimeForm from 'component/projectSpecific/proposal/proposalForm/ProposalDateTimeForm'
import ProposalSettingsForm from 'component/projectSpecific/proposal/proposalForm/ProposalSettingsForm'
import ProposalRequirementsForm from 'component/projectSpecific/proposal/proposalForm/ProposalRequirementsForm'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getTopics} from 'redux/action/projectSpecific/topicActions'
import {fireSuccessToast, getSelectOptions, redirectWithMessage} from 'helper/global/functions'
import {createAdminProposal, createProposal} from 'redux/action/projectSpecific/proposalActions'
import {useStateWithCallbackLazy} from 'use-state-with-callback'
import {getAvailableDates} from 'redux/action/projectSpecific/eventActions'
import {routesMap} from 'config/routesMap'
import {DATABASE_DATE_FORMAT, TIME_FORMAT} from 'helper/global/constants'
import {AbilityContext} from 'App/provider/authorizedAbility'
import moment from 'moment'
import {getListOrganizations} from 'redux/action/projectSpecific/organizationActions'
import {useNavigate} from 'react-router-dom'

const ProposalCreatePage = (props) => {
  const ability = useContext(AbilityContext)
  if (ability.cannot('create', 'Proposals')) {
    return redirectWithMessage(<Trans>You are not authorized to create proposals.</Trans>)
  }

  const {
    getAvailableDates,
    topics,
    getTopics,
    createProposal,
    getListOrganizations,
    organizations,
    createAdminProposal,
  } = props

  const navigate = useNavigate()

  useEffect(() => {
    getTopics(null, 0, 1000, 'name')
  }, [getTopics])

  const [activeStep, setActiveStep] = useState(0)
  const [availableDates, setAvailableDates] = useState({})
  const [activeTopic, setActiveTopic] = useState()
  const [formData, setFormData] = useStateWithCallbackLazy({
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  })

  const proposalSteps = [
    <Trans>Space configuration</Trans>,
    <Trans>Date and time</Trans>,
    <Trans>Event definition</Trans>,
    <Trans>Requirements</Trans>,
  ]

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ProposalAvailabilityForm
            initialValues={formData['step1']}
            onSubmitAvailability={handleSubmitAvailability}
            topics={getSelectOptions(topics)}
            organizations={getSelectOptions(organizations)}
          />
        )
      case 1:
        return (
          <ProposalDateTimeForm
            goStepBack={goStepBack}
            configuration={formData['step1']}
            initialValues={formData['step2']}
            availableDates={availableDates}
            topic={activeTopic}
            onSubmitDateTime={handleSubmitDateTime}
          />
        )
      case 2:
        return (
          <ProposalSettingsForm
            goStepBack={goStepBack}
            initialValues={formData['step3']}
            onSubmitSettings={handleSubmitSettings}
          />
        )
      case 3:
        return (
          <ProposalRequirementsForm
            goStepBack={goStepBack}
            initialValues={formData['step4']}
            onSubmitRequirements={handleSubmitRequirements}
          />
        )
      default:
        return <Trans>Unknown step</Trans>
    }
  }

  const handleSubmitAvailability = (values) => {
    const selectedTopicObject = topics.find((topic) => topic.id === values.topicId)

    setFormData(
      {
        ...formData,
        step1: {
          topic: selectedTopicObject,
          topicId: selectedTopicObject.id,
          spaceConfiguration: values.spaceConfiguration,
          organizationId: values.organizationId,
        },
      },
      null
    )
    setActiveTopic(selectedTopicObject)

    const dateAvailabilityParams = {
      topicId: values.topicId,
      spaceConfiguration: values.spaceConfiguration ? values.spaceConfiguration : null,
    }

    getAvailableDates(dateAvailabilityParams).then((response) => {
      setAvailableDates(response)
      setActiveStep(activeStep + 1)
    })
  }

  const handleSubmitDateTime = (values) => {
    const proposalActions = values.proposalActions[0]
    const valuesToSet = {
      proposalActions: [
        {
          ...proposalActions,
          date: moment(proposalActions.date).format(DATABASE_DATE_FORMAT),
          customTimeFrom: proposalActions?.customTimeFrom
            ? moment(proposalActions.customTimeFrom).format(TIME_FORMAT)
            : null,
          customTimeLength: proposalActions?.customTimeLength
            ? Number(proposalActions.customTimeLength)
            : null,
          customPreparationLength: proposalActions?.customPreparationLength
            ? Number(proposalActions.customPreparationLength)
            : null,
        },
      ],
    }

    setFormData({...formData, step2: valuesToSet}, null)
    setActiveStep(activeStep + 1)
  }

  const handleSubmitSettings = (values) => {
    const valuesToSet = {
      ...values,
      estimatedCapacity: Number(values.estimatedCapacity),
    }
    setFormData({...formData, step3: valuesToSet}, null)
    setActiveStep(activeStep + 1)
  }

  const handleSubmitRequirements = (values) => {
    const valuesToSet = {
      ...values,
      technicalRequirements: values.technicalRequirements || [],
      organizationalRequirements: values.organizationalRequirements || [],
      additionalRequirements: values.additionalRequirements || [],
    }
    setFormData({...formData, step4: valuesToSet}, (currentFormData) => {
      const proposalData = {
        ...currentFormData.step1,
        ...currentFormData.step2,
        ...currentFormData.step3,
        ...currentFormData.step4,
        proposalActions: [
          {
            ...currentFormData.step2.proposalActions[0],
            customTimeLength:
              currentFormData.step2.proposalActions[0].customTimeLength &&
              currentFormData.step2.proposalActions[0].customTimeLength / 60,
            customPreparationLength:
              currentFormData.step2.proposalActions[0].customPreparationLength &&
              currentFormData.step2.proposalActions[0].customPreparationLength / 60,
          },
        ],
      }

      proposalData.date && proposalData.date.format(DATABASE_DATE_FORMAT)

      if (proposalData.platform === true) {
        proposalData.platform = 'ONLINE'
      } else {
        proposalData.platform = 'OFFLINE'
      }

      if (proposalData.organizationId) {
        createAdminProposal(proposalData.organizationId, proposalData).then(() => {
          fireSuccessToast(<Trans>Reservation created.</Trans>)
          navigate(routesMap.admin.proposal)
        })
      } else {
        createProposal(proposalData).then(() => {
          fireSuccessToast(<Trans>Proposal created.</Trans>)
          navigate(routesMap.admin.proposal)
        })
      }
    })
  }

  const goStepBack = () => {
    setActiveStep(activeStep - 1)
  }

  const cannotManageInternal = ability.cannot('manage', 'Internal')

  useEffect(() => {
    if (!cannotManageInternal) {
      getListOrganizations(0, 1000, 'name')
    }
  }, [])

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        pageTitle={
          cannotManageInternal ? (
            <Trans>Create event proposal</Trans>
          ) : (
            <Trans>Create reservation</Trans>
          )
        }
        iconPageHeader={<AddCircle />}
      >
        <HorizontalStepperWrapper
          steps={proposalSteps}
          getStepContent={getStepContent}
          activeStep={activeStep}
        />
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAvailableDates,
      getTopics,
      createProposal,
      getListOrganizations,
      createAdminProposal,
    },
    dispatch
  )
}

ProposalCreatePage.propTypes = {
  getAvailableDates: PropTypes.func,
  getTopics: PropTypes.func,
  createProposal: PropTypes.func,
  getListOrganizations: PropTypes.func,
  createAdminProposal: PropTypes.func,
  topics: PropTypes.array,
  organizations: PropTypes.array,
}

export default compose(
  connect((store) => {
    return {
      topics: store.SimpleListTopics.data,
      organizations: store.Organizations.data,
    }
  }, mapDispatchToProps)
)(ProposalCreatePage)
