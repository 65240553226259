import {getActualUser} from 'helper/global/functions'

export const LOGGED_USER = 'exceLoggedUser'
export const SESSION_ID = 'exceSessionId'

export const hasOnlyPartnerRole = getActualUser?.()?.user?.roles[0]?.name === 'partner'

export const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'
export const DATE_TIME_DATABASE_FORMAT = 'YYYY-MM-DDTHH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATABASE_DATE_FORMAT = 'YYYY-MM-DD'
export const SIMPLE_DATE_FORMAT_SPACED = 'DD. MM.'
export const TIME_FORMAT = 'HH:mm'
