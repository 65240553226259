import React from 'react'
import {withStyles} from 'tss-react/mui'
import MuiDialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import globalDialogStyle from 'asset/global/jss/component/common/dialog/globalDialogStyle'
import cx from 'classnames'

const DialogTitle = (props) => {
  const {children, classes, onClose, dialogTitleClasses} = props

  return (
    <MuiDialogTitle className={cx(classes.muiDialogTitle, dialogTitleClasses)}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeDialogTitleButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default withStyles(DialogTitle, globalDialogStyle)
