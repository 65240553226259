import React, {useCallback, useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import {AddCircle} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import {bindActionCreators, compose} from 'redux'
import {fireSuccessToast, redirectWithMessage} from 'helper/global/functions'
import {editEvent, getEvent} from 'redux/action/projectSpecific/eventActions'
import {routesMap} from 'config/routesMap'
import EventStepper from 'component/projectSpecific/event/eventForm/EventStepper'
import {connect} from 'react-redux'
import {updateGlobalLoader} from 'redux/action/global/loaderActions'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import {AbilityContext} from 'App/provider/authorizedAbility'
import {useNavigate, useParams} from 'react-router-dom'

const EventEditPage = (props) => {
  const ability = useContext(AbilityContext)
  if (ability.cannot('update', 'Events')) {
    return redirectWithMessage(<Trans>You are not authorized to update events.</Trans>)
  }

  const {event, getEvent, isEventLoaded, updateGlobalLoader, editEvent} = props

  const {id, date} = useParams()
  const navigate = useNavigate()

  const fetchEvent = useCallback(() => {
    updateGlobalLoader(true)
    return getEvent(id, date).finally(() => {
      updateGlobalLoader(false)
    })
  }, [getEvent, updateGlobalLoader, id])

  useEffect(() => {
    fetchEvent()
  }, [fetchEvent])

  const handleEditEvent = (values, isRecurrent) => {
    editEvent(event.id, values).then(() => {
      fireSuccessToast(<Trans>Event edited.</Trans>)
      if (isRecurrent || moment(values.from).format(DATABASE_DATE_FORMAT) !== date) {
        // if recurrent event is changed, the date of event is changed and therefore
        // the link with date won't work
        navigate(routesMap.admin.calendar)
      } else {
        navigate(`${routesMap.admin.calendar}/${id}/date/${date}`)
      }
    })
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper pageTitle={<Trans>Edit event</Trans>} iconPageHeader={<AddCircle />}>
        <EventStepper
          operation="edit"
          handleSubmitEvent={handleEditEvent}
          event={isEventLoaded && event}
          handleBack={() => navigate(`${routesMap.admin.calendar}/${id}/date/${date}`)}
        />
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      editEvent,
      getEvent,
      updateGlobalLoader,
    },
    dispatch
  )
}

EventEditPage.propTypes = {
  editEvent: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      event: store.Event.data,
      isEventLoaded: !store.Event.isLoading,
    }
  }, mapDispatchToProps)
)(EventEditPage)
