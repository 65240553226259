import React from 'react'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {tss} from 'tss-react/mui'
import globalButtonStyle from 'asset/global/jss/component/common/button/globalButtonStyle'
import PropTypes from 'prop-types'

const TableActionsButton = (props) => {
  const {children, ...rest} = props

  const useStyles = tss.create(({theme}) => globalButtonStyle(theme))

  const {classes} = useStyles()

  return (
    <GlobalButton
      className={classes.tableActionsButton}
      justIcon
      round
      simple
      {...rest}
      sx={{
        '& svg': {
          width: '20px !important',
          height: '20px !important',
        },
      }}
    >
      {children}
    </GlobalButton>
  )
}

TableActionsButton.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
}

export default TableActionsButton
