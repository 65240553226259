import React from 'react'
import {LOGGED_USER} from 'helper/global/constants'
import 'App/appStyle.scss'
import {Provider} from 'react-redux'
import store from 'App/provider/store'
import {AbilityContextProvider} from 'App/provider/authorizedAbility'
import App from 'App/App'
import {ThemeProvider} from '@mui/material/styles'
import defaultTheme from 'App/theme/defaultTheme'
import mapPermissionsToAbilities from 'config/mapPermissionsToAbilities'
import {createMongoAbility} from '@casl/ability'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'

const AppWrapper = () => {
  const currentUser = JSON.parse(localStorage.getItem(LOGGED_USER))
  const permissions = currentUser?.user?.permissions || []

  const ability = createMongoAbility(mapPermissionsToAbilities(permissions))

  return (
    <Provider store={store}>
      <AbilityContextProvider value={ability}>
        <ThemeProvider theme={defaultTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </AbilityContextProvider>
    </Provider>
  )
}

export default AppWrapper
