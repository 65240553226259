import httpClient from 'helper/global/httpClient'
import {
  FETCH_LIST_ORGANIZATIONS_REQUEST,
  FETCH_LIST_ORGANIZATIONS_SUCCESS,
  FETCH_MY_ORGANIZATION_REQUEST,
  FETCH_MY_ORGANIZATION_SUCCESS,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
} from 'redux/action/projectSpecific/types'
import endpoints from 'config/endpoints'
import {globalApiErrorHandler} from 'helper/global/functions'
import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'

export function getListOrganizations(
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc',
  filter = ''
) {
  return function (dispatch) {
    const listOrganizationsFilters = {
      listOrganizationsFilters: {
        orderBy,
        orderDirection,
        filter,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listOrganizationsFilters})
    dispatch({type: FETCH_LIST_ORGANIZATIONS_REQUEST})
    return httpClient
      .get(endpoints.organizations, {
        limit,
        offset,
        orderBy: orderBy || null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
        filter: filter || null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_LIST_ORGANIZATIONS_SUCCESS,
          data: response.data,
        })
        return response.data.objects
      })
      .catch(globalApiErrorHandler)
  }
}

export function getOrganization(organizationId) {
  return function (dispatch) {
    dispatch({type: FETCH_ORGANIZATION_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${organizationId}`)
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createOrganization(formParams) {
  return function () {
    return httpClient.post(endpoints.organizations, formParams).catch(globalApiErrorHandler)
  }
}

export function editOrganization(organizationId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.organizations}${organizationId}/`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function deleteOrganization(organizationId) {
  return function () {
    return httpClient
      .del(`${endpoints.organizations}${organizationId}/`)
      .catch(globalApiErrorHandler)
  }
}

export function getMyOrganization() {
  return function (dispatch) {
    dispatch({type: FETCH_MY_ORGANIZATION_REQUEST})
    return httpClient
      .get(endpoints.myOrganization)
      .then((response) => {
        dispatch({
          type: FETCH_MY_ORGANIZATION_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}
export function changeMyOrganization(data) {
  return function () {
    return httpClient.put(endpoints.myOrganization, data).catch(globalApiErrorHandler)
  }
}
