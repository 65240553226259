import React from 'react'
import httpClient from 'helper/global/httpClient'
import {LOGGED_USER, SESSION_ID} from 'helper/global/constants'
import endpoints from 'config/endpoints'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/global/functions'
import {Trans} from '@lingui/macro'
import {
  FETCH_MY_ACCOUNT_REQUEST,
  FETCH_MY_ACCOUNT_SUCCESS,
} from 'redux/action/projectSpecific/types'

export const login = (data) => {
  return () => {
    return httpClient
      .post(endpoints.login, data, {
        headers: {'organization-id': 1}, // required for login
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(SESSION_ID, response.headers['session-id'])
          localStorage.setItem(LOGGED_USER, JSON.stringify({...response.data}))
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)
          return response
        }
      })
      .catch(globalApiErrorHandler)
  }
}

export const logout = () => {
  return () => {
    return httpClient
      .del(endpoints.logout)
      .then((response) => {
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const forgottenPasswordRequest = (data) => {
  return () => {
    return httpClient
      .post(endpoints.forgottenPassword, data)
      .then((response) => {
        fireSuccessToast(<Trans>E-mail was sent.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const resetPassword = (token, data) => {
  return () => {
    return httpClient
      .post(`${endpoints.resetPassword}/${token}`, data)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(SESSION_ID, response.headers['session-id'])
          localStorage.setItem(LOGGED_USER, JSON.stringify({...response.data}))
          fireSuccessToast(<Trans>Password was reset.</Trans>)
        }
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const changePassword = (data) => {
  return () => {
    return httpClient
      .put(endpoints.changePassword, data)
      .then((response) => {
        fireSuccessToast(<Trans>Password was changed.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}

export const getMyAccount = () => {
  return (dispatch) => {
    dispatch({type: FETCH_MY_ACCOUNT_REQUEST})
    return httpClient
      .get(endpoints.myAccount)
      .then((response) => {
        dispatch({
          type: FETCH_MY_ACCOUNT_SUCCESS,
          data: response.data,
        })
        const loggedUser = JSON.parse(localStorage.getItem(LOGGED_USER))
        loggedUser.user.name = response.data?.name
        loggedUser.user.email = response.data?.email
        localStorage.setItem(LOGGED_USER, JSON.stringify(loggedUser))
      })
      .catch(globalApiErrorHandler)
  }
}

export const changeMyAccount = (data) => {
  return () => {
    return httpClient
      .put(endpoints.myAccount, data)
      .then((response) => {
        fireSuccessToast(<Trans>User account updated.</Trans>)
        return response
      })
      .catch(globalApiErrorHandler)
  }
}
