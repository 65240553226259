import React from 'react'
import {Close} from '@mui/icons-material'
import {withStyles} from 'tss-react/mui'
import {counterProposalActionsWrapperStyle} from 'asset/projectSpecific/jss/proposal/counterProposalActionsWrapperStyle'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import ProposalActionsFormFields from 'component/projectSpecific/proposal/proposalForm/ProposaActionsFormFields'
import moment from 'moment'
import GridContainer from 'component/global/common/grid/GridContainer'

const CounterProposalActionsWrapper = (props) => {
  const {
    availableDates,
    formProps,
    index,
    topic,
    classes,
    fieldsCount,
    timeFreeCounterProposals,
    removeCounterProposalSet,
    handleDisableDates,
  } = props

  const selectedDate =
    formProps.values.proposalActions &&
    moment(formProps.values.proposalActions[index]?.date).format(DATABASE_DATE_FORMAT)
  const timeSlotOptions = availableDates[selectedDate] || []
  const customTimeSlot =
    formProps.values.proposalActions &&
    formProps.values.proposalActions[index]?.timeSlot === 'CUSTOM'

  return (
    <div className={classes.counterWrapper}>
      {fieldsCount > 1 && (
        <span className={classes.counterCloseButton}>
          <Close onClick={removeCounterProposalSet} />
        </span>
      )}
      <GridContainer spacing={2}>
        <ProposalActionsFormFields
          timeFreeCounterProposals={timeFreeCounterProposals}
          index={index}
          topic={topic}
          handleDisableDates={handleDisableDates}
          timeSlotOptions={timeSlotOptions}
          selectedDate={selectedDate}
          customTimeSlot={customTimeSlot}
        />
      </GridContainer>
    </div>
  )
}

export default withStyles(CounterProposalActionsWrapper, counterProposalActionsWrapperStyle)
