import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import TextInput from 'component/global/common/formField/TextInput'
import {
  composeValidators,
  required,
  validateEmail,
  validateLength,
} from 'helper/projectSpecific/validations'
import React, {useEffect} from 'react'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {bindActionCreators, compose} from 'redux'
import {getMyOrganization, getOrganization} from 'redux/action/projectSpecific/organizationActions'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import imagePlaceholder from 'asset/global/img/image_placeholder.jpg'
import DialogEditFormButtons from 'component/global/common/dialog/DialogEditFormButtons'
import DialogCreateFormButtons from 'component/global/common/dialog/DialogCreateFormButton'
import UploadField from 'component/global/common/formField/UploadField'

const OrganizationForm = (props) => {
  const {
    organizationId,
    form,
    someOrganization,
    myOrganization,
    isOrganizationLoaded,
    isMyOrganizationLoaded,
    logoChanged,
    logoFile,
  } = props
  const {
    getOrganization,
    getMyOrganization,
    deleteItem,
    handleLogoChanged,
    handleLogoFile,
    onSubmit,
  } = props

  useEffect(() => {
    if (organizationId) {
      getOrganization(organizationId)
    } else if (form === 'edit') {
      getMyOrganization()
    }
  }, [getOrganization, getMyOrganization, organizationId, form])

  const isMyOrganization = organizationId === null && form === 'edit'
  const organization = isMyOrganization ? myOrganization : someOrganization
  const formInitialValues =
    (isMyOrganization ? isMyOrganizationLoaded : organizationId && isOrganizationLoaded) &&
    organization

  const handleImageChange = (e) => {
    let reader = new FileReader()
    let file = e.target.files[0]

    reader.onloadend = () => {
      handleLogoChanged(true)
      handleLogoFile(reader.result)
    }
    file && reader.readAsDataURL(file)
  }

  const removeLogo = () => {
    handleLogoChanged(true)
    handleLogoFile(null)
  }

  const revertLogo = () => {
    handleLogoChanged(false)
    handleLogoFile(null)
  }

  const canRemoveLogo = form === 'edit' && !!organization.logoUrl
  const hasLogo = !!organization.logoUrl
  const getLogoSrc = () => {
    if (logoChanged) {
      return logoFile ?? imagePlaceholder
    } else if (form === 'edit' && organization.logoUrl) {
      return organization.logoUrl
    }
    return imagePlaceholder
  }

  return (
    <Form onSubmit={onSubmit} initialValues={formInitialValues}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={4}>
              <GridItem xs={12} md={6}>
                <UploadField
                  imageSrc={getLogoSrc()}
                  canRemoveFile={canRemoveLogo}
                  fileChanged={logoChanged}
                  hasLogo={hasLogo}
                  onImageChange={handleImageChange}
                  removeFile={removeLogo}
                  revertFile={revertLogo}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <Field
                      name="name"
                      label={<Trans>Organization name</Trans>}
                      validate={composeValidators(required, validateLength(100))}
                      component={TextInput}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="admin.name"
                      label={<Trans>Administrator name</Trans>}
                      validate={composeValidators(required, validateLength(100))}
                      component={TextInput}
                      disabled={form === 'edit'}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="admin.email"
                      label={<Trans>Administrator e-mail</Trans>}
                      validate={composeValidators(required, validateEmail)}
                      component={TextInput}
                      disabled={form === 'edit'}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <GridItem xs={12} pt={1}>
              {form === 'edit' && !isMyOrganization ? (
                <DialogEditFormButtons
                  formProps={formProps}
                  permissionAsset="Organizations"
                  deleteItem={deleteItem !== null ? deleteItem(organization) : null}
                />
              ) : (
                <DialogCreateFormButtons
                  buttonText={isMyOrganization ? <Trans>Edit</Trans> : null}
                />
              )}
            </GridItem>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganization,
      getMyOrganization,
    },
    dispatch
  )
}

OrganizationForm.propTypes = {
  classes: PropTypes.object,
  someOrganization: PropTypes.object,
  organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  form: PropTypes.string,
  isOrganizationLoading: PropTypes.bool,
  logoChanged: PropTypes.bool,
  getOrganization: PropTypes.func,
  handleLogoChanged: PropTypes.func,
  deleteItem: PropTypes.func,
  handleLogoFile: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      someOrganization: store.Organization.data,
      myOrganization: store.MyOrganization.data,
      isOrganizationLoaded: !store.Organization.isLoading,
      isMyOrganizationLoaded: !store.MyOrganization.isLoading,
    }
  }, mapDispatchToProps)
)(OrganizationForm)
