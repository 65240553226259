import {grayColor, primaryColor, warningColor} from 'asset/global/jss/styleHelpers'

const globalDialogStyle = (theme) => ({
  muiDialogActions: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  muiDialogTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: '16px 57px 8px 41px',
    color: primaryColor[0],
  },
  closeDialogTitleButton: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  warningIcon: {
    color: warningColor[0],
    fontSize: 90,
  },
  warningBody: {
    margin: '10px 0',
  },
  warningTitle: {
    fontSize: '1.5rem',
    color: grayColor[2],
  },
})

export default globalDialogStyle
