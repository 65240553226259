import {darkText, dividerColor} from 'asset/global/jss/styleHelpers'

const globalTabsStyle = (theme) => ({
  tabs: {
    height: '50px',
    alignItems: 'flex-end',
    borderBottom: '1px solid ' + dividerColor,
    '& .MuiTab-root': {
      fontSize: '1.4rem',
      fontWeight: '300',
      textTransform: 'none',
      paddingLeft: '2rem',
      paddingRight: '2rem',
    },
    '& .MuiTab-textColorPrimary:not(.Mui-selected):hover': {
      color: darkText,
    },
  },
})

export default globalTabsStyle
