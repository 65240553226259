import React from 'react'
import {proposalDetailStyle} from 'asset/projectSpecific/jss/proposal/proposalDetailStyle'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'
import {prepareTimeSlotLabelsView} from 'helper/projectSpecific/functions'
import moment from 'moment'
import {DATE_FORMAT, hasOnlyPartnerRole, TIME_FORMAT} from 'helper/global/constants'
import PropTypes from 'prop-types'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {Trans} from '@lingui/macro'
import {Check} from '@mui/icons-material'

const ProposalActions = (props) => {
  const {proposalActions, classes, proposalStatus, openConfirmModal} = props

  return (
    <>
      {proposalActions
        .sort((a) => {
          if (a.status === 'PENDING' || a.status === 'CONFIRMED') return -1
          else return 1
        })
        .map((action, index) => {
          const customDateTimeFrom =
            action.customTimeFrom && moment(`${action.date} ${action.customTimeFrom}`)
          const proposalActionItemClasses = cx(classes.proposalActionItem, [
            {[classes.deniedProposalAction]: action.status === 'DENIED'},
          ])
          const customPreparationLength =
            action.customPreparationLength && Math.round(action.customPreparationLength * 60)
          return (
            <div key={index} className={classes.proposalActionWrapper}>
              <p className={proposalActionItemClasses}>
                <>{moment(action.date).format(DATE_FORMAT)}</>&nbsp;&nbsp;&nbsp;
                {action.timeSlot !== 'CUSTOM' ? (
                  <>{prepareTimeSlotLabelsView(action.timeSlot)}</>
                ) : (
                  <>
                    <>{customDateTimeFrom.format(TIME_FORMAT)}</>
                    &nbsp;&ndash;&nbsp;
                    <>{customDateTimeFrom.add(action.customTimeLength, 'h').format(TIME_FORMAT)}</>
                  </>
                )}
                {customPreparationLength && (
                  <>
                    &nbsp;{`(${customPreparationLength} min. `}
                    <Trans>preparation</Trans>)
                  </>
                )}
              </p>
              {proposalStatus === 'COUNTER_PROPOSAL' && action.status === 'PENDING' && (
                <GlobalButton
                  backgroundColor="success"
                  startIcon={<Check />}
                  onClick={openConfirmModal(action)}
                  size="small"
                  className={classes.confirmProposalButton}
                >
                  {hasOnlyPartnerRole ? <Trans>Accept</Trans> : <Trans>Confirm</Trans>}
                </GlobalButton>
              )}
            </div>
          )
        })}
    </>
  )
}

ProposalActions.propTypes = {
  classes: PropTypes.object.isRequired,
  proposalActions: PropTypes.array,
}

export default withStyles(ProposalActions, proposalDetailStyle)
