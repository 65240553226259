import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {required} from 'helper/projectSpecific/validations'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import TextInput from 'component/global/common/formField/TextInput'
import {Box} from '@mui/material'
import GlobalButton from 'component/global/common/button/GlobalButton'
import WarningMessage from 'component/projectSpecific/common/WarningMessage'

const ProposalRejectForm = (props) => {
  const {onSubmitReject} = props

  return (
    <Form onSubmit={onSubmitReject}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <GridContainer spacing={4}>
            <GridItem xs={12}>
              <WarningMessage
                warningMessage={<Trans>Write denial reason of event proposal.</Trans>}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="reason"
                label={<Trans>Rejection reason</Trans>}
                component={TextInput}
                validate={required}
              />
            </GridItem>
            <GridItem xs={12}>
              <Box display="flex" justifyContent={'flex-end'} flexGrow={1}>
                <GlobalButton type="submit" backgroundColor="primary">
                  <Trans>Reject</Trans>
                </GlobalButton>
              </Box>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Form>
  )
}

ProposalRejectForm.propTypes = {
  onSubmitReject: PropTypes.func,
}

export default ProposalRejectForm
