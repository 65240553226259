import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import globalButtonStyle from 'asset/global/jss/component/common/button/globalButtonStyle'
import IconButton from '@mui/material/IconButton'

const GlobalIconButton = (props) => {
  const {classes, color, className, children, ...rest} = props

  const btnClasses = cx({
    [classes[color]]: color,
    [className]: className,
  })
  return (
    <IconButton className={btnClasses} {...rest} size="large">
      {children}
    </IconButton>
  )
}

GlobalIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'iconPrimary',
    'iconSecondary',
    'iconInfo',
    'iconSuccess',
    'iconWarning',
    'iconDanger',
  ]),
  className: PropTypes.string,
  children: PropTypes.node,
}

export default withStyles(GlobalIconButton, globalButtonStyle)
