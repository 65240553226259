import React from 'react'
import {ArrowBackIos, ArrowForwardIos} from '@mui/icons-material'
import GridItem from 'component/global/common/grid/GridItem'
import GlobalIconButton from 'component/global/common/button/GlobalIconButton'
import {withStyles} from 'tss-react/mui'
import {topicsCalendarStyle} from 'asset/projectSpecific/jss/topic/topicsCalendarStyle'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {updateGlobalLoader} from 'redux/action/global/loaderActions'
import updateAppFilterStates from 'redux/action/global/appFilterStatesAction'

const YearSwitcher = (props) => {
  const {year, classes, updateAppFilterStates} = props

  const handleUpdateFilter = (increase) => () => {
    let newValue = year
    increase ? newValue++ : newValue--
    updateAppFilterStates({topicsCalendarFilters: {year: newValue}})
  }

  return (
    <GridItem xs={12} className={classes.topicYearSwitcher} container justifyContent="center">
      <GlobalIconButton color="iconPrimary" onClick={handleUpdateFilter(false)}>
        <ArrowBackIos />
      </GlobalIconButton>
      <h1>{year}</h1>
      <GlobalIconButton color="iconPrimary" onClick={handleUpdateFilter(true)}>
        <ArrowForwardIos />
      </GlobalIconButton>
    </GridItem>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalLoader,
      updateAppFilterStates,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      year: store.AppFilterStates.topicsCalendarFilters.year,
    }
  }, mapDispatchToProps)
)(withStyles(YearSwitcher, topicsCalendarStyle))
