import React from 'react'
import {FormControl, FormHelperText, Input, InputLabel, MenuItem, Select} from '@mui/material'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'

const SelectField = (props) => {
  const {
    classes,
    meta,
    helperText,
    required,
    labelProps,
    label,
    options,
    id,
    input: {value: selectedValue, onChange},
    emptyOption,
    emptyOptionLabel,
    customOnChange,
    valuesToString,
    classNames,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  const handleChange = (e) => {
    onChange(e)
    customOnChange && customOnChange(props.input.name, e.target.value)
  }

  return (
    <FormControl
      variant="standard"
      error={!!errorMessage}
      className={cx(classNames, classes.formSelectControl)}
      required={required}
    >
      <InputLabel className={errorMessage ? 'error' : ''} {...labelProps}>
        {label}
      </InputLabel>
      <Select
        variant="standard"
        className={classes.customSelectClasses}
        value={selectedValue ? selectedValue : ''}
        onChange={handleChange}
        input={<Input id={id} placement="bottom" onChange={handleChange} />}
        {...rest}
      >
        {emptyOption && (
          <MenuItem key="empty" value="">
            <i>{emptyOptionLabel || 'reset'}</i>
          </MenuItem>
        )}

        {options?.map((item) =>
          item.label ? (
            <MenuItem key={item.value} value={valuesToString ? item.value.toString() : item.value}>
              {item.label}
            </MenuItem>
          ) : (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          )
        )}
      </Select>
      <FormHelperText>{errorMessage || helperText || ' '}</FormHelperText>
    </FormControl>
  )
}

SelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.node,
  label: PropTypes.node,
  options: PropTypes.array,
  id: PropTypes.number,
  emptyOption: PropTypes.bool,
  valuesToString: PropTypes.bool,
}

export default withStyles(SelectField, globalFormFieldStyles)
