import React from 'react'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import ProposalActions from 'component/projectSpecific/proposal/proposalDetail/ProposalActions'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'

const ProposalDateTimeModal = (props) => {
  const {data, closeModal} = props
  return (
    <DialogWrapper
      open={data !== null}
      maxWidth="md"
      handleClose={closeModal}
      title={<Trans>Counter proposals</Trans>}
    >
      {data !== null && <ProposalActions proposalActions={data} />}
    </DialogWrapper>
  )
}

ProposalDateTimeModal.propTypes = {
  closeModal: PropTypes.func,
}

export default ProposalDateTimeModal
