import React from 'react'
import EventDetailButtons from 'component/projectSpecific/event/eventDetail/EventDetailButtons'
import ParticipantButtons from 'component/projectSpecific/event/eventParticipant/ParticipantButtons'
import PropTypes from 'prop-types'
import PricingButtons from 'component/projectSpecific/event/eventPrice/PricingButtons'

const EventDetailActionsHeader = (props) => {
  const {
    tabsValue,
    shareOnFacebook,
    copyUrl,
    generateQr,
    exportToXls,
    editEventDetail,
    hardDeleteEvent,
    openMediaServerDialog,
    openPrintDialog,
    hasPricing,
    handleOpenPricingDialog,
    isEventPodcast,
  } = props

  if (tabsValue === 'detail') {
    return (
      <EventDetailButtons
        shareOnFacebook={shareOnFacebook}
        copyUrl={copyUrl}
        generateQr={generateQr}
        editEvent={editEventDetail}
        deleteEvent={hardDeleteEvent}
        openMediaServerDialog={openMediaServerDialog}
        openPrintDialog={openPrintDialog}
        isEventPodcast={isEventPodcast}
      />
    )
  } else if (tabsValue === 'participant') {
    return <ParticipantButtons exportToXls={exportToXls} />
  } else {
    return (
      <PricingButtons hasPricing={hasPricing} handleOpenPricingDialog={handleOpenPricingDialog} />
    )
  }
}

EventDetailActionsHeader.propTypes = {
  tabsValue: PropTypes.string,
  shareOnFacebook: PropTypes.func,
  copyUrl: PropTypes.func,
  generateQr: PropTypes.func,
  exportToXls: PropTypes.func,
  hardDeleteEvent: PropTypes.func,
  openMediaServerDialog: PropTypes.func,
  openPrintDialog: PropTypes.func,
}

export default EventDetailActionsHeader
