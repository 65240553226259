import {dangerColor, grayColor, primaryColor} from 'asset/global/jss/styleHelpers'

export const eventDetailStyle = (theme) => ({
  detailEventDateTimeItem: {
    color: grayColor[19],
    fontWeight: 'bold',
    display: 'flex',
    marginBlockStart: '1em',
    marginBlockEnd: '1em',
    marginInlineStart: '0px',
    marginInlineEnd: '0px',
  },
  detailEventDateTimeItemDate: {
    display: 'inline-flex',
    width: '70px',
    paddingRight: '5px',
  },
  topItem: {
    '& svg': {
      fill: primaryColor[0],
    },
    '& span': {
      '&:first-of-type': {
        marginRight: '20px',
      },
      '&:last-of-type': {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: primaryColor[0],
      },
    },
  },
  eventImg: {
    width: '100%',
    height: 'auto',
    maxWidth: '250px',
    maxHeight: '250px',
  },
  spaceConfigurationLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& svg': {
      color: primaryColor[0],
      cursor: 'pointer',
    },
  },
  mediaVisuals: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',

    '& .image-placeholder': {
      backgroundColor: primaryColor[0] + 50,
      border: '1px solid ' + primaryColor[0] + 'ee',
      borderRadius: '3px',
      color: primaryColor[0] + 'cc',
      padding: '5px',
      fontWeight: '700',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
    },
    '& .vertical': {
      paddingBottom: '10px',
      width: '40%',
      minWidth: '90px',
      '& .image-placeholder': {
        height: '125px',
        width: '90px',
      },
    },
    '& .horizontal': {
      paddingBottom: '10px',
      width: '60%',
      minWidth: '150px',
      '& .image-placeholder': {
        height: '125px',
        width: '150px',
      },
    },
  },
  editVisualsBtn: {
    marginTop: '10px',
    padding: '6px 20px',
  },
  labelColor: {
    color: '#999999',
  },
  requiredColor: {
    color: dangerColor[0],
  },
  requiredHelperText: {
    fontSize: 12,
    fontWeight: 400,
    lineHeight: 1.66,
    marginTop: '3px',
  },
})
