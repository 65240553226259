import React from 'react'
import {LOGGED_USER, SESSION_ID} from 'helper/global/constants'
import {toast} from 'react-toastify'
import {routesMap} from 'config/routesMap'
import GlobalLink from 'component/global/common/button/GlobalLink'
import {Typography} from '@mui/material'
import {Trans} from '@lingui/macro'
import {router} from 'App/provider/router'

export const getActualUser = () => {
  return JSON.parse(localStorage.getItem(LOGGED_USER))
}

export const prepareFilteringState = (orderBy, orderDirection, property) => {
  let sort = {orderDirection: 'asc', orderBy: property}

  if (orderBy === property) {
    if (orderDirection === 'asc') {
      sort = {orderDirection: 'desc', orderBy: property}
    } else {
      sort = {orderDirection: 'asc', orderBy: null}
    }
  }

  return sort
}

export const fireSuccessToast = (message, options) => {
  return toast.success(message, options)
}

export const fireErrorToast = (message, options = {autoClose: 5000}) => {
  return toast.error(message, options)
}

export const fireWarningToast = (message, options) => {
  return toast.warning(message, options)
}

export const translateErrorMessage = (message) => {
  if (message === 'Wrong user name or password.') {
    return <Trans>Wrong user name or password.</Trans>
  }
  if (message === 'Old password is not valid.') {
    return <Trans>Old password is not valid.</Trans>
  }
  if (message === 'Token invalid error.') {
    return <Trans>Token invalid error.</Trans>
  }
  if (message === 'Token expired.') {
    return <Trans>Token expired.</Trans>
  }
  return <Trans>Bad request.</Trans>
}

const loopThroughErrors = (message, errors) => {
  if (errors?.length !== 0) {
    errors.forEach((err) => {
      fireErrorToast(
        <>
          {message}: {err?.message}
        </>
      )
      if (err?.message === 'Session ID not authenticated.') {
        clearLocalStorage()
        // redirect('/login')
      }
    })
  } else {
    fireErrorToast(message)
  }
}

export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data} = error.response

    switch (status) {
      case 400:
        data?.errors
          ? loopThroughErrors(<Trans>Bad request</Trans>, data?.errors)
          : data?.message && fireErrorToast(data.message)
        return Promise.reject(error)
      case 401:
        clearLocalStorage()
        break
      case 403:
        data?.errors
          ? loopThroughErrors(<Trans>Forbidden</Trans>, data?.errors)
          : data?.message && fireErrorToast(data.message)
        return Promise.reject(error)
      case 500:
        fireErrorToast(<Trans>Internal server error</Trans>)
        break
      default:
        fireErrorToast(<Trans>Unknown server error</Trans>)
        break
    }
  } else {
    console.error(error)
  }
  return true
}

export const clearLocalStorage = () => {
  localStorage.removeItem(LOGGED_USER)
  localStorage.removeItem(SESSION_ID)
  router.navigate(routesMap.auth.login)
}

export const prepareBreadcrumbs = (classes, location) => {
  const path = location.pathname
  let splitPath = path.split('/')
  let basePath = `/${splitPath[1]}/`
  let parsedPath = splitPath.slice(2).filter((path) => path)

  // list of breadcrumbs always have home link
  let breadcrumbs = [
    <GlobalLink key="home" to={routesMap.admin.calendar} globalLinkClassName={classes.breadcrumbs}>
      <Trans>Experience Center</Trans>
    </GlobalLink>,
  ]

  const getBreadcrumbTranslation = {
    events: <Trans>Events</Trans>,
    proposals: <Trans>Proposals</Trans>,
    organizations: <Trans>Organization</Trans>,
    topics: <Trans>Topics</Trans>,
    statistics: <Trans>Statistics</Trans>,
    calendar: <Trans>Calendar</Trans>,
    new: <Trans>New</Trans>,
    date: <Trans>Date</Trans>,
    reservations: <Trans>Reservations</Trans>,
  }

  // iterate through (relevant) parsed routes
  while (parsedPath.length > 1) {
    if (isNaN(parsedPath[0])) {
      breadcrumbs.push(
        <GlobalLink
          key={parsedPath[0]}
          to={`${basePath}${parsedPath[0]}`}
          globalLinkClassName={classes.breadcrumbs}
        >
          {getBreadcrumbTranslation?.[parsedPath[0]] || parsedPath[0]}
        </GlobalLink>
      )
    }
    // update basePath for next iteration
    basePath = `${basePath}${parsedPath[0]}/`
    // shift first item from breadcrumbs which is prepared
    parsedPath.shift()
  }

  // last breadcrumb which is not a link
  const lastBreadcrumbText = () => {
    if (Number(parsedPath[0])) {
      return <Trans>Detail</Trans>
    }
    return (
      <span className={classes.lastBreadcrumb}>
        {getBreadcrumbTranslation?.[parsedPath[0]] || parsedPath[0]}
      </span>
    )
  }

  breadcrumbs.push(
    <Typography key="last" color="textPrimary" sx={{fontWeight: 'bold'}}>
      {parsedPath.length !== 0 && lastBreadcrumbText()}
    </Typography>
  )

  return breadcrumbs
}

/**
 * Remap list of object for usage in select field option
 * @param {Object[]} list - what we want to remap
 * @param {string} [valueKey=id] - key of value
 * @param {string} [labelKey=name] - key of label
 */
export const getSelectOptions = (list = [], valueKey = 'id', labelKey = 'name') => {
  return list
    .filter((r) => r?.[valueKey] && r?.[labelKey])
    .map((item) => {
      return {
        value: item?.[valueKey],
        label: item?.[labelKey],
      }
    })
}

/**
 * Join array of items with separator
 * like array.join(separator) or array.toString()
 * @param {array} list - list we want to modify
 * @param {string|number|node} [separator=,] - Separator for array
 */
export const joinArray = (list, separator = ',') => {
  if (list.length === 0) {
    return []
  }
  // slice(1) because first part (index 0) is set as initial for reduce function
  // separator is added before every item in list except first
  return list.slice(1).reduce(
    (total, currentIteration) => {
      // add separator with current iteration of list to previous result
      // starting with first item (index 0) from list as initial value
      return total.concat([separator, currentIteration])
    },
    [list[0]]
  )
}

/**
 * Function that take any list and return the items found by key
 * @param {object[]} list - array of object where to search
 * @param {string|object} value - Value we are searching for
 * @param {string} [key='value'] - Key in object, where value should be
 */
export const getItemFromObjectList = (list, value, key = 'value') => {
  return list.find((item) => item[key] === value)
}

export const redirectWithMessage = (message) => {
  fireWarningToast(message)
  router.navigate(routesMap.admin.calendar)
}
