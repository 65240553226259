import React from 'react'
import {withStyles} from 'tss-react/mui'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import Cell from 'component/global/common/table/Cell'
import PropTypes from 'prop-types'
import cx from 'classnames'

const DetailTableWrapper = (props) => {
  const {
    classes,
    classNames,
    data,
    alignTop,
    cellWrap,
    strongValue = true,
    valueCellAlign = 'inherit',
  } = props
  return (
    <div className={cx(classes.detailTableWrapper, classNames)}>
      {data && (
        <Table>
          <TableBody>
            {data?.map((item, index) => {
              const {field, value, visible = true} = item
              return (
                visible && (
                  <TableRow hover tabIndex={-1} key={index}>
                    <Cell isDetailCell alignTop={alignTop}>
                      {field}
                    </Cell>
                    <Cell cellWrap={cellWrap} isDetailCell align={valueCellAlign}>
                      {strongValue ? <strong>{value}</strong> : value}
                    </Cell>
                  </TableRow>
                )
              )
            })}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

DetailTableWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  cellWrap: PropTypes.bool,
}

export default withStyles(DetailTableWrapper, globalWrapperStyles)
