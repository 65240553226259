import httpClient from 'helper/global/httpClient'
import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'
import {globalApiErrorHandler} from 'helper/global/functions'
import endpoints from 'config/endpoints'
import {
  FETCH_SIMPLE_LIST_TOPICS_REQUEST,
  FETCH_SIMPLE_LIST_TOPICS_SUCCESS,
  FETCH_TOPIC_REQUEST,
  FETCH_TOPIC_SUCCESS,
  FETCH_TOPICS_CALENDAR_REQUEST,
  FETCH_TOPICS_CALENDAR_SUCCESS,
} from 'redux/action/projectSpecific/types'

export function getTopicsCalendar(year) {
  return function (dispatch) {
    const listTopicsFilters = {
      topicsCalendarFilter: {
        year,
      },
    }
    dispatch({
      type: UPDATE_FILTERS_STATE,
      data: listTopicsFilters,
    })
    dispatch({type: FETCH_TOPICS_CALENDAR_REQUEST})
    return httpClient
      .get(endpoints.topicsCalendar, {
        year: year,
      })
      .then((response) => {
        dispatch({
          type: FETCH_TOPICS_CALENDAR_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getTopics(
  year = null,
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc'
) {
  return function (dispatch) {
    dispatch({type: FETCH_SIMPLE_LIST_TOPICS_REQUEST})
    return httpClient
      .get(endpoints.topics, {
        year,
        offset,
        limit,
        orderBy,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_SIMPLE_LIST_TOPICS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getTopic(topicId) {
  return (dispatch) => {
    dispatch({type: FETCH_TOPIC_REQUEST})
    return httpClient
      .get(`${endpoints.topics}${topicId}/`)
      .then((response) => {
        dispatch({
          type: FETCH_TOPIC_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createTopic(formParams) {
  return function () {
    return httpClient.post(endpoints.topics, formParams).catch(globalApiErrorHandler)
  }
}

export function editTopic(topicId, formParams) {
  return function () {
    return httpClient.put(`${endpoints.topics}${topicId}/`, formParams).catch(globalApiErrorHandler)
  }
}

export function deleteTopic(topicId) {
  return function () {
    return httpClient.del(`${endpoints.topics}${topicId}/`).catch(globalApiErrorHandler)
  }
}
