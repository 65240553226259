import httpClient from 'helper/global/httpClient'
import {applyMiddleware, compose, createStore} from 'redux'
import axiosMiddleware from 'redux-axios-middleware'
import thunk from 'redux-thunk'
import rootReducer from 'redux/reducer'

const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(axiosMiddleware(httpClient), thunk))
)
export default store
