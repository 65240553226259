import {Star} from '@mui/icons-material'
import React from 'react'
import {eventDetailStyle} from 'asset/projectSpecific/jss/event/eventDetailStyle'
import {withStyles} from 'tss-react/mui'

const EventDetailTitle = (props) => {
  const {name, classes} = props

  return (
    <div className={classes.topItem}>
      <span>{name}</span>
      <span>
        <Star />
        <span>TOP</span>
      </span>
    </div>
  )
}

export default withStyles(EventDetailTitle, eventDetailStyle)
