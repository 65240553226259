import {Field, Form} from 'react-final-form'
import React from 'react'
import GridContainer from 'component/global/common/grid/GridContainer'
import {Trans} from '@lingui/macro'
import TextInput from 'component/global/common/formField/TextInput'
import InputAdornment from '@mui/material/InputAdornment'
import {IconButton} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import {Search} from '@mui/icons-material'
import PropTypes from 'prop-types'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'

const FullTextSearchForm = (props) => {
  const {handleSubmit, classes} = props

  return (
    <Form onSubmit={handleSubmit}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer justifyContent="flex-start" className={classes.fullTextSearch}>
              <Field
                name="filter"
                label={<Trans>Fulltext search</Trans>}
                component={TextInput}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={formProps.handleSubmit} color="primary" size="large">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

FullTextSearchForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

export default withStyles(FullTextSearchForm, globalFormFieldStyles)
