import React, {useContext, useEffect, useState} from 'react'
import {getDate} from 'helper/projectSpecific/calendarFunctions'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {withStyles} from 'tss-react/mui'
import calendarStyle from 'asset/global/jss/component/calendar/calendarStyle'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import CalendarDayEvents from 'component/projectSpecific/event/eventList/CalendarDayEvents'
import {routesMap} from 'config/routesMap'
import {Trans} from '@lingui/macro'
import {AbilityContext} from 'App/provider/authorizedAbility'
import BeachAccessIcon from '@mui/icons-material/BeachAccess'
import {useNavigate} from 'react-router-dom'

const EventCalendarCell = (props) => {
  const {cell, calendar, calendarLoading, showAllEvents, renderAll} = props

  const ability = useContext(AbilityContext)
  const navigate = useNavigate()

  const [cellState, setCellState] = useState()

  useEffect(() => {
    const newCellState = calendar.filter(
      (day) =>
        moment(day.date).format(DATABASE_DATE_FORMAT) ===
        (cell.date && cell.date.format(DATABASE_DATE_FORMAT))
    )
    if (newCellState[0] && newCellState[0].events) {
      newCellState[0].events.sort((a, b) => moment(a.from) - moment(b.from))
    }

    setCellState(...newCellState)
  }, [calendar, cell, calendarLoading])

  const openEventDetail = (event) => () => {
    if (ability.can('read', 'Event')) {
      navigate(`${routesMap.admin.calendar}/${event.id}/date/${getDate(event.from)}`)
    }
  }

  const isTodayClass = () => {
    if (getDate(cell.date) === moment().format(DATABASE_DATE_FORMAT)) {
      return 'today-cell'
    }
  }

  const renderCalendarDayEvents = () => {
    const calendarDaysToRender = []
    const daysToRender =
      cellState.events.length > 2
        ? renderAll
          ? cellState.events.length
          : 2
        : cellState.events.length

    for (let i = 0; i < daysToRender; i++) {
      calendarDaysToRender.push(
        <CalendarDayEvents openEventDetail={openEventDetail} key={i} event={cellState.events[i]} />
      )
    }

    if (cellState.events.length > 2) {
      if (!renderAll) {
        calendarDaysToRender.push(
          <div className="show-more-less" key="show-all" onClick={showAllEvents}>
            <Trans>show more</Trans>
          </div>
        )
      } else {
        calendarDaysToRender.push(
          <div className="show-more-less" key="show-all" onClick={showAllEvents}>
            <Trans>show less</Trans>
          </div>
        )
      }
    }
    return calendarDaysToRender
  }

  return (
    <td className={isTodayClass()}>
      <div className="cell-wrapper">
        <div className="cell-date">
          {cell.date && cell.date.date()}
          {cellState && cellState.businessDayOff && (
            <BeachAccessIcon className={'cell-date-holiday-icon'} />
          )}
        </div>
        {cellState && cellState.businessDayOff && (
          <div className="calendar-event-wrapper day-off">{cellState.businessDayOff.name}</div>
        )}
        {cellState && renderCalendarDayEvents()}
      </div>
    </td>
  )
}

export default compose(
  connect((store) => {
    return {
      calendar: store.EventsCalendar.data,
      calendarLoading: store.EventsCalendar.isLoading,
    }
  }, {})
)(withStyles(EventCalendarCell, calendarStyle))
