import moment from 'moment'
import {DAYS} from 'helper/projectSpecific/constants'
import {DATABASE_DATE_FORMAT, TIME_FORMAT} from 'helper/global/constants'

export const createCalendarGrid = () => {
  let grid = []
  for (let row = 0; row < 6; row++) {
    grid.push([])
    for (let col = 0; col < 7; col++) {
      const cell = {}
      grid[row].push(cell)
    }
  }
  return grid
}

export const getFirstWeekOfMonth = (selectedYear, selectedMonth) => {
  return moment().year(selectedYear).month(selectedMonth).startOf('month').week()
}

export const getFirstDayOfWeek = (selectedYear, selectedMonth) => {
  const firstWeek = getFirstWeekOfMonth(selectedYear, selectedMonth)
  // first week of month can be in previous year (case for January)
  const myYear = firstWeek >= 52 ? selectedYear - 1 : selectedYear

  return moment().weekYear(myYear).week(firstWeek).startOf('week')
}

export const fillCalendarWithDates = (selectedYear, selectedMonth, grid, setGrid) => {
  // get number of week in year (i.e. 40)
  const firstWeekOfMonth = getFirstWeekOfMonth(selectedYear, selectedMonth)

  // first week of month can be in previous year (case for January)
  const myYear = firstWeekOfMonth >= 52 ? selectedYear - 1 : selectedYear

  // get first day of first week in month (because it might be a day of previous month)
  let myDate = moment().weekYear(myYear).week(firstWeekOfMonth).startOf('week')

  const newGrid = [...grid]

  newGrid.forEach((row, rowIndex) => {
    if (grid[rowIndex] === 'day-detail' || grid[rowIndex] === 'week-detail') return

    row.forEach((col, colIndex) => {
      if (grid[rowIndex][colIndex] === 'day-detail') return

      // moment must use clone, otherwise same date will be placed in all cells of grid
      const dateClone = myDate.clone()
      grid[rowIndex][colIndex] = {date: dateClone}
      myDate.add(1, 'd')
    })
  })

  return setGrid(newGrid)
}

// if the date is not a moment then create moment from the date
export const ensureMoment = (date) => {
  let momentDate = date
  if (!moment.isMoment(moment)) {
    momentDate = moment(date)
  }
  return momentDate
}

// index move due to the english week calendar starting with sunday
export const getDay = (date) => {
  const momentDate = ensureMoment(date)
  return DAYS[momentDate.day()]
}

export const getTime = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(TIME_FORMAT)
}

export const getDate = (date) => {
  const momentDate = ensureMoment(date)
  return momentDate.format(DATABASE_DATE_FORMAT)
}
