import React from 'react'
import {
  ADDITIONAL_REQUIREMENTS,
  EVENT_PLATFORMS,
  EVENT_SPACE_CONFIGURATIONS,
  EVENT_TYPES,
  EVENT_ZONES,
  ORGANIZATIONAL_REQUIREMENTS,
  ORGANIZATIONAL_SETUP,
  PROPOSAL_STATES,
  RR_CONSTANTS,
  TECHNICAL_REQUIREMENTS,
  TIME_SLOTS_LIST,
} from 'helper/projectSpecific/constants'
import {getItemFromObjectList, joinArray} from 'helper/global/functions'
import {Trans} from '@lingui/macro'
import ParticipantsDetailCell from 'component/projectSpecific/event/eventDetail/ParticipantsDetailCell'
import EstimatedCapacityDetailCell from 'component/projectSpecific/proposal/proposalDetail/EstimatedCapacityDetailCell'
import {RRule} from 'rrule'
import moment from 'moment'
import {DATE_FORMAT} from 'helper/global/constants'

export const prepareTechnicalRequirementsList = (active) => {
  const requirementsToRender = TECHNICAL_REQUIREMENTS.filter((item) =>
    active.includes(item.value)
  ).map((item) => item.label)
  return joinArray(requirementsToRender, ', ')
}

export const prepareOrganizationalRequirementsList = (active) => {
  const requirementsToRender = ORGANIZATIONAL_REQUIREMENTS.filter((item) =>
    active.includes(item.value)
  ).map((item) => item.label)
  return joinArray(requirementsToRender, ', ')
}

export const prepareAdditionalRequirementsList = (active) => {
  const requirementsToRender = ADDITIONAL_REQUIREMENTS.filter((item) =>
    active.includes(item.value)
  ).map((item) => item.label)
  return joinArray(requirementsToRender, ', ')
}

export const prepareTimeSlotLabelsView = (timeSlot) => {
  return getItemFromObjectList(TIME_SLOTS_LIST, timeSlot).label
}

export const getProposalStatus = (status) => {
  return getItemFromObjectList(PROPOSAL_STATES, status)
}

export const renderRequirementsArray = (list) => {
  return list.map((item, index) => {
    return <span key={index}>{item}</span>
  })
}

const getOrganizationalSetup = ({organizationalSetup}) => {
  if (organizationalSetup) {
    return ORGANIZATIONAL_SETUP.filter((option) => option.value === organizationalSetup)[0].label
  }
  return null
}

export const requirementsData = (item, isAdmin = false, isPodcast) => {
  let requirementsData = []

  if (!isPodcast) {
    requirementsData.push(
      {
        field: <Trans>Technical requirements</Trans>,
        value: (
          <>
            <div>
              <strong>
                {renderRequirementsArray(
                  prepareTechnicalRequirementsList(item.technicalRequirements)
                )}
              </strong>
            </div>
            {item.technicalNote && <div style={{marginTop: '10px'}}>{item.technicalNote}</div>}
          </>
        ),
      },
      {
        field: <Trans>Organizational requirements</Trans>,
        value: (
          <>
            <div>
              <strong>
                {renderRequirementsArray(
                  prepareOrganizationalRequirementsList(item.organizationalRequirements)
                )}
              </strong>
            </div>
            {item.organizationalNote && (
              <div style={{marginTop: '5px'}}>{item.organizationalNote}</div>
            )}
          </>
        ),
      },
      {
        field: <Trans>Additional requirements</Trans>,
        value: (
          <>
            <div>
              <strong>
                {renderRequirementsArray(
                  prepareAdditionalRequirementsList(item.additionalRequirements)
                )}
              </strong>
            </div>
            {item.additionalNote && <div style={{marginTop: '10px'}}>{item.additionalNote}</div>}
          </>
        ),
      }
    )
  }

  if (isAdmin) {
    requirementsData.push({
      field: <Trans>Internal requirements</Trans>,
      value: (
        <>
          {isAdmin && item.internalTechnicalNote && <div>{item.internalTechnicalNote}</div>}
          {isAdmin && item.internalOrganizationalNote && (
            <div style={{marginTop: '10px'}}>{item.internalOrganizationalNote}</div>
          )}
        </>
      ),
    })
  }

  if (item.organizationalSetup) {
    requirementsData.splice(2, 0, {
      field: <Trans>Space setup</Trans>,
      value: (
        <div>
          <strong>{getOrganizationalSetup(item)}</strong>
        </div>
      ),
    })
  }
  return requirementsData
}

export const getProposalEventGenericInfos = (item, type, isAdmin = false) => {
  const genericInfos = [
    {
      field: <Trans>Organization</Trans>,
      value: item?.organization?.name,
    },
    {
      field: <Trans>Organizer</Trans>,
      value: item?.organizer,
      visible: isAdmin,
    },
    {
      field: <Trans>Contact</Trans>,
      value: item?.organizerContact?.email,
      visible: isAdmin,
    },
    {
      field: <Trans>Topic</Trans>,
      value: item?.topic?.name,
    },
    {
      field: <Trans>Event type</Trans>,
      value: item?.type && prepareEventTypeLabelsView(item),
      visible: item?.zone !== 'PODCAST',
    },
    {
      field:
        type === 'event' ? (
          <Trans>Participants</Trans>
        ) : (
          <Trans>Estimated number of participants</Trans>
        ),
      value:
        type === 'event' ? (
          <ParticipantsDetailCell participants={item.participantsCount} total={item.capacity} />
        ) : (
          <EstimatedCapacityDetailCell estimatedCapacity={item.estimatedCapacity} />
        ),
      visible: item?.zone !== 'PODCAST',
    },
    {
      field: <Trans>Actual participants</Trans>,
      value: item?.actualParticipantsCount,
      visible: item?.zone !== 'PODCAST',
    },
    {
      field: <Trans>Platform</Trans>,
      value: item.platform && prepareEventPlatformLabelsView(item.platform),
      visible: item?.zone !== 'PODCAST',
    },
    {
      field: <Trans>Hidden online registration</Trans>,
      value: item.hideRegistration ? <Trans>Yes</Trans> : <Trans>No</Trans>,
      visible: item?.zone !== 'PODCAST',
    },
  ]
  if (type === 'event') {
    genericInfos.push({
      field: <Trans>Zone</Trans>,
      value: item.zone && prepareEventZoneLabelsView(item.zone),
    })
  }

  return genericInfos
}

export const getRRuleFromConfig = (config) => {
  const frequencySpec = config[`freq${config.freq}`]
  delete config.help
  delete config[`freq${config.freq}`]

  const ruleObject = {
    ...config,
    ...frequencySpec,
  }
  const rule = new RRule(ruleObject)
  const rruleSplit = RRule.optionsToString(rule.options)?.split('RRULE:')?.[1]

  return rruleSplit?.split(';BYHOUR')?.[0]
}

export const parseRRuleForRender = (config) => {
  if (config.includes('undefined')) return [{field: <Trans>Mistake in data</Trans>, value: ''}]
  const parsedRRule = RRule.fromString(config)
  const RRuleObj = parsedRRule.origOptions
  const renderObj = [
    {
      field: <Trans>Recurrence</Trans>,
      value: getItemFromObjectList(RR_CONSTANTS.repeatOptions, RRuleObj.freq).label,
    },
  ]
  switch (RRuleObj.freq) {
    case RRule.MONTHLY:
      renderObj.push({
        field: <Trans>Every X month(s)</Trans>,
        value: RRuleObj.interval,
      })
      if (RRuleObj.bymonthday) {
        renderObj.push({
          field: <Trans>On the</Trans>,
          value: RRuleObj.bymonthday,
        })
      }
      if (RRuleObj.byweekday) {
        const monthlyDays = RRuleObj.byweekday.map((item) => {
          return RR_CONSTANTS.dayByKey[item.toString()]
        })
        renderObj.push(
          {
            field: <Trans>Which</Trans>,
            value: getItemFromObjectList(
              [...RR_CONSTANTS.dayInMonthPosition, ...RR_CONSTANTS.daysOptionsExt],
              RRuleObj.bysetpos
            ).label,
          },
          {
            field: <Trans>Days</Trans>,
            value: monthlyDays.join(', '),
          }
        )
      }
      break
    case RRule.WEEKLY:
      const weeklyDays = RRuleObj.byweekday.map((item) => {
        return RR_CONSTANTS.dayByKey[item.toString()]
      })

      renderObj.push(
        {
          field: <Trans>Every X week(s)</Trans>,
          value: RRuleObj.interval,
        },
        {
          field: <Trans>Days</Trans>,
          value: weeklyDays.join(', '),
        }
      )
      break
    case RRule.DAILY:
      renderObj.push({
        field: <Trans>Every X day(s)</Trans>,
        value: RRuleObj.interval,
      })
      break
    default:
      break
  }

  let endValue = <Trans>Never to end</Trans>

  if (RRuleObj.until) {
    endValue = <Trans>End on {moment(RRuleObj.until).format(DATE_FORMAT)}</Trans>
  } else if (RRuleObj.count) {
    endValue = <Trans>End after {RRuleObj.count} occurrences</Trans>
  }

  renderObj.push({
    field: <Trans>End of recurrence</Trans>,
    value: endValue,
  })

  return renderObj
}

const prepareEventZoneLabelsView = (zone) => {
  return getItemFromObjectList(EVENT_ZONES, zone).label
}

const prepareEventTypeLabelsView = ({type, customType}) => {
  if (type === 'CUSTOM') {
    return customType
  }
  return getItemFromObjectList(EVENT_TYPES, type).label
}

const prepareEventPlatformLabelsView = (platform) => {
  return getItemFromObjectList(EVENT_PLATFORMS, platform).label
}

export const getSpaceConfigurationImage = (spaceConfiguration) => {
  const currentSpaceConfiguration = EVENT_SPACE_CONFIGURATIONS.filter(
    (config) => config.id === spaceConfiguration
  )[0]
  return <img src={currentSpaceConfiguration.image} alt={currentSpaceConfiguration.id} />
}
