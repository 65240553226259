import React from 'react'
import {Trans} from '@lingui/macro'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'

const MediaServerUpload = (props) => {
  const {imageSrc, onImageChange, removeFile, classes, title, type} = props

  const verticalClass = cx({
    vertical: type === 'vertical',
    horizontal: type === 'horizontal',
    event: type === 'event',
  })

  return (
    <div className={classes.logoContainer + ' container'}>
      <div className="title">{title}</div>
      <div className={classes.logoResponsive}>
        {imageSrc ? (
          <img className={classes.logo} src={imageSrc} alt="logo" />
        ) : (
          <div className={verticalClass}>
            <div className="image-placeholder">
              {type === 'vertical' ? (
                <>
                  <div>2100</div>
                  <div>x</div>
                  <div>1300</div>
                </>
              ) : type === 'horizontal' ? (
                <>
                  <div>1500</div>
                  <div>x</div>
                  <div>1800</div>
                </>
              ) : (
                <div>
                  <div>220</div>
                  <div>x</div>
                  <div>220</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className={classes.uploadWrapper}>
        <label>
          <Trans>Upload</Trans>
          <input
            id={type}
            className={classes.fileInput}
            onChange={onImageChange}
            accept="image/png, image/jpeg, image/gif"
            type="file"
          />
        </label>
        <label onClick={removeFile}>
          <Trans>Remove</Trans>
        </label>
      </div>
    </div>
  )
}

export default withStyles(MediaServerUpload, globalFormFieldStyles)
