import React from 'react'
import {DesktopTimePicker} from '@mui/x-date-pickers/DesktopTimePicker'
import FormHelperText from '@mui/material/FormHelperText'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import {TIME_FORMAT} from 'helper/global/constants'
import {ensureMoment} from 'helper/projectSpecific/calendarFunctions'

const TimeInput = (props) => {
  const {helperText, id, input, meta, label, classes, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={classes.dateTimePicker}>
      <DesktopTimePicker
        {...input}
        format={TIME_FORMAT}
        label={label}
        value={input.value ? ensureMoment(input.value) : undefined}
        slotProps={{
          field: {fullWidth: true},
          textField: {
            fullWidth: true,
            variant: 'standard',
            error: !!errorMessage,
          },
        }}
        {...rest}
      />
      <FormHelperText>{errorMessage || helperText || ' '}</FormHelperText>
    </div>
  )
}

export default withStyles(TimeInput, globalFormFieldStyles)
