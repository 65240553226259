import React from 'react'
import PropTypes from 'prop-types'
import {Divider, Drawer, IconButton} from '@mui/material'
import {ChevronLeft, Menu} from '@mui/icons-material'
import Gradients from 'component/projectSpecific/common/Gradients'
import DrawerMenu from 'component/global/layoutElement/DrawerMenu'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'
import globalLayoutElementsStyle from 'asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import logo from 'asset/projectSpecific/img/MEC_static_logo_RGB.svg'
import logoGif from 'asset/projectSpecific/gif/logo_white.gif'

const AdminSidebar = (props) => {
  const {classes, disabled, openedDrawer, handleDrawerToggle} = props

  const drawerWrapperStyle = cx(classes.drawerWrapper, {
    [classes.drawerOpened]: openedDrawer,
    [classes.drawerClosed]: !openedDrawer,
  })

  return (
    <Drawer variant="permanent" className={drawerWrapperStyle}>
      <Gradients size={[200, 400]} position={'circle at bottom left'} />
      <div className={classes.drawerCollapse}>
        <IconButton onClick={handleDrawerToggle} size="large">
          {openedDrawer ? <ChevronLeft /> : <Menu />}
        </IconButton>
      </div>

      <div className={classes.drawerLogoContainer}>
        <object
          data={logoGif}
          type="image/gif"
          className={cx({[classes.hiddenElement]: !openedDrawer})}
        >
          <img src={logo} alt="logo" className={cx({[classes.hiddenElement]: !openedDrawer})} />
        </object>
      </div>
      <Divider variant="middle" />

      <DrawerMenu openedDrawer={openedDrawer} disabled={disabled} />
    </Drawer>
  )
}

AdminSidebar.propTypes = {
  openedDrawer: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
}

export default withStyles(AdminSidebar, globalLayoutElementsStyle)
