import React, {useCallback, useEffect, useState} from 'react'
import calendarStyle from 'asset/global/jss/component/calendar/calendarStyle'
import {withStyles} from 'tss-react/mui'
import {
  createCalendarGrid,
  fillCalendarWithDates,
  getFirstDayOfWeek,
} from 'helper/projectSpecific/calendarFunctions'
import PropTypes from 'prop-types'
import {DAYS} from 'helper/projectSpecific/constants'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {updateLocalLoader} from 'redux/action/global/loaderActions'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import {getProposalsCalendar} from 'redux/action/projectSpecific/proposalActions'
import ReservationCalendarCell from 'component/projectSpecific/event/eventList/ReservationCalendarCell'

const ReservationCalendar = (props) => {
  const {filter, selectedYear, selectedMonth, updateLocalLoader, classes, getProposalsCalendar} =
    props

  const [grid, setGrid] = useState(createCalendarGrid())
  const [showAllRow, setShownRows] = useState([])

  const showAllEvents = (rowIndex) => () => {
    const isAlreadyShown = showAllRow.includes(rowIndex)
    if (isAlreadyShown) {
      setShownRows((prevState) => [...prevState.filter((index) => index !== rowIndex)])
    } else {
      setShownRows((prevState) => [...prevState, rowIndex])
    }
  }

  const fetchProposalCalendar = useCallback(() => {
    const from = getFirstDayOfWeek(selectedYear, selectedMonth)
    const to = from.clone().add(6, 'weeks').subtract(1, 'day')
    updateLocalLoader(true)

    getProposalsCalendar(
      from.format(DATABASE_DATE_FORMAT),
      to.format(DATABASE_DATE_FORMAT),
      filter
    ).finally(() => updateLocalLoader(false))
  }, [selectedYear, selectedMonth, updateLocalLoader, filter])

  useEffect(() => {
    fillCalendarWithDates(selectedYear, selectedMonth, grid, setGrid)
    return () => {
      setShownRows([])
    }
  }, [selectedMonth, selectedYear])

  useEffect(() => {
    fetchProposalCalendar()
  }, [selectedMonth, selectedYear, filter])

  return (
    <div className={classes.tableWrapper}>
      <table>
        <thead>
          <tr>
            {DAYS.map((day, dayIndex) => (
              <th key={dayIndex}>{day}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {grid?.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row?.map((cell, colIndex) => (
                <ReservationCalendarCell
                  cell={cell}
                  key={`${rowIndex}-${colIndex}`}
                  renderAll={showAllRow.includes(rowIndex)}
                  showAllEvents={showAllEvents(rowIndex)}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

ReservationCalendar.propTypes = {
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
  classes: PropTypes.object,
  updateLocalLoader: PropTypes.func,
  getProposalsCalendar: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProposalsCalendar,
      updateLocalLoader,
    },
    dispatch
  )
}

export default compose(
  connect(() => {
    return {}
  }, mapDispatchToProps)
)(withStyles(ReservationCalendar, calendarStyle))
