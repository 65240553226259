import {
  FETCH_ORGANIZATION_CONTACT_REQUEST,
  FETCH_ORGANIZATION_CONTACT_SUCCESS,
  FETCH_ORGANIZATION_CONTACTS_REQUEST,
  FETCH_ORGANIZATION_CONTACTS_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initListOrganizationContactsState = {
  isLoading: true,
  data: [],
  meta: {},
}
const initOrganizationContactState = {
  isLoading: true,
  data: {},
}

export const ListOrganizationContactsReducer = (
  state = initListOrganizationContactsState,
  action = null
) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_CONTACTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORGANIZATION_CONTACTS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    default:
      return state
  }
}

export const OrganizationContactReducer = (state = initOrganizationContactState, action = null) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_CONTACT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORGANIZATION_CONTACT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
