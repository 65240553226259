import React from 'react'
import Pagination from 'component/global/common/table/Pagination'
import TableWrapper from 'component/global/common/wrapper/TableWrapper'
import {ORGANIZATION_ADDRESSES_LIST_COLUMNS} from 'component/projectSpecific/organization/organizationDetail/_organizationDetailAddressesTableColumns'
import OrganizationAddressRow from 'component/projectSpecific/organization/organizationList/OrganizationAddressRow'
import PropTypes from 'prop-types'

const OrganizationAddressDetailTab = (props) => {
  const {
    order,
    orderBy,
    addresses,
    requestSort,
    count,
    page,
    onChangePage,
    rowsPerOrganizationPage,
    onEditAddressClick,
    onDeleteAddressClick,
  } = props

  return (
    <TableWrapper
      onRequestSort={requestSort}
      columns={ORGANIZATION_ADDRESSES_LIST_COLUMNS}
      orderDirection={order}
      orderBy={orderBy}
      tablePagination={
        <Pagination
          count={count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerOrganizationPage}
        />
      }
    >
      {addresses?.map((row) => (
        <OrganizationAddressRow
          key={row.id}
          row={row}
          onEditClick={onEditAddressClick}
          onDeleteClick={onDeleteAddressClick}
        />
      ))}
    </TableWrapper>
  )
}

OrganizationAddressDetailTab.propTypes = {
  onEditAddressClick: PropTypes.func,
  onDeleteAddressClick: PropTypes.func,
}

export default OrganizationAddressDetailTab
