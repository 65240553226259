import React from 'react'
import {FormControl, FormControlLabel, FormHelperText} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import PropTypes from 'prop-types'
import Radio from '@mui/material/Radio'
import FormLabel from '@mui/material/FormLabel'
import RadioGroup from '@mui/material/RadioGroup'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'

const CustomRadioGroup = (props) => {
  const {input, meta, label, options, classes, direction, availableOptions, xs, disabled} = props
  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  const isDisabled = (value) => {
    if (availableOptions) {
      return !availableOptions.includes(value)
    }
    return false
  }

  const radioGroupClasses = cx(classes.customRadioGroupWrapper, {
    [classes.radioGroupFlexDirection]: direction === 'row',
  })

  return (
    <FormControl variant="standard" component="fieldset" className={radioGroupClasses}>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup {...input}>
        <GridContainer>
          {options?.map((item, index) => (
            <GridItem xs={xs} key={index}>
              <FormControlLabel
                value={item.value}
                control={<Radio />}
                label={item.label}
                disabled={disabled || isDisabled(item.value)}
              />
            </GridItem>
          ))}
        </GridContainer>
      </RadioGroup>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

CustomRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.node,
  options: PropTypes.array,
  direction: PropTypes.string,
}

export default withStyles(CustomRadioGroup, globalFormFieldStyles)
