import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import GlobalButton from 'component/global/common/button/GlobalButton'
import GridContainer from 'component/global/common/grid/GridContainer'

const EventQrCodeDialog = (props) => {
  const {openDialog, closeDialog, name, qrCode, classes} = props

  const downloadQrCode = () => {
    let a = document.createElement('a') //create a link
    a.href = `data:image/png;base64,${qrCode}` //provide data
    a.download = `${name}-QR.png` // set name
    a.click() //downloaded file
  }

  return (
    <DialogWrapper
      open={openDialog}
      maxWidth="sm"
      handleClose={closeDialog}
      title={<Trans>QR code of: {name}</Trans>}
    >
      <div className={classes.qrCode}>
        <img src={`data:image/png;base64,${qrCode}`} alt="QR code" />
      </div>
      <GridContainer justifyContent="center">
        <GlobalButton backgroundColor="primary" onClick={downloadQrCode}>
          <Trans>Save</Trans>
        </GlobalButton>
      </GridContainer>
    </DialogWrapper>
  )
}

EventQrCodeDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  qrCode: PropTypes.string,
  name: PropTypes.node,
  classes: PropTypes.object,
}

export default withStyles(EventQrCodeDialog, globalWrapperStyles)
