import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import OrganizationForm from 'component/projectSpecific/organization/organizationForm/OrganizationForm'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'

const OrganizationEditDialog = (props) => {
  const {openEdit, closeEdit, handleEditSubmit, id, ...rest} = props
  return (
    <DialogWrapper
      open={openEdit}
      handleClose={closeEdit}
      title={<Trans>Edit partner organization</Trans>}
      maxWidth="md"
    >
      <OrganizationForm onSubmit={handleEditSubmit} organizationId={id} form="edit" {...rest} />
    </DialogWrapper>
  )
}

OrganizationEditDialog.propTypes = {
  openEdit: PropTypes.bool,
  closeEdit: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default OrganizationEditDialog
