import React from 'react'
import PropTypes from 'prop-types'
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link'
import cx from 'classnames'
import {withStyles} from 'tss-react/mui'
import globalLinkStyle from 'asset/global/jss/component/common/button/globalLinkStyle'

const GlobalLink = (props) => {
  const {classes, children, globalLinkClassName, ...rest} = props

  return (
    <Link
      component={RouterLink}
      className={cx(classes.globalLink, globalLinkClassName)}
      {...rest}
      underline="hover"
    >
      {children}
    </Link>
  )
}

GlobalLink.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  rest: PropTypes.bool,
}

export default withStyles(GlobalLink, globalLinkStyle)
