import {adaptV4Theme, createTheme} from '@mui/material'

import {
  cardBackgroundColor,
  darkText,
  defaultBackgroundColor,
  grayColor,
  primaryColor,
  secondaryColor,
  successColor,
} from 'asset/global/jss/styleHelpers'

const defaultTheme = createTheme(
  adaptV4Theme({
    typography: {
      fontFamily: ['TeleNeo', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
    palette: {
      primary: {
        main: primaryColor[0],
      },
      secondary: {
        main: secondaryColor[0],
      },
      success: {
        main: successColor[0],
      },
      text: {
        primary: darkText,
        secondary: grayColor[0],
      },
      background: {
        paper: cardBackgroundColor,
        default: defaultBackgroundColor,
      },
    },
  })
)

export default defaultTheme
