import {
  FETCH_MY_ACCOUNT_REQUEST,
  FETCH_MY_ACCOUNT_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initMyOrganizationState = {
  isLoading: true,
  data: {},
}

export const MyAccountReducer = (state = initMyOrganizationState, action = null) => {
  switch (action.type) {
    case FETCH_MY_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_MY_ACCOUNT_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
