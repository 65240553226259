import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {Box} from '@mui/material'
import GlobalButton from 'component/global/common/button/GlobalButton'
import CounterProposalActionsWrapper from 'component/projectSpecific/proposal/proposalDetail/CounterProposalActionsWrapper'
import TextInput from 'component/global/common/formField/TextInput'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import {required} from 'helper/projectSpecific/validations'

const CounterProposalForm = (props) => {
  const {topic, availableDates, timeFreeCounterProposals, onSubmitCounterProposal} = props

  const [fields, setFields] = useState([1])

  const availableDatesList = Object.keys(availableDates)
  const handleDisableDates = (day) => {
    return !availableDatesList.includes(day.format(DATABASE_DATE_FORMAT))
  }

  const addCounterProposalSet = () => {
    setFields([...fields, 1])
  }

  const removeCounterProposalSet = (index, formProps) => () => {
    if (fields.length > 0) {
      const newFields = [...fields]
      formProps.values.proposalActions && formProps.values.proposalActions.splice(index, 1)
      newFields.splice(index, 1)
      setFields(newFields)
    }
  }

  return (
    <Form onSubmit={onSubmitCounterProposal}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <GridContainer spacing={4}>
            <GridItem xs={12}>
              {fields.map((field, index) => (
                <CounterProposalActionsWrapper
                  timeFreeCounterProposals={
                    timeFreeCounterProposals && timeFreeCounterProposals[index]
                  }
                  key={index}
                  fieldsCount={fields.length}
                  index={index}
                  topic={topic}
                  handleDisableDates={handleDisableDates}
                  formProps={formProps}
                  availableDates={availableDates}
                  removeCounterProposalSet={removeCounterProposalSet(index, formProps)}
                />
              ))}
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="reason"
                label={<Trans>Counter proposal note</Trans>}
                component={TextInput}
                multiline={true}
                variant="outlined"
                validate={required}
              />
            </GridItem>
            <GridItem xs={12}>
              <Box display="flex" justifyContent={'space-between'} flexGrow={1}>
                <GlobalButton onClick={addCounterProposalSet} backgroundColor="primary" inverted>
                  <Trans>Add choice</Trans>
                </GlobalButton>
                <GlobalButton type="submit" backgroundColor="primary">
                  <Trans>Send counter proposal</Trans>
                </GlobalButton>
              </Box>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Form>
  )
}

CounterProposalForm.propTypes = {
  onSubmitCounterProposal: PropTypes.func,
}

export default CounterProposalForm
