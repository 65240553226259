import {BORDER_RADIUS, warningColor} from 'asset/global/jss/styleHelpers'

const warningMessageStyle = () => ({
  warningMessageWrapper: {
    marginBottom: '20px',
  },
  warningMessage: {
    border: `1px solid ${warningColor[0]}`,
    color: warningColor[0],
    borderRadius: BORDER_RADIUS,
    padding: '5px 10px',
    '& svg': {
      fill: warningColor[0],
    },
  },
  toast: {
    display: 'flex',
    alignItems: 'center',
  },
  toastIcon: {
    marginRight: '10px',
  },
})
export default warningMessageStyle
