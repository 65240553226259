import React, {useState} from 'react'
import {Trans} from '@lingui/macro'
import {Field} from 'react-final-form'
import {required} from 'helper/projectSpecific/validations'
import GridContainer from 'component/global/common/grid/GridContainer'
import {EVENT_SPACE_CONFIGURATIONS} from 'helper/projectSpecific/constants'
import GridItem from 'component/global/common/grid/GridItem'
import {stepperFormStyle} from 'asset/projectSpecific/jss/common/stepperFormStyle'
import {withStyles} from 'tss-react/mui'
import ErrorField from 'component/global/common/formField/ErrorField'
import PropTypes from 'prop-types'
import SpaceConfigurationItem from 'component/projectSpecific/proposal/proposalForm/SpaceConfigurationItem'
import {Info} from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import ConfigurationDialog from 'component/projectSpecific/proposal/proposalForm/ConfigurationDialog'

const SpaceConfiguration = (props) => {
  const {selectConfigurations, selected, classes, requiredConfiguration = true} = props

  const [configurationDialog, setConfigurationDialog] = useState(false)

  const openConfigurationDialog = () => {
    setConfigurationDialog(true)
  }

  return (
    <>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      <div className={classes.spaceConfigurationLabel}>
        <Trans>Choose space configuration</Trans>
        <Tooltip title={<Trans>Configuration map</Trans>} placement="top" enterDelay={300}>
          <Info onClick={openConfigurationDialog} />
        </Tooltip>
      </div>
      <Field
        name="spaceConfiguration"
        component="input"
        type="hidden"
        validate={requiredConfiguration && required}
      />
      <GridContainer className={classes.spaceConfigurationContainer}>
        {EVENT_SPACE_CONFIGURATIONS.map((config) => (
          <GridItem xs={6} md={4} key={config.id} className={classes.spaceConfigurationItem}>
            <SpaceConfigurationItem
              selectConfigurations={selectConfigurations}
              selected={selected}
              itemId={config.id}
            >
              <img src={config.image} alt={config.configuration} />
            </SpaceConfigurationItem>
          </GridItem>
        ))}
      </GridContainer>
      {requiredConfiguration && <ErrorField name="spaceConfiguration" />}
    </>
  )
}

SpaceConfiguration.propTypes = {
  classes: PropTypes.object.isRequired,
  selectConfigurations: PropTypes.func,
  selected: PropTypes.string,
  requiredConfiguration: PropTypes.bool,
}

export default withStyles(SpaceConfiguration, stepperFormStyle)
