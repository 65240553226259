import React, {useContext, useEffect, useState} from 'react'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import {t, Trans} from '@lingui/macro'
import {Assignment, Dashboard, Equalizer, EventNote, SettingsInputSvideo} from '@mui/icons-material'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import {AbilityContext} from 'App/provider/authorizedAbility'
import {redirectWithMessage} from 'helper/global/functions'
import {bindActionCreators, compose} from 'redux'
import {
  getPricingStatistics,
  getSatisfactionStatistics,
  getStatistics,
} from 'redux/action/projectSpecific/eventStatisticsActions'
import {connect} from 'react-redux'
import GridContainer from 'component/global/common/grid/GridContainer'
import PropTypes from 'prop-types'
import moment from 'moment'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import DetailGridItem from 'component/global/common/grid/DetailGridItem'
import DetailTableWrapper from 'component/global/common/wrapper/DetailTableWrapper'
import {EVENT_SPACE_CONFIGURATIONS, EVENT_ZONES} from 'helper/projectSpecific/constants'
import Loader from 'component/global/common/table/Loader'
import TabPanel from 'component/global/common/tab/TabPanel'
import ListIcon from '@mui/icons-material/List'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import i18nProvider from 'App/provider/i18n'
import StarsIcon from '@mui/icons-material/Stars'
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown'
import TheatersIcon from '@mui/icons-material/Theaters'
import PaymentDataTable from 'App/page/statistic/PaymentDataTable'
import StatisticsFilters from 'App/page/statistic/StatisticsFilters'

const StatisticsPage = (props) => {
  const ability = useContext(AbilityContext)
  if (ability.cannot('read', 'Statistic')) {
    return redirectWithMessage(<Trans>You are not authorized to list statistics.</Trans>)
  }

  const {
    classes,
    getStatistics,
    statistics,
    statisticsFilter,
    statisticsLoading,
    getPricingStatistics,
    pricingData,
    pricingDataLoading,
    satisfactionLoading,
    satisfactionData,
    getSatisfactionStatistics,
  } = props

  const [activeTab, setActiveTab] = useState('events')

  const [filters, setFilters] = useState({
    period: 'thisMonth',
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    organizationId: null,
  })

  const handleChangeTab = (e, tabValue) => {
    setActiveTab(tabValue)
  }

  const handleChangeFilter = (value, name) => {
    setFilters((prevState) => ({
      ...prevState,
      period: 'own',
      [name]: value,
    }))
  }

  const getTabList = () => {
    const tabList = []
    if (ability.can('read', 'Statistic')) {
      tabList.push({label: <Trans>Events</Trans>, value: 'events'})
      tabList.push({label: <Trans>Prices</Trans>, value: 'price'})
      tabList.push({label: <Trans>Satisfaction</Trans>, value: 'satisfaction'})
    }
    return tabList
  }

  useEffect(() => {
    if (activeTab === 'price') {
      getPricingStatistics(filters)
    } else if (activeTab === 'satisfaction') {
      getSatisfactionStatistics(filters)
    } else {
      getStatistics(filters)
    }
  }, [filters, activeTab])

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        tabsValue={activeTab}
        tabList={getTabList()}
        handleChangeTab={handleChangeTab}
        pageTitle={<Trans>Statistics</Trans>}
        iconPageHeader={<Equalizer />}
        toolbar={false}
        actionsHeader={
          <StatisticsFilters
            setFilters={setFilters}
            filters={filters}
            activeTab={activeTab}
            handleChangeFilter={handleChangeFilter}
          />
        }
      >
        <>
          <TabPanel value={activeTab} index={'events'}>
            <GridContainer spacing={4}>
              {statisticsLoading ? (
                <Loader />
              ) : (
                <>
                  <DetailGridItem xs={12} sm={6} icon={<EventNote />} title={<Trans>Events</Trans>}>
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={[
                        {
                          field: <Trans>Number of events</Trans>,
                          value: statistics.totalEvents ? statistics.totalEvents : 0,
                        },
                        {
                          field: <Trans>Number of online events</Trans>,
                          value: statistics.onlineEventsCount ? statistics.onlineEventsCount : 0,
                        },
                        {
                          field: <Trans>Number of internal events</Trans>,
                          value: statistics.internalEventsCount
                            ? statistics.internalEventsCount
                            : 0,
                        },
                        {
                          field: <Trans>Event capacity summary</Trans>,
                          value: statistics.eventsCapacity ? statistics.eventsCapacity : 0,
                        },
                        {
                          field: <Trans>Event participants summary</Trans>,
                          value: statistics.eventsParticipants ? statistics.eventsParticipants : 0,
                        },
                        {
                          field: <Trans>Online event viewers summary</Trans>,
                          value: statistics.eventsOnlineViewers
                            ? statistics.eventsOnlineViewers
                            : 0,
                        },
                        {
                          field: <Trans>Actual participants summary</Trans>,
                          value: statistics.actualParticipantsCount
                            ? statistics.actualParticipantsCount
                            : 0,
                        },
                      ]}
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<Assignment />}
                    title={<Trans>Proposals</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={[
                        {
                          field: <Trans>Number of confirmed proposals</Trans>,
                          value: statistics.confirmedProposals ? statistics.confirmedProposals : 0,
                        },
                        {
                          field: <Trans>Number of denied proposals</Trans>,
                          value: statistics.deniedProposals ? statistics.deniedProposals : 0,
                        },
                      ]}
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<SettingsInputSvideo />}
                    title={<Trans>Events per space configuration</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={
                        statistics?.eventsPerSpaceConfiguration &&
                        Object.keys(statistics.eventsPerSpaceConfiguration).map((conf) => ({
                          field: EVENT_SPACE_CONFIGURATIONS.find((c) => c.id === conf)
                            ?.configuration
                            ? EVENT_SPACE_CONFIGURATIONS.find((c) => c.id === conf)?.configuration
                            : conf,
                          value: statistics.eventsPerSpaceConfiguration[conf],
                        }))
                      }
                    />
                  </DetailGridItem>

                  <DetailGridItem
                    xs={12}
                    sm={6}
                    icon={<Dashboard />}
                    title={<Trans>Events per zone</Trans>}
                  >
                    <DetailTableWrapper
                      classNames={classes.statisticsTable}
                      data={
                        statistics?.eventsPerZone &&
                        Object.keys(statistics.eventsPerZone).map((zone) => ({
                          field: EVENT_ZONES.find((c) => c.value === zone)?.label
                            ? EVENT_ZONES.find((c) => c.value === zone)?.label
                            : zone,
                          value: statistics.eventsPerZone[zone],
                        }))
                      }
                    />
                  </DetailGridItem>
                </>
              )}
            </GridContainer>
          </TabPanel>

          <TabPanel value={activeTab} index={'price'}>
            {pricingDataLoading ? (
              <Loader />
            ) : (
              <GridContainer spacing={4}>
                <PaymentDataTable />

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<ListIcon />}
                  title={<Trans>Marketing counter</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={pricingData?.marketingCounters?.map((counter) => ({
                      field: (
                        <span
                          style={{whiteSpace: 'normal'}}
                          dangerouslySetInnerHTML={{
                            __html:
                              counter.marketingCounterDescription &&
                              counter.marketingCounterDescription,
                          }}
                        />
                      ),
                      value: counter.marketingCounter
                        ? `${Math.round(counter.marketingCounter / 100)} Kč`
                        : '0 Kč',
                    }))}
                  />
                </DetailGridItem>
              </GridContainer>
            )}
          </TabPanel>

          <TabPanel value={activeTab} index={'satisfaction'}>
            {satisfactionLoading ? (
              <Loader />
            ) : (
              <GridContainer spacing={4}>
                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<QuestionAnswerIcon />}
                  title={<Trans>Satisfaction survey</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(t`Celkem odpovědí`),
                        value: satisfactionData.totalResponses || 0,
                      },
                    ]}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<StarsIcon />}
                  title={
                    <Trans>
                      Zajišťovali jsme vám služby, které nejsou běžně součástí pronájmů prostor a
                      vnímáte je jako benefit Magenta Experience Centra?
                    </Trans>
                  }
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(t`Yes`),
                        value: satisfactionData.nonStandardServicesConfirmed || 0,
                      },
                      {
                        field: i18nProvider._(t`No`),
                        value:
                          satisfactionData.totalResponses -
                            satisfactionData.nonStandardServicesConfirmed || 0,
                      },
                    ]}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<ThumbsUpDownIcon />}
                  title={<Trans>Co Vám nevyhovovalo, nebo máme zlepšit?</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={satisfactionData?.improvementSuggestions
                      ?.filter((row) => !!row)
                      ?.map((row) => ({
                        field: row,
                        value: null,
                      }))}
                  />
                </DetailGridItem>

                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<SentimentVerySatisfiedIcon />}
                  title={<Trans>Satisfaction</Trans>}
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={[
                      {
                        field: i18nProvider._(
                          t`Jak jste byli celkově spokojeni s Magenta Experience Centrem?`
                        ),
                        value: satisfactionData.overAllSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak byli s prostorem spokojeni účastníci vašeho eventu?`
                        ),
                        value: satisfactionData.participantSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak jste byli spokojeni s organizačním zajištěním?`
                        ),
                        value: satisfactionData.organizationalSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(
                          t`Jak jste byli spokojeni s technickým zajištěním akce?`
                        ),
                        value:
                          satisfactionData.technicalSupportSatisfactionAverage?.toFixed(2) || 0,
                      },
                      {
                        field: i18nProvider._(t`Doporučili by jste MEC kolegům pro konání eventů?`),
                        value: satisfactionData.likelihoodOfRecommendationAverage?.toFixed(2) || 0,
                      },
                    ]}
                  />
                </DetailGridItem>
                <DetailGridItem
                  xs={12}
                  sm={6}
                  icon={<TheatersIcon />}
                  title={
                    <Trans>
                      Zajišťovali jsme vám live stream, záznam z akce či dokonce postprodukční práce
                      a grafický servis? Jak jste byli spokojeni?
                    </Trans>
                  }
                >
                  <DetailTableWrapper
                    classNames={classes.statisticsTable}
                    data={satisfactionData?.audioVideoServicesCommentaries
                      ?.filter((row) => !!row)
                      ?.map((row) => ({
                        field: row,
                        value: null,
                      }))}
                  />
                </DetailGridItem>
              </GridContainer>
            )}
          </TabPanel>
        </>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

StatisticsPage.propTypes = {
  getStatistics: PropTypes.func,
  getPricingStatistics: PropTypes.func,
  statistics: PropTypes.object,
  statisticsLoading: PropTypes.bool,
  getSatisfactionStatistics: PropTypes.func,
  pricingData: PropTypes.object,
  pricingDataLoading: PropTypes.bool,
  satisfactionData: PropTypes.object,
  satisfactionLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStatistics,
      getPricingStatistics,
      getSatisfactionStatistics,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      statistics: store.Statistics.data,
      statisticsLoading: store.Statistics.isLoading,
      statisticsFilter: store.AppFilterStates.statisticsFilters,
      pricingData: store.Statistics.pricingData,
      pricingDataLoading: store.Statistics.pricingDataLoading,
      satisfactionData: store.Statistics.satisfactionData,
      satisfactionLoading: store.Statistics.satisfactionLoading,
    }
  }, mapDispatchToProps)
)(withStyles(StatisticsPage, globalWrapperStyles))
