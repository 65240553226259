import React from 'react'
import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import cx from 'classnames'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'

const CustomCheckbox = (props) => {
  const {classes, noMargin, input, meta, justifyStart, justifyEnd, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  const checkboxClasses = cx(
    classes.root,
    classes.checkbox,
    justifyStart && classes.checkboxJustifyStart,
    justifyEnd && classes.checkboxJustifyEnd,
    noMargin && classes.noCheckboxMargin
  )

  return (
    <FormControl variant="standard" className={checkboxClasses} error={!!errorMessage}>
      <FormControlLabel
        {...rest}
        control={<Checkbox {...input} className={errorMessage ? 'error' : ''} />}
      />
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

CustomCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  noMargin: PropTypes.bool,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  justifyStart: PropTypes.bool,
  justifyEnd: PropTypes.bool,
}

export default withStyles(CustomCheckbox, globalFormFieldStyles)
