import React from 'react'
import {Field, Form} from 'react-final-form'
import {withStyles} from 'tss-react/mui'
import calendarStyle from 'asset/global/jss/component/calendar/calendarStyle'
import {Trans} from '@lingui/macro'
import SelectField from 'component/global/common/formField/SelectField'
import {getSelectOptions} from 'helper/global/functions'
import {EVENT_SPACE_CONFIGURATIONS} from 'helper/projectSpecific/constants'

const ReservationCalendarFilters = (props) => {
  const {classes, eventsProposalsFilter, setEventsProposalsFilter} = props

  // const ability = useContext(AbilityContext)

  const setFilters = (name, value) => {
    setEventsProposalsFilter((prevState) => ({...prevState, [name]: value}))
  }

  // const getEventZonesOptions = () => {
  //   if (ability.can('manage', 'AllEvents')) {
  //     return EVENT_ZONES
  //   } else if (ability.can('manage', 'Podcast')) {
  //     return [{value: 'PODCAST', label: <Trans>Podcast</Trans>}]
  //   }
  // }

  return (
    <Form onSubmit={setEventsProposalsFilter} initialValues={eventsProposalsFilter}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.onSubmit} className={classes.eventFilters}>
            <Field
              name="spaceConfiguration"
              label={<Trans>Space</Trans>}
              component={SelectField}
              options={getSelectOptions(EVENT_SPACE_CONFIGURATIONS, 'id', 'configuration')}
              emptyOption={true}
              emptyOptionLabel={<Trans>reset</Trans>}
              customOnChange={setFilters}
            />
            {/*<Field*/}
            {/*  name="eventZone"*/}
            {/*  label={<Trans>Zone</Trans>}*/}
            {/*  component={SelectField}*/}
            {/*  options={getEventZonesOptions()}*/}
            {/*  emptyOption={true}*/}
            {/*  emptyOptionLabel={<Trans>reset</Trans>}*/}
            {/*  customOnChange={setFilters}*/}
            {/*/>*/}
          </form>
        )
      }}
    </Form>
  )
}

export default withStyles(ReservationCalendarFilters, calendarStyle)
