import httpClient from 'helper/global/httpClient'
import {
  FETCH_PRICING_STATISTICS_REQUEST,
  FETCH_PRICING_STATISTICS_SUCCESS,
  FETCH_SATISFACTION_STATISTICS_REQUEST,
  FETCH_SATISFACTION_STATISTICS_SUCCESS,
  FETCH_STATISTICS_REQUEST,
  FETCH_STATISTICS_SUCCESS,
} from 'redux/action/projectSpecific/types'
import endpoints from 'config/endpoints'
import {globalApiErrorHandler} from 'helper/global/functions'
import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'
import moment from 'moment'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'

export function getStatistics(filters) {
  return function (dispatch) {
    const statisticsFilters = {
      from: filters.from ? moment(filters.from).format(DATABASE_DATE_FORMAT) : null,
      to: filters.to ? moment(filters.to).format(DATABASE_DATE_FORMAT) : null,
    }
    if (statisticsFilters.from && statisticsFilters.to) {
      dispatch({type: UPDATE_FILTERS_STATE, data: filters})
      dispatch({type: FETCH_STATISTICS_REQUEST})
      return httpClient
        .get(endpoints.statistics, statisticsFilters)
        .then((response) => {
          dispatch({type: FETCH_STATISTICS_SUCCESS, data: response.data})
        })
        .catch(globalApiErrorHandler)
    }
  }
}

export function getPricingStatistics(filters) {
  return function (dispatch) {
    const statisticsFilters = {
      from: filters?.from ? moment(filters.from).format(DATABASE_DATE_FORMAT) : null,
      to: filters?.to ? moment(filters.to).format(DATABASE_DATE_FORMAT) : null,
      organizationId: filters?.organizationId,
    }
    if (statisticsFilters.from && statisticsFilters.to && statisticsFilters.organizationId) {
      dispatch({type: UPDATE_FILTERS_STATE, data: filters})
      dispatch({type: FETCH_PRICING_STATISTICS_REQUEST})
      return httpClient
        .get(endpoints.statistics + '/pricing', statisticsFilters)
        .then((response) => {
          dispatch({type: FETCH_PRICING_STATISTICS_SUCCESS, data: response.data})
        })
        .catch(globalApiErrorHandler)
    }
  }
}

export function getSatisfactionStatistics(filters) {
  return function (dispatch) {
    const statisticsFilters = {
      from: filters?.from ? moment(filters.from).format(DATABASE_DATE_FORMAT) : null,
      to: filters?.to ? moment(filters.to).format(DATABASE_DATE_FORMAT) : null,
      organizationId: filters?.organizationId,
    }
    if (statisticsFilters.from && statisticsFilters.to && statisticsFilters.organizationId) {
      dispatch({type: UPDATE_FILTERS_STATE, data: filters})
      dispatch({type: FETCH_SATISFACTION_STATISTICS_REQUEST})
      return httpClient
        .get(endpoints.statistics + '/satisfaction', statisticsFilters)
        .then((response) => {
          dispatch({type: FETCH_SATISFACTION_STATISTICS_SUCCESS, data: response.data})
        })
        .catch(globalApiErrorHandler)
    }
  }
}
