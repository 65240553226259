import {
  BORDER_RADIUS,
  dangerColor,
  infoColor,
  primaryColor,
  secondaryColor,
  successColor,
  warningColor,
  whiteColor,
} from 'asset/global/jss/styleHelpers'

const globalTableStyle = (theme) => ({
  cell: {
    padding: '0px 5px',
    '&:first-of-type': {
      padding: '0px 5px 0px 10px',
    },
    '&:last-of-type': {
      padding: '0px 10px 0px 5px',
    },
    whiteSpace: 'nowrap',
  },
  cellWrap: {
    whiteSpace: 'normal',
  },
  clickableSpan: {
    color: primaryColor[0],
    cursor: 'pointer',
    '&:hover': {
      color: primaryColor[1],
    },
  },
  cellActions: {
    textAlign: 'end',
    '& button': {
      color: primaryColor[0],

      '&:hover': {
        color: primaryColor[1],
      },
    },
  },
  detailCell: {
    padding: '10px 0 !important',
    '&:first-of-type': {
      padding: '10px 5px 10px 0 !important',
    },
  },
  cellCheckBox: {
    padding: '0px',
  },
  detailButton: {
    color: 'grey',
  },
  tableHeadActions: {
    textAlign: 'right',
  },
  chip: {
    color: whiteColor,
    fontWeight: 500,
    padding: '6px 8px',
    marginRight: '2px',
    '&:last-of-type': {
      marginRight: '0px',
    },
    borderRadius: BORDER_RADIUS,
  },
  primary: {
    backgroundColor: primaryColor[0],
  },
  secondary: {
    backgroundColor: secondaryColor,
  },
  info: {
    backgroundColor: infoColor[0],
  },
  success: {
    backgroundColor: successColor[0],
  },
  warning: {
    backgroundColor: warningColor[0],
  },
  danger: {
    backgroundColor: dangerColor[0],
  },
  cellAlignTop: {
    verticalAlign: 'top',
  },
  welcome: {
    border: 0,
    width: 'auto',
    cursor: 'pointer',
    padding: '8px 12px',
    display: 'inline-flex',
    boxSizing: 'border-box',
    '& svg': {
      paddingLeft: '5px',
      width: '18px',
      height: '18px',
    },
  },
  welcomeText: {
    display: 'flex',
    textTransform: 'capitalize',
  },
  smallChipPadding: {
    padding: '2px !important',
  },
  contactText: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  addContactText: {
    color: primaryColor[0],
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  yesText: {
    color: successColor[0],
    fontWeight: 500,
  },
  noText: {
    color: warningColor[0],
    fontWeight: 500,
  },
  loader: {
    border: '5px solid transparent',
    borderRadius: '50%',
    borderTop: '5px solid ' + primaryColor[0] + '90',
    width: 40,
    height: 40,
    animation: '$spin 1.2s linear infinite',
    margin: '0 auto',
    padding: 20,
  },

  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },

  loaderWrapper: {
    minHeight: '25vh',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },
})

export default globalTableStyle
