import React, {useRef, useState} from 'react'
import {
  Breadcrumbs,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
} from '@mui/material'
import {ChevronRight, ExitToApp, Lock, Person, SupervisorAccount} from '@mui/icons-material'
import {withStyles} from 'tss-react/mui'
import globalLayoutElementsStyle from 'asset/global/jss/component/layoutElement/globalLayoutElementsStyle'
import PropTypes from 'prop-types'
import {
  clearLocalStorage,
  fireSuccessToast,
  getActualUser,
  prepareBreadcrumbs,
} from 'helper/global/functions'
import {Trans} from '@lingui/macro'
import {
  changeMyAccount,
  changePassword,
  getMyAccount,
  logout,
} from 'redux/action/global/authActions'
import {connect} from 'react-redux'
import OrganizationEditDialog from 'component/projectSpecific/organization/organizationDetail/OrganizationEditDialog'
import {changeMyOrganization} from 'redux/action/projectSpecific/organizationActions'
import OrganizationUserEditDialog from 'component/projectSpecific/organization/organizationDetail/OrganizationUserEditDialog'
import UserChangePasswordDialog from 'component/projectSpecific/auth/UserChangePasswordDialog'
import {useLocation, useNavigate} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {routesMap} from 'config/routesMap'

const AdminNavbar = ({
  classes,
  logout,
  changeMyOrganization,
  changePassword,
  changeMyAccount,
  getMyAccount,
  disabled = false,
}) => {
  let location = useLocation()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [openOrganization, setOpenOrganization] = useState(false)
  const [logoFile, setLogoFile] = useState(null)
  const [logoChanged, setLogoChanged] = useState(false)
  const [openUser, setOpenUser] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [userName, setUserName] = useState(getActualUser()?.user?.name)
  const [errorMessage, setErrorMessage] = useState(null)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpenOrganization = () => {
    setOpenOrganization(true)
    handleClose()
  }

  const handleCloseOrganization = () => {
    setOpenOrganization(false)
  }

  const handleOpenUser = () => {
    setOpenUser(true)
    handleClose()
  }

  const handleCloseUser = () => {
    setOpenUser(false)
  }

  const handleOpenChangePassword = () => {
    setOpenChangePassword(true)
    handleClose()
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false)
  }

  const handleOrganizationEdit = (values) => {
    if (logoChanged) {
      if (!logoFile) {
        values.logoUrl = null
      } else {
        values.logoData = logoFile
      }
    }

    changeMyOrganization(values).then(() => {
      handleCloseOrganization()
      fireSuccessToast(<Trans>Organization updated.</Trans>)
    })
  }

  const handleEditMyAccount = (values) => {
    const data = {
      name: values.name,
      email: values.email,
    }
    changeMyAccount(data).then(() => {
      handleCloseUser()
      getMyAccount().then(() => {
        setUserName(getActualUser()?.user?.name)
      })
    })
  }

  const handleSetLogoFile = (file) => {
    setLogoFile(file)
  }

  const handleChangePassword = (values) => {
    changePassword(values)
      .then(() => {
        handleCloseChangePassword()
      })
      .catch((err) => setErrorMessage(err?.response?.data?.message))
  }

  const handleLogout = () => {
    logout().then((res) => {
      if (res.status === 204) {
        navigate(routesMap.auth.login)
        clearLocalStorage()
      }
    })
  }

  return (
    <div className={classes.adminNavbarWrapper}>
      <OrganizationEditDialog
        openEdit={openOrganization}
        closeEdit={handleCloseOrganization}
        handleEditSubmit={handleOrganizationEdit}
        id={null}
        logoChanged={logoChanged}
        logoFile={logoFile}
        handleLogoChanged={setLogoChanged}
        handleLogoFile={handleSetLogoFile}
        deleteItem={null}
      />
      <OrganizationUserEditDialog
        openUserEdit={openUser}
        closeUserEdit={handleCloseUser}
        handleUserEditSubmit={handleEditMyAccount}
        organizationId={null}
        userId={null}
        deleteUser={null}
        disableRolesEdit
      />
      <UserChangePasswordDialog
        errorMessage={errorMessage}
        openDialog={openChangePassword}
        closeDialog={handleCloseChangePassword}
        handleSubmit={handleChangePassword}
      />

      <Toolbar>
        <Breadcrumbs separator={<ChevronRight />} aria-label="breadcrumb">
          {prepareBreadcrumbs(classes, location)}
        </Breadcrumbs>

        <div
          className={classes.adminNavbarSettings}
          ref={anchorRef}
          onClick={() => !disabled && handleToggle()}
        >
          <span>{userName}</span>
          <IconButton
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
            size="large"
          >
            <Person />
          </IconButton>
        </div>

        <Popper
          className={classes.dropDownPopper}
          open={open}
          anchorEl={anchorRef.current}
          placement="bottom-end"
          role={'menu'}
          transition
          disablePortal
        >
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} id="menu-list-grow" variant="selectedMenu">
                    <MenuItem onClick={handleOpenOrganization}>
                      <SupervisorAccount />
                      <Trans>Edit my organization</Trans>
                    </MenuItem>
                    <MenuItem onClick={handleOpenUser}>
                      <Person />
                      <Trans>Edit my account</Trans>
                    </MenuItem>
                    <MenuItem onClick={handleOpenChangePassword}>
                      <Lock />
                      <Trans>Change my password</Trans>
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      <ExitToApp />
                      <Trans>Logout</Trans>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
    </div>
  )
}

AdminNavbar.propTypes = {
  classes: PropTypes.object,
  logout: PropTypes.func,
  changeMyOrganization: PropTypes.func,
  changePassword: PropTypes.func,
  changeMyAccount: PropTypes.func,
  getMyAccount: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
      changeMyOrganization,
      changePassword,
      changeMyAccount,
      getMyAccount,
    },
    dispatch
  )
}

export default compose(connect(null, mapDispatchToProps))(
  withStyles(AdminNavbar, globalLayoutElementsStyle)
)
