const mapPermissionsToAbilities = (permissions) => {
  const rules = []
  permissions.forEach((permission) => {
    switch (permission) {
      case 'can_list_events':
        rules.push({subject: 'Events', action: 'read'})
        break
      case 'can_create_events':
        rules.push({subject: 'Events', action: 'create'})
        break
      case 'can_get_events':
        rules.push({subject: 'Event', action: 'read'})
        break
      case 'can_share_events':
        rules.push({subject: 'Events', action: 'share'})
        break
      case 'can_update_events':
        rules.push({subject: 'Events', action: 'update'})
        break
      case 'can_update_events_for_partner':
        rules.push({subject: 'Events', action: 'partnerUpdate'})
        break
      case 'can_delete_events':
        rules.push({subject: 'Events', action: 'delete'})
        break
      case 'can_list_participants':
        rules.push({subject: 'Participants', action: 'read'})
        break
      case 'can_export_participants':
        rules.push({subject: 'Participants', action: 'export'})
        break
      case 'can_delete_participants':
        rules.push({subject: 'Participants', action: 'delete'})
        break
      case 'can_list_organizations':
        rules.push({subject: 'Organizations', action: 'read'})
        break
      case 'can_create_organizations':
        rules.push({subject: 'Organizations', action: 'create'})
        break
      case 'can_get_organizations':
        rules.push({subject: 'Organization', action: 'read'})
        break
      case 'can_update_organizations':
        rules.push({subject: 'Organizations', action: 'update'})
        break
      case 'can_delete_organizations':
        rules.push({subject: 'Organizations', action: 'delete'})
        break
      case 'can_list_topics':
        rules.push({subject: 'Topics', action: 'read'})
        break
      case 'can_create_topics':
        rules.push({subject: 'Topics', action: 'create'})
        break
      case 'can_get_topics':
        rules.push({subject: 'Topic', action: 'read'})
        break
      case 'can_update_topics':
        rules.push({subject: 'Topics', action: 'update'})
        break
      case 'can_delete_topics':
        rules.push({subject: 'Topics', action: 'delete'})
        break
      case 'can_list_users':
        rules.push({subject: 'Users', action: 'read'})
        break
      case 'can_create_users':
        rules.push({subject: 'Users', action: 'create'})
        break
      case 'can_get_users':
        rules.push({subject: 'User', action: 'read'})
        break
      case 'can_update_users':
        rules.push({subject: 'Users', action: 'update'})
        break
      case 'can_delete_users':
        rules.push({subject: 'Users', action: 'delete'})
        break
      case 'can_reset_password_users':
        rules.push({subject: 'Users', action: 'resetPassword'})
        break
      case 'can_list_proposals':
        rules.push({subject: 'Proposals', action: 'read'})
        break
      case 'can_create_proposals':
        rules.push({subject: 'Proposals', action: 'create'})
        break
      case 'can_get_proposals':
        rules.push({subject: 'Proposal', action: 'read'})
        break
      case 'can_get_proposals_for_partner':
        rules.push({subject: 'Proposals', action: 'partnerOpen'})
        break
      case 'can_counter_proposals':
        rules.push({subject: 'Proposals', action: 'counter'})
        break
      case 'can_confirm_proposals':
        rules.push({subject: 'Proposals', action: 'confirm'})
        break
      case 'can_deny_proposals':
        rules.push({subject: 'Proposals', action: 'deny'})
        break
      case 'can_manage_events_podcast':
        rules.push({subject: 'Podcast', action: 'manage'})
        break
      case 'can_manage_events_all':
        rules.push({subject: 'AllEvents', action: 'manage'})
        break
      case 'can_manage_event_internal_information':
        rules.push({subject: 'Internal', action: 'manage'})
        break
      case 'can_get_statistics': {
        rules.push({subject: 'Statistic', action: 'read'})
        break
      }
      default:
        break
    }
  })
  return rules
}
export default mapPermissionsToAbilities
