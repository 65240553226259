import {
  containerFluid,
  drawerMiniWidth,
  drawerWidth,
  primaryColor,
  transition,
} from 'asset/global/jss/styleHelpers'

const globalLayoutStyle = () => ({
  authWrapper: {
    height: 'auto',
    minHeight: '100vh',
    position: 'relative',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  adminLayoutWrapper: {
    position: 'relative',
    top: '0',
    minHeight: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  positionLoader: {
    position: 'fixed',
    top: '0px',
    width: '100%',
    zIndex: 1000,
    '& .MuiLinearProgress-barColorPrimary': {
      backgroundColor: primaryColor[0],
    },
  },
  indeterminate: {
    height: '5px',
  },
  contentAdminLayout: {
    minHeight: 'calc(100vh - 125px)',
  },
  containerAdminLayout: {
    ...containerFluid,
  },
  hide: {
    display: 'none',
  },
  mainAdminPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    width: `calc(100% - ${drawerWidth}px)`,
    overflow: 'auto',
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    overflowScrolling: 'touch',
    padding: '0 40px',
    boxSizing: 'border-box',
  },
  mainAdminPanelSidebarMini: {
    width: `calc(100% - ${drawerMiniWidth}px)`,
  },
  mainAdminPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
})

export default globalLayoutStyle
