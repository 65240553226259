import {primaryColor} from 'asset/global/jss/styleHelpers'

export const counterProposalActionsWrapperStyle = (theme) => ({
  counterWrapper: {
    padding: '20px',
    position: 'relative',
    border: `1px solid ${primaryColor[0]}`,
    marginBottom: '20px',
  },
  counterCloseButton: {
    '& svg': {
      position: 'absolute',
      top: '5px',
      right: '5px',
      fill: primaryColor[0],
      cursor: 'pointer',
    },
  },
})
