import {grayColor} from 'asset/global/jss/styleHelpers'

export const proposalDetailStyle = (theme) => ({
  estimatedCapacityDetailCell: {
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      marginLeft: '10px',
      display: 'flex',
    },
  },
  proposalActionItem: {
    color: grayColor[19],
    fontWeight: 'bold',
    margin: '5px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  deniedProposalAction: {
    textDecoration: 'line-through',
    color: grayColor[5],
  },
  proposalActionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  confirmProposalButton: {
    fontSize: '10px',
    padding: '4px 15px',
  },
})
