import React from 'react'
import cx from 'classnames'
import {withStyles} from 'tss-react/mui'
import Button from '@mui/material/Button'
import globalButtonStyle from 'asset/global/jss/component/common/button/globalButtonStyle'
import LinearProgress from '@mui/material/LinearProgress'

const GlobalButton = (props) => {
  const {
    backgroundColor,
    round,
    children,
    fullWidth,
    disabled,
    startIcon,
    endIcon,
    simple,
    block,
    link,
    justIcon,
    className,
    inverted,
    text,
    loading,
    actionHeaderButton,
    classes,
    ...rest
  } = props

  const btnClasses = cx(
    {
      [classes.button]: true,
      [classes[backgroundColor]]: backgroundColor && !inverted,
      [classes[backgroundColor + 'Inverted']]: backgroundColor && inverted,
      [classes.round]: round,
      [classes.fullWidth]: fullWidth,
      [classes.disabled]: disabled || loading,
      [classes.simple]: simple,
      [classes.block]: block,
      [classes.link]: link,
      [classes.justIcon]: justIcon,
      [classes.actionHeaderButton]: actionHeaderButton,
      [className]: className,
    },
    simple ? backgroundColor : ''
  )

  return (
    <span className={classes.loadingWrapper}>
      <Button
        startIcon={startIcon}
        text={text}
        {...rest}
        className={btnClasses}
        endIcon={endIcon}
        disabled={disabled || loading}
      >
        {children}
      </Button>
      {loading && <LinearProgress className={classes.linearProgress} />}
    </span>
  )
}

export default withStyles(GlobalButton, globalButtonStyle)
