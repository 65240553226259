import React from 'react'
import {Field, Form} from 'react-final-form'
import {Box} from '@mui/material'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {Delete} from '@mui/icons-material'
import DateInput from 'component/global/common/formField/DateInput'
import {EVENT_METHODS} from 'helper/projectSpecific/constants'
import CustomRadioGroup from 'component/global/common/formField/CustomRadioGroup'
import {required} from 'helper/projectSpecific/validations'
import moment from 'moment'
import {DATE_TIME_DATABASE_FORMAT} from 'helper/global/constants'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'

const EventDeleteForm = (props) => {
  const {onSubmit, date} = props

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{method: 'SINGLE_OCCURENCE', date: moment(date, DATE_TIME_DATABASE_FORMAT)}}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <GridContainer spacing={4}>
            <GridItem xs={12}>
              <Field
                name="method"
                component={CustomRadioGroup}
                options={EVENT_METHODS}
                validate={required}
                direction="row"
                xs={12}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="date"
                label={<Trans>Date</Trans>}
                component={DateInput}
                disabled={formProps.values.method === 'ALL_OCCURENCES'}
              />
            </GridItem>

            <GridItem xs={12} pt={1} sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <GlobalButton
                backgroundColor="primary"
                type="submit"
                loading={props.submitting}
                endIcon={<Delete />}
              >
                <Trans>Delete</Trans>
              </GlobalButton>
            </GridItem>
          </GridContainer>
        </form>
      )}
    </Form>
  )
}

EventDeleteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default EventDeleteForm
