import React from 'react'
import PropTypes from 'prop-types'
import TablePagination from '@mui/material/TablePagination'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import updateGlobalSettings from 'redux/action/global/globalSettingsActions'
import {Trans} from '@lingui/macro'

const Pagination = (props) => {
  const {count, rowsPerPage, rowsPerPageOptions, page, onChangePage, updateGlobalSettings} = props

  const updateRowsPerPage = (event) => {
    updateGlobalSettings({rowsPerPage: event.target.value})
  }

  const handleChangePage = (event, value) => {
    onChangePage(value)
  }

  return (
    <TablePagination
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={count ? count : 0}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={updateRowsPerPage}
      labelRowsPerPage={<Trans>Rows per page:</Trans>}
      labelDisplayedRows={({from, to, count}) =>
        `${from}-${to} z ${count !== -1 ? count : `více než ${to}`}`
      }
    />
  )
}

Pagination.propTypes = {
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSettings,
    },
    dispatch
  )
}

export default connect((store) => {
  return {
    rowsPerPage: store.GlobalSettings.rowsPerPage,
    rowsPerPageOptions: store.GlobalSettings.rowsPerPageOptions,
    total: store.Organizations.total,
  }
}, mapDispatchToProps)(Pagination)
