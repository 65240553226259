import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import calendarStyle from 'asset/global/jss/component/calendar/calendarStyle'
import {ChevronLeft, ChevronRight} from '@mui/icons-material'
import {MONTHS_MAP} from 'helper/projectSpecific/constants'

const EventCalendarHeader = (props) => {
  const {previousMonth, selectedYear, selectedMonth, nextMonth, classes} = props

  return (
    <div className={classes.calendarMonthHeader}>
      <ChevronLeft onClick={previousMonth} />
      <div className="text">
        {MONTHS_MAP[selectedMonth + 1]} {selectedYear}
      </div>
      <ChevronRight onClick={nextMonth} />
    </div>
  )
}

EventCalendarHeader.propTypes = {
  previousMonth: PropTypes.func,
  selectedYear: PropTypes.number,
  selectedMonth: PropTypes.number,
  nextMonth: PropTypes.func,
  classes: PropTypes.object,
}

export default withStyles(EventCalendarHeader, calendarStyle)
