import {
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SHARING_REQUEST,
  FETCH_EVENT_SHARING_SUCCESS,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENTS_CALENDAR_REQUEST,
  FETCH_EVENTS_CALENDAR_SUCCESS,
  FETCH_LIST_EVENTS_REQUEST,
  FETCH_LIST_EVENTS_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initListEventsState = {
  isLoading: true,
  data: [],
  meta: {},
}

const initEventsCalendarState = {
  isLoading: true,
  data: [],
}

const initEventState = {
  isLoading: true,
  data: {},
}

const initEventSharingState = {
  isLoading: true,
  data: {},
}

export const ListEventsReducer = (state = initListEventsState, action = null) => {
  switch (action.type) {
    case FETCH_LIST_EVENTS_REQUEST:
      return {...state, isLoading: true}
    case FETCH_LIST_EVENTS_SUCCESS:
      return {
        ...state,
        data: [...action.data.objects],
        meta: {...action.data.meta},
        isLoading: false,
      }
    default:
      return state
  }
}

export const EventsCalendarReducer = (state = initEventsCalendarState, action = null) => {
  switch (action.type) {
    case FETCH_EVENTS_CALENDAR_REQUEST:
      return {...state, isLoading: true}
    case FETCH_EVENTS_CALENDAR_SUCCESS:
      return {
        ...state,
        data: [...action.data],
        isLoading: false,
      }
    default:
      return state
  }
}

export const EventReducer = (state = initEventState, action = null) => {
  switch (action.type) {
    case FETCH_EVENT_REQUEST:
      return {...state, isLoading: true}
    case FETCH_EVENT_SUCCESS:
      return {
        data: {...action.data},
        isLoading: false,
      }
    default:
      return state
  }
}

export const EventSharingReducer = (state = initEventSharingState, action = null) => {
  switch (action.type) {
    case FETCH_EVENT_SHARING_REQUEST:
      return {...state, isLoading: true}
    case FETCH_EVENT_SHARING_SUCCESS:
      return {
        data: {...action.data},
        isLoading: false,
      }
    default:
      return state
  }
}
