import React from 'react'
import PropTypes from 'prop-types'
import {Table, TableBody} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import SimpleTableHead from 'component/global/common/table/SimpleTableHead'

const TableWrapper = (props) => {
  const {orderDirection, orderBy, columns, tablePagination, classes, children, onRequestSort} =
    props

  return (
    <>
      <div className={classes.tableWrapper}>
        <Table>
          <SimpleTableHead
            orderDirection={orderDirection}
            orderBy={orderBy}
            columns={columns}
            onRequestSort={onRequestSort}
          />

          <TableBody className={classes.tableBody}>{children}</TableBody>
        </Table>
      </div>
      {tablePagination}
    </>
  )
}

TableWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  orderDirection: PropTypes.string,
  orderBy: PropTypes.string,
  onRequestSort: PropTypes.func.isRequired,
  handleSelectAllClick: PropTypes.func,
  tablePagination: PropTypes.node,
  children: PropTypes.node,
  columns: PropTypes.array,
  align: PropTypes.string,
}

export default withStyles(TableWrapper, globalWrapperStyles)
