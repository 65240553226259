import React, {useCallback, useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required} from 'helper/projectSpecific/validations'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {bindActionCreators, compose} from 'redux'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getOrganizationUser} from 'redux/action/projectSpecific/organizationUserActions'
import DialogCreateFormButtons from 'component/global/common/dialog/DialogCreateFormButton'
import {getRoles} from 'redux/action/projectSpecific/roleActions'
import PasswordInput from 'component/global/common/formField/PasswordInput'
import {translateErrorMessage} from 'helper/global/functions'

const UserChangePasswordForm = (props) => {
  const {userId, id, form, organizationUser, isOrganizationUserLoaded, errorMessage} = props
  const {onSubmit, getOrganizationUser, getRoles} = props

  const fetchOrganizationUser = useCallback(() => {
    return getOrganizationUser(id, userId, true)
  }, [getOrganizationUser, id, userId])

  const fetchOrganizationUserRoles = useCallback(() => {
    return getRoles()
  }, [getRoles])

  useEffect(() => {
    if (userId) {
      fetchOrganizationUser()
    }
    fetchOrganizationUserRoles()
  }, [userId, fetchOrganizationUser, fetchOrganizationUserRoles, form])

  const getInitialValues = () => {
    if (userId && isOrganizationUserLoaded) {
      return {...organizationUser, roleIds: organizationUser.roles}
    }
  }

  return (
    <Form onSubmit={onSubmit} initialValues={getInitialValues()}>
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <GridContainer spacing={4}>
            <GridItem xs={12}>
              <Field
                name="oldPassword"
                label={<Trans>Old password</Trans>}
                validate={required}
                component={PasswordInput}
                helperText={errorMessage && translateErrorMessage(errorMessage)}
              />
            </GridItem>
            <GridItem xs={12}>
              <Field
                name="newPassword"
                label={<Trans>New password</Trans>}
                validate={required}
                component={PasswordInput}
              />
            </GridItem>
          </GridContainer>
          <GridItem xs={12} pt={1}>
            <DialogCreateFormButtons buttonText={<Trans>Change password</Trans>} />
          </GridItem>
        </form>
      )}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationUser,
      getRoles,
    },
    dispatch
  )
}

UserChangePasswordForm.propTypes = {
  userId: PropTypes.number,
  id: PropTypes.number,
  form: PropTypes.string,
  organizationUser: PropTypes.object,
  isOrganizationUserLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  deleteItem: PropTypes.func,
  getOrganizationUser: PropTypes.func,
  getRoles: PropTypes.func,
}

export default compose(
  connect((store) => {
    return {
      organizationUser: store.OrganizationUser.data,
      isOrganizationUserLoaded: !store.OrganizationUser.isLoading,
      roles: store.Roles.data,
    }
  }, mapDispatchToProps)
)(UserChangePasswordForm)
