import React from 'react'
import organizationDetailStyle from 'asset/projectSpecific/jss/organization/organizationDetailStyle'
import {withStyles} from 'tss-react/mui'
import Pagination from 'component/global/common/table/Pagination'
import TableWrapper from 'component/global/common/wrapper/TableWrapper'
import EventParticipantRow from 'component/projectSpecific/event/eventParticipant/EventParticipantRow'
import {EVENT_PARTICIPANTS_LIST_COLUMNS} from 'component/projectSpecific/event/eventParticipant/_eventParticipantsTableColumns'

const EventParticipantsTab = (props) => {
  const {order, orderBy, countParticipants, participantPage, participants, rowsPerParticipantPage} =
    props
  const {requestSort, onParticipantChangePage, handleParticipantLogout} = props
  return (
    <TableWrapper
      onRequestSort={requestSort}
      columns={EVENT_PARTICIPANTS_LIST_COLUMNS}
      orderDirection={order}
      orderBy={orderBy}
      tablePagination={
        <Pagination
          count={countParticipants}
          page={participantPage}
          onChangePage={onParticipantChangePage}
          rowsPerPage={rowsPerParticipantPage}
        />
      }
    >
      {participants?.map((row) => (
        <EventParticipantRow
          key={row.id}
          row={row}
          onParticipantLogoutClick={handleParticipantLogout(row)}
        />
      ))}
    </TableWrapper>
  )
}

export default withStyles(EventParticipantsTab, organizationDetailStyle)
