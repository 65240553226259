import React, {useContext, useEffect, useState} from 'react'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import {Trans} from '@lingui/macro'
import {EventNote} from '@mui/icons-material'
import TabPanel from 'component/global/common/tab/TabPanel'
import {routesMap} from 'config/routesMap'
import ProposalsListTab from 'component/projectSpecific/proposal/proposalList/ProposalsListTab'
import EventsCalendarTab from 'component/projectSpecific/event/eventList/EventCalendarTab'
import EventPageActionHeader from 'component/projectSpecific/event/eventList/EventPageActionHeader'
import {AbilityContext, Can} from 'App/provider/authorizedAbility'
import {fireErrorToast, fireSuccessToast, redirectWithMessage} from 'helper/global/functions'
import moment from 'moment'
import ReservationCalendarTab from 'component/projectSpecific/event/eventList/ReservationCalendarTab'
import {useNavigate} from 'react-router-dom'

const EventsPage = (props) => {
  const {tab, eventsFilterQuery} = props
  const ability = useContext(AbilityContext)

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(tab)
  const [proposalsLoaded, setProposalsLoaded] = useState(false)
  const [eventsFilter, setEventsFilter] = useState(eventsFilterQuery)
  const [eventsProposalsFilter, setEventsProposalsFilter] = useState()
  const [selectedYear, setSelectedYear] = useState(moment().year())

  const handleChangeTab = (e, tabValue) => {
    setActiveTab(tabValue)
    navigate(`${routesMap.admin.event}/${tabValue}`)
  }

  const openNewForm = () => {
    if (activeTab === 'proposals') {
      return navigate(`${routesMap.admin.proposal}/new`)
    }
    return navigate(`${routesMap.admin.calendar}/new`)
  }

  const canReadProposals = ability.can('read', 'Proposals')
  const canReadEvents = ability.can('read', 'Events')

  useEffect(() => {
    if (canReadProposals || canReadEvents) {
      if (activeTab === 'proposals' && !canReadProposals) {
        setActiveTab('calendar')
        navigate(routesMap.admin.calendar)
      } else if (activeTab === 'calendar' && !canReadEvents) {
        setActiveTab('proposals')
        navigate(routesMap.admin.proposal)
      } else {
        setActiveTab(tab)
      }
    } else {
      redirectWithMessage(<Trans>You are not authorized to list events.</Trans>)
    }
  }, [tab])

  const getTabList = () => {
    const tabList = []
    if (canReadProposals) {
      tabList.push({label: <Trans>Event proposals</Trans>, value: 'proposals'})
    }
    if (canReadEvents) {
      tabList.push({label: <Trans>Events calendar</Trans>, value: 'calendar'})
    }
    if (canReadProposals && canReadEvents) {
      tabList.push({label: <Trans>Reservation overview</Trans>, value: 'reservations'})
    }
    return tabList
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        tabsValue={activeTab}
        tabList={getTabList()}
        handleChangeTab={handleChangeTab}
        iconPageHeader={<EventNote />}
        toolbar={false}
        actionsHeader={
          <EventPageActionHeader
            tab={activeTab}
            openNewForm={openNewForm}
            setEventsFilter={setEventsFilter}
            eventsFilter={eventsFilter}
            setEventsProposalsFilter={setEventsProposalsFilter}
            eventsProposalsFilter={eventsProposalsFilter}
            selectedYear={selectedYear}
          />
        }
      >
        <Can I="read" a="Proposals">
          <TabPanel value={activeTab} index={'proposals'}>
            <ProposalsListTab
              proposalsLoaded={proposalsLoaded}
              setProposalsLoaded={setProposalsLoaded}
            />
          </TabPanel>
        </Can>
        <Can I="read" an="Events">
          <TabPanel value={activeTab} index={'calendar'}>
            <EventsCalendarTab
              filter={eventsFilter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </TabPanel>
        </Can>
        {ability.can('read', 'Proposals') && ability.can('read', 'Events') && (
          <TabPanel value={activeTab} index={'reservations'}>
            <ReservationCalendarTab
              filter={eventsProposalsFilter}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
            />
          </TabPanel>
        )}
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

EventsPage.propTypes = {}

export default EventsPage
