import React from 'react'
import {Trans} from '@lingui/macro'
import DropDownButton from 'component/global/common/button/DropDownButton'
import {TableChart} from '@mui/icons-material'
import PropTypes from 'prop-types'
import {Can} from 'App/provider/authorizedAbility'

const ParticipantButtons = (props) => {
  const {exportToXls} = props
  return (
    <Can I="export" a="Participants">
      <DropDownButton
        backgroundColor="primary"
        label={<Trans>Export</Trans>}
        dropDownItems={[
          {
            label: (
              <>
                <TableChart />
                <Trans>Export to XLS</Trans>
              </>
            ),
            onClick: exportToXls,
          },
        ]}
      />
    </Can>
  )
}

ParticipantButtons.propTypes = {
  exportToXls: PropTypes.func,
  exportToPdf: PropTypes.func,
}

export default ParticipantButtons
