import moment from 'moment/moment'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import SelectField from 'component/global/common/formField/SelectField'
import DateInput from 'component/global/common/formField/DateInput'
import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import {connect} from 'react-redux'
import {getListOrganizations} from 'redux/action/projectSpecific/organizationActions'

const StatisticsFilters = ({
  organizations,
  classes,
  getListOrganizations,
  setFilters,
  filters,
  handleChangeFilter,
  activeTab,
}) => {
  const handleChangeOrganization = (name, value) => {
    setFilters((prevState) => ({
      ...prevState,
      organizationId: value,
    }))
  }

  useEffect(() => {
    getListOrganizations(0, 300, 'name', 'ASC')
  }, [])

  return (
    <Form onSubmit={() => {}} initialValues={filters}>
      {(formProps) => {
        const handleChangePeriod = (name, value) => {
          if (value === 'thisWeek') {
            formProps.form.change('from', moment().startOf('week'))
            formProps.form.change('to', moment().endOf('week'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'thisWeek',
              from: moment().startOf('week'),
              to: moment().endOf('week'),
            }))
          } else if (value === 'thisMonth') {
            formProps.form.change('from', moment().startOf('month'))
            formProps.form.change('to', moment().endOf('month'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'thisMonth',
              from: moment().startOf('month'),
              to: moment().endOf('month'),
            }))
          } else if (value === 'thisYear') {
            formProps.form.change('from', moment().startOf('year'))
            formProps.form.change('to', moment().endOf('year'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'thisYear',
              from: moment().startOf('year'),
              to: moment().endOf('year'),
            }))
          } else if (value === 'lastWeek') {
            formProps.form.change('from', moment().startOf('week').subtract(1, 'week'))
            formProps.form.change('to', moment().endOf('week').subtract(1, 'week'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'lastWeek',
              from: moment().startOf('week').subtract(1, 'week'),
              to: moment().endOf('week').subtract(1, 'week'),
            }))
          } else if (value === 'lastMonth') {
            formProps.form.change('from', moment().startOf('month').subtract(1, 'month'))
            formProps.form.change('to', moment().endOf('month').subtract(1, 'month'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'lastMonth',
              from: moment().startOf('month').subtract(1, 'month'),
              to: moment().endOf('month').subtract(1, 'month'),
            }))
          } else if (value === 'lastYear') {
            formProps.form.change('from', moment().startOf('year').subtract(1, 'year'))
            formProps.form.change('to', moment().endOf('year').subtract(1, 'year'))
            setFilters((prevState) => ({
              ...prevState,
              period: 'lastYear',
              from: moment().startOf('year').subtract(1, 'year'),
              to: moment().endOf('year').subtract(1, 'year'),
            }))
          }
        }

        return (
          <form onSubmit={formProps.onSubmit}>
            <GridContainer direction={'row'} spacing={4}>
              {activeTab !== 'events' && (
                <GridItem>
                  <Field
                    name={'organizationId'}
                    label={<Trans>Organization</Trans>}
                    component={SelectField}
                    options={organizations.map((org) => ({value: org.id, label: org.name}))}
                    classNames={classes.fieldWidth}
                    customOnChange={handleChangeOrganization}
                    emptyOption={true}
                    emptyOptionLabel={<Trans>None</Trans>}
                  />
                </GridItem>
              )}
              <GridItem>
                <Field
                  name="period"
                  label={<Trans>Period</Trans>}
                  component={SelectField}
                  options={[
                    {value: 'thisWeek', label: <Trans>This week</Trans>},
                    {value: 'thisMonth', label: <Trans>This month</Trans>},
                    {value: 'thisYear', label: <Trans>This year</Trans>},
                    {value: 'lastWeek', label: <Trans>Last week</Trans>},
                    {value: 'lastMonth', label: <Trans>Last month</Trans>},
                    {value: 'lastYear', label: <Trans>Last year</Trans>},
                    {value: 'own', label: <Trans>Own</Trans>},
                  ]}
                  classNames={classes.fieldWidth}
                  customOnChange={handleChangePeriod}
                />
              </GridItem>
              <GridItem>
                <Field
                  name="from"
                  label={<Trans>From</Trans>}
                  component={DateInput}
                  onCustomChange={handleChangeFilter}
                  maxDate={formProps.values?.to || undefined}
                  classNames={classes.fieldWidth}
                />
              </GridItem>
              <GridItem>
                <Field
                  name="to"
                  label={<Trans>To</Trans>}
                  component={DateInput}
                  onCustomChange={handleChangeFilter}
                  minDate={formProps.values?.from || undefined}
                  classNames={classes.fieldWidth}
                />
              </GridItem>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getListOrganizations,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {organizations: store.Organizations.data}
  }, mapDispatchToProps)
)(withStyles(StatisticsFilters, globalWrapperStyles))
