import React from 'react'
import {FormControl, FormHelperText, Input, InputLabel, MenuItem, Select} from '@mui/material'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import GlobalChip from 'component/global/common/table/GlobalChip'

const MultiSelectField = (props) => {
  const {
    classes,
    meta,
    helperText,
    required,
    labelProps,
    label,
    options,
    id,
    renderValue,
    input: {value: selectedValues, onChange},
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  const defaultRenderValue = (selected) => {
    const selectedForRender = options.filter((option) => {
      if (option.label) {
        return selected.indexOf(option.value) !== -1
      }
      return selected.indexOf(option) !== -1
    })
    return (
      <div className={classes.chipWrapper}>
        {selectedForRender?.map((item) =>
          item.label ? (
            <GlobalChip key={item.value} label={item.label} />
          ) : (
            <GlobalChip key={item} label={item} />
          )
        )}
      </div>
    )
  }

  return (
    <FormControl variant="standard" className={classes.formSelectControl} required={required}>
      <InputLabel className={errorMessage ? 'error' : ''} {...labelProps}>
        {label}
      </InputLabel>
      <Select
        variant="standard"
        className={classes.customSelectClasses}
        value={
          selectedValues ? (Array.isArray(selectedValues) ? selectedValues : [selectedValues]) : []
        }
        onChange={onChange}
        multiple
        input={<Input id={id} placement="bottom" onChange={onChange} />}
        renderValue={renderValue || defaultRenderValue}
        {...rest}
      >
        {options?.map((item, index) => {
          if (item.label) {
            return (
              <MenuItem key={index} value={item.value} className={classes.itemStyle}>
                <Checkbox checked={selectedValues.indexOf(item.value) !== -1} />
                <ListItemText primary={item.label} />
              </MenuItem>
            )
          }
          return (
            <MenuItem key={item} value={item} className={classes.itemStyle}>
              <Checkbox checked={selectedValues.indexOf(item) !== -1} />
              <ListItemText primary={item} />
            </MenuItem>
          )
        })}
      </Select>
      <FormHelperText>{errorMessage || helperText || ' '}</FormHelperText>
    </FormControl>
  )
}

MultiSelectField.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.node,
  label: PropTypes.node,
  options: PropTypes.array,
  id: PropTypes.number,
  renderValue: PropTypes.func,
}

export default withStyles(MultiSelectField, globalFormFieldStyles)
