import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import EventDeleteForm from 'component/projectSpecific/event/eventForm/EventDeleteForm'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'

const EventDeleteFormDialog = (props) => {
  const {openDialog, closeDialog, handleDeleteSubmit, name, eventDate} = props

  return (
    <DialogWrapper
      open={openDialog}
      maxWidth="md"
      handleClose={closeDialog}
      title={<Trans>Delete event {name}</Trans>}
    >
      <EventDeleteForm onSubmit={handleDeleteSubmit} date={eventDate} />
    </DialogWrapper>
  )
}

EventDeleteFormDialog.propTypes = {
  openDeleteWarningDialog: PropTypes.bool,
  closeDeleteWarningDialog: PropTypes.func,
  confirmDelete: PropTypes.func,
  name: PropTypes.node,
}

export default EventDeleteFormDialog
