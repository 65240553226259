import {
  FETCH_LIST_PROPOSALS_REQUEST,
  FETCH_LIST_PROPOSALS_SUCCESS,
  FETCH_PROPOSAL_CONFIRM_DATA_REQUEST,
  FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS,
  FETCH_PROPOSAL_REQUEST,
  FETCH_PROPOSAL_SUCCESS,
  FETCH_PROPOSALS_CALENDAR_REQUEST,
  FETCH_PROPOSALS_CALENDAR_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initListProposalsState = {
  isLoading: true,
  data: [],
  meta: {},
  calendar: [],
  calendarLoading: false,
}

const initProposalState = {
  isLoading: true,
  data: {},
}

const initProposalConfirmDataState = {
  isLoading: true,
  data: {},
}

export const ListProposalsReducer = (state = initListProposalsState, action = null) => {
  switch (action.type) {
    case FETCH_LIST_PROPOSALS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_LIST_PROPOSALS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    case FETCH_PROPOSALS_CALENDAR_REQUEST: {
      return {
        ...state,
        calendarLoading: true,
      }
    }
    case FETCH_PROPOSALS_CALENDAR_SUCCESS: {
      return {
        ...state,
        calendar: action.data,
        calendarLoading: false,
      }
    }
    default:
      return state
  }
}

export const ProposalReducer = (state = initProposalState, action = null) => {
  switch (action.type) {
    case FETCH_PROPOSAL_REQUEST:
      return {...state, isLoading: true}
    case FETCH_PROPOSAL_SUCCESS:
      return {
        data: action.data,
        isLoading: false,
      }
    default:
      return state
  }
}

export const ProposalConfirmDataReducer = (state = initProposalConfirmDataState, action = null) => {
  switch (action.type) {
    case FETCH_PROPOSAL_CONFIRM_DATA_REQUEST:
      return {...state, isLoading: true}
    case FETCH_PROPOSAL_CONFIRM_DATA_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }
    default:
      return state
  }
}
