import httpClient from 'helper/global/httpClient'
import endpoints from 'config/endpoints'
import {globalApiErrorHandler} from 'helper/global/functions'
import {
  FETCH_LIST_ROLES_REQUEST,
  FETCH_LIST_ROLES_SUCCESS,
} from 'redux/action/projectSpecific/types'

export function getRoles() {
  return function (dispatch) {
    dispatch({type: FETCH_LIST_ROLES_REQUEST})
    return httpClient
      .get(endpoints.roles)
      .then((response) => {
        dispatch({
          type: FETCH_LIST_ROLES_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}
