import GridItem from 'component/global/common/grid/GridItem'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {Trans} from '@lingui/macro'
import GridContainer from 'component/global/common/grid/GridContainer'
import React from 'react'
import PropTypes from 'prop-types'

const WarningDialogButtons = (props) => {
  const {handleClose, handleConfirm} = props
  return (
    <GridContainer spacing={4}>
      <GridItem container xs={6} justifyContent="flex-end">
        <GlobalButton onClick={handleClose} backgroundColor="primary" inverted>
          <Trans>Cancel</Trans>
        </GlobalButton>
      </GridItem>
      <GridItem xs={6}>
        <GlobalButton onClick={handleConfirm} backgroundColor="primary">
          <Trans>Confirm</Trans>
        </GlobalButton>
      </GridItem>
    </GridContainer>
  )
}

WarningDialogButtons.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
}

export default WarningDialogButtons
