import {
  FETCH_LIST_ORGANIZATIONS_REQUEST,
  FETCH_LIST_ORGANIZATIONS_SUCCESS,
  FETCH_ORGANIZATION_REQUEST,
  FETCH_ORGANIZATION_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initListOrganizationsState = {
  isLoading: true,
  data: [],
  meta: {},
}
const initOrganizationState = {
  isLoading: true,
  data: {},
}

export const ListOrganizationsReducer = (state = initListOrganizationsState, action = null) => {
  switch (action.type) {
    case FETCH_LIST_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_LIST_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    default:
      return state
  }
}

export const OrganizationsReducer = (state = initOrganizationState, action = null) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORGANIZATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
