import React from 'react'
import {withStyles} from 'tss-react/mui'
import Grid from '@mui/material/Grid'
import globalGridStyle from 'asset/global/jss/component/common/grid/globalGridStyle'

const GridContainer = (props) => {
  const {classes, children, className, ...rest} = props

  return (
    <Grid container {...rest} className={className}>
      {children}
    </Grid>
  )
}

export default withStyles(GridContainer, globalGridStyle)
