import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'
import TopicForm from 'component/projectSpecific/topic/topicForm/TopicForm'

const TopicEditDialog = (props) => {
  const {openEdit, closeEdit, handleEditSubmit, editId, handleDeleteItem} = props
  return (
    <DialogWrapper
      open={openEdit}
      maxWidth="md"
      handleClose={closeEdit}
      title={<Trans>Edit topic</Trans>}
    >
      <TopicForm
        onSubmit={handleEditSubmit}
        form="edit"
        editId={editId}
        deleteItem={handleDeleteItem}
      />
    </DialogWrapper>
  )
}

TopicEditDialog.propTypes = {
  openEdit: PropTypes.bool,
  closeEdit: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  editId: PropTypes.node,
  handleDeleteItem: PropTypes.func,
}

export default TopicEditDialog
