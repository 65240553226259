import React from 'react'
import {Group} from '@mui/icons-material'
import PropTypes from 'prop-types'
import {proposalDetailStyle} from 'asset/projectSpecific/jss/proposal/proposalDetailStyle'
import {withStyles} from 'tss-react/mui'

const EstimatedCapacityDetailCell = (props) => {
  const {estimatedCapacity, classes} = props

  return (
    <div className={classes.estimatedCapacityDetailCell}>
      {estimatedCapacity}
      <span>
        <Group />
      </span>
    </div>
  )
}

EstimatedCapacityDetailCell.propTypes = {
  estimatedCapacity: PropTypes.node,
  classes: PropTypes.object.isRequired,
}

export default withStyles(EstimatedCapacityDetailCell, proposalDetailStyle)
