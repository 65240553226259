import {UPDATE_GLOBAL_SETTINGS_SUCCESS} from 'redux/action/global/types'

function updateGlobalSettings(data) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        type: UPDATE_GLOBAL_SETTINGS_SUCCESS,
        data,
      })
      resolve()
    })
  }
}

export default updateGlobalSettings
