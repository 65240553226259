import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import HorizontalStepFormWrapper from 'component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from 'tss-react/mui'
import {stepperFormStyle} from 'asset/projectSpecific/jss/common/stepperFormStyle'
import CustomCheckbox from 'component/global/common/formField/CustomCheckbox'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import TextInput from 'component/global/common/formField/TextInput'
import {ORGANIZATIONAL_SETUP, PROPOSAL_REQUIREMENTS} from 'helper/projectSpecific/constants'
import GlobalStepperButtons from 'component/global/common/button/GlobalStepperButtons'
import SelectField from 'component/global/common/formField/SelectField'
import Tooltip from '@mui/material/Tooltip'
import {Info} from '@mui/icons-material'
import ConfigurationDialog from 'component/projectSpecific/proposal/proposalForm/ConfigurationDialog'

const ProposalRequirementsForm = (props) => {
  const {onSubmitRequirements, goStepBack, initialValues, classes} = props

  const [configurationDialog, setConfigurationDialog] = useState(false)

  return (
    <HorizontalStepFormWrapper mediumWidth={8} largeWidth={6}>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      <Form onSubmit={onSubmitRequirements} initialValues={initialValues}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            {PROPOSAL_REQUIREMENTS.map((proposalRequirement, indexProposal) => (
              <div key={indexProposal} className={classes.requirementBox}>
                <div className={classes.spaceConfigurationLabel}>
                  <h4>{proposalRequirement.header}</h4>
                  {proposalRequirement.group === 'organizationalRequirements' && (
                    <Tooltip
                      title={<Trans>Configuration map</Trans>}
                      placement="top"
                      enterDelay={300}
                    >
                      <a
                        href="/planek-usporadani.pdf"
                        target="_blank"
                        style={{display: 'flex', zIndex: 1}}
                      >
                        <Info />
                      </a>
                    </Tooltip>
                  )}
                </div>
                <GridContainer spacing={2} alignItems={'center'}>
                  {proposalRequirement.requirements.map((requirement, index) => (
                    <GridItem key={index} xs={3}>
                      <Field
                        name={proposalRequirement.group}
                        value={requirement.value}
                        type="checkbox"
                        label={requirement.label}
                        component={CustomCheckbox}
                      />
                    </GridItem>
                  ))}
                  {proposalRequirement.setUp && (
                    <GridItem xs={6}>
                      <Field
                        name={proposalRequirement.setUp.name}
                        label={proposalRequirement.setUp.label}
                        component={SelectField}
                        emptyOption={true}
                        options={ORGANIZATIONAL_SETUP}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12}>
                    <Field
                      name={proposalRequirement.note.name}
                      label={proposalRequirement.note.label}
                      component={TextInput}
                      variant={'outlined'}
                      multiline={true}
                    />
                  </GridItem>
                </GridContainer>
              </div>
            ))}
            <GlobalStepperButtons
              handleBack={goStepBack}
              submitButtonLabel={<Trans>Create</Trans>}
            />
          </form>
        )}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

ProposalRequirementsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitRequirements: PropTypes.func,
  goStepBack: PropTypes.func,
}

export default withStyles(ProposalRequirementsForm, stepperFormStyle)
