import httpClient from 'helper/global/httpClient'
import {
  FETCH_ORGANIZATION_CONTACT_REQUEST,
  FETCH_ORGANIZATION_CONTACT_SUCCESS,
  FETCH_ORGANIZATION_CONTACTS_REQUEST,
  FETCH_ORGANIZATION_CONTACTS_SUCCESS,
} from 'redux/action/projectSpecific/types'
import {globalApiErrorHandler} from 'helper/global/functions'
import endpoints from 'config/endpoints'
import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'

export function getOrganizationContacts(
  id,
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc'
) {
  return (dispatch) => {
    const listOrganizationContactsFilters = {
      listOrganizationContactsFilters: {
        orderBy,
        orderDirection,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listOrganizationContactsFilters})
    dispatch({type: FETCH_ORGANIZATION_CONTACTS_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/contacts`, {
        limit,
        offset,
        orderBy: orderBy ? orderBy : null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_CONTACTS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getOrganizationContact(id, contactId) {
  return (dispatch) => {
    dispatch({type: FETCH_ORGANIZATION_CONTACT_REQUEST})
    return httpClient
      .get(`${endpoints.organizations}${id}/contacts/${contactId}`)
      .then((response) => {
        dispatch({
          type: FETCH_ORGANIZATION_CONTACT_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function createOrganizationContact(organizationId, formParams) {
  return function () {
    return httpClient
      .post(`${endpoints.organizations}${organizationId}/contacts`, formParams)
      .catch(globalApiErrorHandler)
  }
}

export function editOrganizationContact(organizationId, contactId, formParams) {
  return function () {
    return httpClient
      .put(`${endpoints.organizations}${organizationId}/contacts/${contactId}`, {
        ...formParams,
        id: contactId,
      })
      .catch(globalApiErrorHandler)
  }
}

export function deleteOrganizationContact(organizationId, contactId) {
  return function () {
    return httpClient
      .del(`${endpoints.organizations}${organizationId}/contacts/${contactId}`)
      .catch(globalApiErrorHandler)
  }
}

export function exportOrganizationContacts(organizationId) {
  return function () {
    return httpClient
      .get(
        `${endpoints.organizations}${organizationId}/contacts/export`,
        {}, // empty data params
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .catch(globalApiErrorHandler)
  }
}

export function exportAllOrganizationsContacts() {
  return function () {
    return httpClient
      .get(
        `${endpoints.organizations}contacts/export`,
        {}, // empty data params
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .catch(globalApiErrorHandler)
  }
}
