import React from 'react'
import {withStyles} from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'

const SwitcherField = (props) => {
  const {
    helpText,
    classes,
    disabled,
    input: {onChange, checked},
    valueA,
    valueB,
    justifyStart,
    justifyEnd,
    className,
    zeroMarginTop,
  } = props

  const switcherClasses = cx(
    classes.switcher,
    justifyStart && classes.switcherJustifyStart,
    justifyEnd && classes.switcherJustifyEnd,
    zeroMarginTop && classes.switcherZeroMarginTop,
    className
  )

  return (
    <FormControl variant="standard" className={classes.formSwitchControl} disabled={disabled}>
      <div className={switcherClasses}>
        <span>{valueA}</span>
        <Switch checked={checked} onChange={onChange} />
        <span>{valueB}</span>
      </div>
      <FormHelperText className={classes.helperTextSwitcherMargin}>{helpText}</FormHelperText>
    </FormControl>
  )
}

export default withStyles(SwitcherField, globalFormFieldStyles)
