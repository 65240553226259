import React from 'react'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {DeleteForever, Edit} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GridContainer from 'component/global/common/grid/GridContainer'
import {Can} from 'App/provider/authorizedAbility'
import GridItem from 'component/global/common/grid/GridItem'

const DialogEditFormButtons = (props) => {
  const {deleteItem, permissionAsset, submitting} = props

  return (
    <GridContainer justifyContent="space-between">
      <Can I="delete" a={permissionAsset}>
        <GridItem>
          <GlobalButton
            backgroundColor="warning"
            startIcon={<DeleteForever />}
            onClick={deleteItem}
            style={{visibility: deleteItem === null ? 'hidden' : 'auto'}}
          >
            <Trans>Delete</Trans>
          </GlobalButton>
        </GridItem>
      </Can>
      <Can I="update" a={permissionAsset}>
        <GridItem>
          <GlobalButton
            backgroundColor="primary"
            type="submit"
            loading={submitting}
            startIcon={<Edit />}
          >
            <Trans>Edit</Trans>
          </GlobalButton>
        </GridItem>
      </Can>
    </GridContainer>
  )
}

DialogEditFormButtons.propTypes = {
  deleteItem: PropTypes.func,
  permissionAsset: PropTypes.string,
}

export default DialogEditFormButtons
