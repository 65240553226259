import React from 'react'
import {RouterProvider} from 'react-router-dom'
import {I18nProvider} from '@lingui/react'
import {ToastContainer} from 'react-toastify'
import 'App/appStyle.scss'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import 'moment/locale/cs'
import catalogEn from 'App/locale/en/messages'
import catalogCs from 'App/locale/cs/messages'
import i18nProvider from 'App/provider/i18n'
import {router} from 'App/provider/router'
import globalToastStyle from 'asset/global/jss/component/common/toast/globalToastStyle'
import {withStyles} from 'tss-react/mui'

const App = (props) => {
  const {classes} = props

  i18nProvider.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18nProvider.activate('cs')

  moment.locale('cs')

  return (
    <I18nProvider i18n={i18nProvider}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <RouterProvider router={router} />
    </I18nProvider>
  )
}

export default withStyles(App, globalToastStyle)
