import React from 'react'
import {resolveEnvironmentParam} from 'helper/global/envParams'
import {fireErrorToast} from 'helper/global/functions'
import axios from 'axios'
import {v4} from 'uuid'
import {SESSION_ID} from 'helper/global/constants'
import {Trans} from '@lingui/macro'

const PUBLIC_API_KEY = resolveEnvironmentParam('PUBLIC_API_KEY')

axios.defaults.withCredentials = true

axios.defaults.headers.common.uuid = v4()
axios.defaults.headers.common['X-PUBLIC-API-KEY'] = PUBLIC_API_KEY

axios.interceptors.request.use((config) => {
  const configCopy = config
  const token = localStorage.getItem(SESSION_ID)

  if (token) {
    configCopy.headers['session-id'] = token
  }

  return configCopy
})

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (!error.response) {
      fireErrorToast(<Trans>Server connection issue</Trans>)
      throw new Error('Server connection issue')
    }

    return Promise.reject(error)
  }
)

const post = (
  url,
  data = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  return axios.post(url, data, config)
}

const get = (
  url,
  params = {},
  config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
) => {
  const configCopy = config
  configCopy.params = params
  return axios.get(url, configCopy)
}

const put = (url, data = {}, config = {}) => {
  return axios.put(url, data, config)
}

const patch = (url, data = {}, config = {}) => {
  return axios.patch(url, data, config)
}

const del = (url, config = {}) => {
  return axios.delete(url, config)
}

const httpClient = {
  post,
  get,
  put,
  del,
  patch,
}

export default httpClient
