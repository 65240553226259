import React from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import {DATE_FORMAT} from 'helper/global/constants'
import {TIME_SLOTS_MAP} from 'helper/projectSpecific/constants'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {proposalListStyle} from 'asset/projectSpecific/jss/proposal/proposalListStyle'
import {withStyles} from 'tss-react/mui'
import PropTypes from 'prop-types'

const ProposalDateTimeCell = (props) => {
  const {proposalActions, openProposalActionsModal, classes} = props
  const notDeniedProposals = proposalActions?.filter((item) => item.status !== 'DENIED')

  if (notDeniedProposals.length > 1) {
    return (
      <GlobalButton onClick={openProposalActionsModal(notDeniedProposals)}>
        <Trans>Counter proposals</Trans>
      </GlobalButton>
    )
  } else {
    return notDeniedProposals?.map((action, index) => {
      return (
        <span key={index}>
          <span>{action.date && moment(action.date).format(DATE_FORMAT)}</span>
          <span className={classes.timeSlot}>
            {action.timeSlot && TIME_SLOTS_MAP[action.timeSlot]}
          </span>
        </span>
      )
    })
  }
}

ProposalDateTimeCell.propTypes = {
  classes: PropTypes.object.isRequired,
  proposalActions: PropTypes.array,
  openProposalActionsModal: PropTypes.func,
}

export default withStyles(ProposalDateTimeCell, proposalListStyle)
