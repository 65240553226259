import httpClient from 'helper/global/httpClient'
import {globalApiErrorHandler} from 'helper/global/functions'
import endpoints from 'config/endpoints'
import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'
import {
  FETCH_EVENT_PARTICIPANTS_REQUEST,
  FETCH_EVENT_PARTICIPANTS_SUCCESS,
} from 'redux/action/projectSpecific/types'

export function getEventParticipants(
  eventId,
  date,
  offset = 0,
  limit = 20,
  orderBy = null,
  orderDirection = 'asc'
) {
  return (dispatch) => {
    const listEventParticipantsFilters = {
      listEventParticipantsFilters: {
        orderBy,
        orderDirection,
      },
    }
    dispatch({type: UPDATE_FILTERS_STATE, data: listEventParticipantsFilters})
    dispatch({type: FETCH_EVENT_PARTICIPANTS_REQUEST})
    return httpClient
      .get(`${endpoints.events}${eventId}/date/${date}/participants`, {
        limit,
        offset,
        orderBy: orderBy || null,
        orderDirection: orderBy ? orderDirection.toUpperCase() : null,
      })
      .then((response) => {
        dispatch({
          type: FETCH_EVENT_PARTICIPANTS_SUCCESS,
          data: response.data,
        })
      })
      .catch(globalApiErrorHandler)
  }
}

export function getEventParticipantsExport(eventId, date) {
  return function () {
    return httpClient
      .get(
        `${endpoints.events}${eventId}/date/${date}/participants/export`,
        {}, // empty data params
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: 'arraybuffer',
        }
      )
      .catch(globalApiErrorHandler)
  }
}

export function logoutEventParticipant(eventId, date, participantId) {
  return function () {
    return httpClient
      .del(`${endpoints.events}${eventId}/date/${date}/participants/${participantId}`)
      .catch(globalApiErrorHandler)
  }
}
