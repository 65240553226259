import React from 'react'
import {TextField} from '@mui/material'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import cx from 'classnames'

const NumberInput = (props) => {
  const {classes, input, meta, helperText, min, max, step, endAdornment, ...rest} = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <TextField
      variant="standard"
      className={cx(classes.root, classes.numberAlignRight)}
      fullWidth
      type="number"
      error={!!errorMessage}
      helperText={errorMessage || helperText || ' '}
      InputProps={{inputProps: {min: min, max: max, step: step}, endAdornment: endAdornment}}
      {...input}
      {...rest}
    />
  )
}

NumberInput.propTypes = {
  classes: PropTypes.object.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object.isRequired,
  rest: PropTypes.object,
  helperText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  endAdornment: PropTypes.node,
}

export default withStyles(NumberInput, globalFormFieldStyles)
