import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import globalCardStyle from 'asset/global/jss/component/common/card/globalCardStyle'

const Card = (props) => {
  const {classes, className, children, profile, color, ...rest} = props

  const cardClasses = cx({
    [classes.card]: true,
    [classes.cardProfile]: profile,
    [classes[color]]: color,
    [className]: className !== undefined,
  })

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  )
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  profile: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  children: PropTypes.node,
}

export default withStyles(Card, globalCardStyle)
