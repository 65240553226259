import {primaryColor} from 'asset/global/jss/styleHelpers'

export const horizontalStepperStyle = (theme) => ({
  horizontalStepForm: {
    padding: '30px 0px',
  },
  stepper: {
    '& .MuiStepIcon-root.MuiStepIcon': {
      '&-active,&-completed': {
        color: primaryColor[0],
      },
    },
    '&.MuiStepper-root': {
      padding: '24px 0px',
    },
  },
  stepLabel: {
    '& .MuiStepLabel-label.Mui-active': {
      display: 'block',
      color: primaryColor[0],
      fontWeight: 500,
    },
  },
})
