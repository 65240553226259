import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import {bindActionCreators, compose} from 'redux'
import {updateLocalLoader} from 'redux/action/global/loaderActions'
import {connect} from 'react-redux'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {getSingleEventVisual, getWeekEventVisual} from 'redux/action/projectSpecific/eventActions'

const EventDetailPrintDialog = (props) => {
  const {
    openDialog,
    closeDialog,
    getSingleEventVisual,
    getWeekEventVisual,
    eventId,
    eventDate,
    updateLocalLoader,
    localLoading,
  } = props

  const displayPdf = (data) => {
    const blob = new Blob([data], {type: 'application/pdf'})
    let link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.target = '_blank'
    link.click()
  }

  const handlePrintEvent = () => {
    updateLocalLoader(true)
    getSingleEventVisual(eventId, eventDate).then((res) => {
      displayPdf(res)
      updateLocalLoader(false)
    })
  }

  const handlePrintWeek = () => {
    updateLocalLoader(true)
    getWeekEventVisual(eventDate).then((res) => {
      displayPdf(res)
      updateLocalLoader(false)
    })
  }

  return (
    <DialogWrapper
      open={openDialog}
      maxWidth="md"
      handleClose={closeDialog}
      title={<Trans>Print visuals</Trans>}
    >
      <GridContainer alignItems="center">
        <GridItem xs={8}>
          <Trans>Print visual for this event</Trans>
        </GridItem>
        <GridItem container justifyContent={'flex-end'} xs={4}>
          <GlobalButton
            disabled={localLoading}
            onClick={handlePrintEvent}
            backgroundColor="primary"
          >
            <Trans>Print</Trans>
          </GlobalButton>
        </GridItem>
        <GridItem xs={8}>
          <Trans>Print visual for whole week</Trans>
        </GridItem>
        <GridItem container justifyContent={'flex-end'} xs={4}>
          <GlobalButton disabled={localLoading} onClick={handlePrintWeek} backgroundColor="primary">
            <Trans>Print</Trans>
          </GlobalButton>
        </GridItem>
      </GridContainer>
    </DialogWrapper>
  )
}

EventDetailPrintDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  name: PropTypes.node,
  classes: PropTypes.object,
  editEvent: PropTypes.func,
  updateLocalLoader: PropTypes.func,
  getSingleEventVisual: PropTypes.func,
  getWeekEventVisual: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      getSingleEventVisual,
      getWeekEventVisual,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      localLoading: store.Loader.local,
    }
  }, mapDispatchToProps)
)(withStyles(EventDetailPrintDialog, globalWrapperStyles))
