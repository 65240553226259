import React from 'react'
import {withStyles} from 'tss-react/mui'
import globalGridStyle from 'asset/global/jss/component/common/grid/globalGridStyle'
import GridItem from 'component/global/common/grid/GridItem'

const DetailGridItem = (props) => {
  const {classes, children, className, icon, title, ...rest} = props

  return (
    <GridItem {...rest}>
      <span className={classes.detailGridItemHeader}>
        {icon}
        {title}
      </span>
      {children}
    </GridItem>
  )
}

export default withStyles(DetailGridItem, globalGridStyle)
