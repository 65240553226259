import React, {Suspense, useEffect, useState} from 'react'
import {LOGGED_USER} from 'helper/global/constants'
import PropTypes from 'prop-types'
import Footer from 'component/global/layoutElement/Footer'
import Gradients from 'component/projectSpecific/common/Gradients'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import AdminSidebar from 'component/global/layoutElement/AdminSidebar'
import AdminNavbar from 'component/global/layoutElement/AdminNavbar'
import globalLayoutStyle from 'asset/global/jss/layout/globalLayoutStyle'
import cx from 'classnames'
import LinearProgress from '@mui/material/LinearProgress'
import {connect} from 'react-redux'
import {compose} from 'redux'
import {routesMap} from 'config/routesMap'
import AdminLoadingPage from 'App/page/loading/AdminLoadingPage'
import {withStyles} from 'tss-react/mui'

const AdminLayout = (props) => {
  const {classes, globalLoading, localLoading} = props

  let location = useLocation()
  const navigate = useNavigate()
  const [openedDrawer, setOpenedDrawer] = useState(window.innerWidth > 960)

  const handleDrawerToggle = () => {
    setOpenedDrawer(!openedDrawer)
  }

  const mainPanelClasses = cx(classes.mainAdminPanel, {
    [classes.mainAdminPanelSidebarMini]: !openedDrawer,
    [classes.mainAdminPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  })

  useEffect(() => {
    if (!localStorage.getItem(LOGGED_USER)) navigate(routesMap.auth.login)
    document.getElementById('content-wrapper').scrollTop = 0
    // window.scrollTo(0, 0) maybe use this
  }, [location])

  return (
    <div className={classes.adminLayoutWrapper}>
      <Suspense fallback={<AdminLoadingPage />}>
        <div className={cx(classes.indeterminate, classes.positionLoader)}>
          <LinearProgress
            className={cx(classes.positionLoader, {
              [classes.hide]: !globalLoading && !localLoading,
            })}
            classes={{indeterminate: classes.indeterminate}}
          />
        </div>
        <Gradients size={[400, 800]} />
        <AdminSidebar openedDrawer={openedDrawer} handleDrawerToggle={handleDrawerToggle} />

        <div className={mainPanelClasses} id="content-wrapper">
          <AdminNavbar />
          <div className={classes.contentAdminLayout}>
            <div className={cx(classes.containerAdminLayout, {[classes.hide]: globalLoading})}>
              <Outlet />
            </div>
          </div>
          <Footer />
        </div>
      </Suspense>
    </div>
  )
}

AdminLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  globalLoading: PropTypes.bool,
  localLoading: PropTypes.bool,
}

export default compose(
  connect((store) => {
    return {
      globalLoading: store.Loader.global,
      localLoading: store.Loader.local,
    }
  })
)(withStyles(AdminLayout, globalLayoutStyle))
