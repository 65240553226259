import React, {useContext, useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import HorizontalStepFormWrapper from 'component/global/common/stepper/HorrizontalStepFormWrapper'
import {withStyles} from 'tss-react/mui'
import {stepperFormStyle} from 'asset/projectSpecific/jss/common/stepperFormStyle'
import CustomCheckbox from 'component/global/common/formField/CustomCheckbox'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import TextInput from 'component/global/common/formField/TextInput'
import {ORGANIZATIONAL_SETUP, PROPOSAL_REQUIREMENTS} from 'helper/projectSpecific/constants'
import GlobalStepperButtons from 'component/global/common/button/GlobalStepperButtons'
import SelectField from 'component/global/common/formField/SelectField'
import Tooltip from '@mui/material/Tooltip'
import {Info} from '@mui/icons-material'
import ConfigurationDialog from 'component/projectSpecific/proposal/proposalForm/ConfigurationDialog'
import {AbilityContext} from 'App/provider/authorizedAbility'

const EventRequirementsForm = (props) => {
  const {
    onSubmitRequirements,
    goStepBack,
    initialValues,
    classes,
    havePartnerPermission,
    operation,
    configuration,
  } = props

  const ability = useContext(AbilityContext)

  const getSubmitText = () => {
    if (operation === 'edit') {
      return <Trans>Edit</Trans>
    }
    return <Trans>Create</Trans>
  }
  const [configurationDialog, setConfigurationDialog] = useState(false)

  return (
    <HorizontalStepFormWrapper>
      <ConfigurationDialog
        openDialog={configurationDialog}
        closeDialog={() => setConfigurationDialog(false)}
      />
      <Form onSubmit={onSubmitRequirements} initialValues={initialValues}>
        {(formProps) => (
          <form onSubmit={formProps.handleSubmit}>
            {configuration.zone !== 'PODCAST' &&
              PROPOSAL_REQUIREMENTS.map((proposalRequirement, indexProposal) => (
                <div key={indexProposal} className={classes.requirementBox}>
                  <div className={classes.spaceConfigurationLabel}>
                    <h4>{proposalRequirement.header}</h4>
                    {proposalRequirement.group === 'organizationalRequirements' && (
                      <Tooltip
                        title={<Trans>Configuration map</Trans>}
                        placement="top"
                        enterDelay={300}
                      >
                        <a href="/planek-usporadani.pdf" target="_blank">
                          <Info />
                        </a>
                      </Tooltip>
                    )}
                  </div>
                  <GridContainer spacing={4}>
                    {proposalRequirement.requirements.map((requirement, index) => (
                      <GridItem key={index} xs={6} md={6} lg={3}>
                        <Field
                          name={proposalRequirement.group}
                          value={requirement.value}
                          type="checkbox"
                          label={requirement.label}
                          component={CustomCheckbox}
                          disabled={havePartnerPermission}
                        />
                      </GridItem>
                    ))}
                    {proposalRequirement.setUp && (
                      <GridItem xs={12} md={6}>
                        <Field
                          name={proposalRequirement.setUp.name}
                          label={proposalRequirement.setUp.label}
                          component={SelectField}
                          options={ORGANIZATIONAL_SETUP}
                          disabled={havePartnerPermission}
                        />
                      </GridItem>
                    )}
                    <GridItem xs={12}>
                      <Field
                        name={proposalRequirement.note.name}
                        label={proposalRequirement.note.label}
                        component={TextInput}
                        variant={'outlined'}
                        multiline={true}
                        disabled={havePartnerPermission}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              ))}

            {ability.can('manage', 'Internal') && (
              <div key={'internal'} className={classes.requirementBox}>
                <div className={classes.spaceConfigurationLabel}>
                  <GridContainer spacing={4}>
                    <GridItem xs={12}>
                      <h4>
                        <Trans>Internal requirements</Trans>
                      </h4>
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name={'internalTechnicalNote'}
                        label={<Trans>Internal technical note</Trans>}
                        component={TextInput}
                        variant={'outlined'}
                        multiline={true}
                        disabled={havePartnerPermission}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <Field
                        name={'internalOrganizationalNote'}
                        label={<Trans>Internal organizational note</Trans>}
                        component={TextInput}
                        variant={'outlined'}
                        multiline={true}
                        disabled={havePartnerPermission}
                      />
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            )}
            <GlobalStepperButtons
              handleBack={() => goStepBack(formProps.values)}
              submitButtonLabel={getSubmitText()}
            />
          </form>
        )}
      </Form>
    </HorizontalStepFormWrapper>
  )
}

EventRequirementsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onSubmitRequirements: PropTypes.func,
  goStepBack: PropTypes.func,
  havePartnerPermission: PropTypes.bool,
  operation: PropTypes.string,
  configuration: PropTypes.object,
}

export default withStyles(EventRequirementsForm, stepperFormStyle)
