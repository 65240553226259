import React from 'react'
import PropTypes from 'prop-types'
import GlobalChip from 'component/global/common/table/GlobalChip'
import {getProposalStatus} from 'helper/projectSpecific/functions'

const ProposalStatus = (props) => {
  const {status, size = undefined} = props
  const state = status && getProposalStatus(status)
  return <GlobalChip label={state?.label} color={state?.color} size={size} />
}

ProposalStatus.propTypes = {
  status: PropTypes.node,
}

export default ProposalStatus
