import React, {useCallback, useContext, useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {Trans} from '@lingui/macro'
import {Assignment} from '@mui/icons-material'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import GridContainer from 'component/global/common/grid/GridContainer'
import YearSwitcher from 'component/projectSpecific/common/YearSwitcher'
import {fireSuccessToast, redirectWithMessage} from 'helper/global/functions'
import {
  createTopic,
  deleteTopic,
  editTopic,
  getTopicsCalendar,
} from 'redux/action/projectSpecific/topicActions'
import {updateLocalLoader} from 'redux/action/global/loaderActions'
import TopicCalendarItems from 'component/projectSpecific/topic/topicList/TopicCalendarItems'
import TopicCreateDialog from 'component/projectSpecific/topic/topicDetail/TopicCreatDialog'
import TopicEditDialog from 'component/projectSpecific/topic/topicDetail/TopicEditDialog'
import PropTypes from 'prop-types'
import WarningDialog from 'component/global/common/dialog/WarningDialog'
import {AbilityContext} from 'App/provider/authorizedAbility'
import {DATABASE_DATE_FORMAT} from 'helper/global/constants'
import moment from 'moment'

const TopicsPage = (props) => {
  const ability = useContext(AbilityContext)
  if (ability.cannot('read', 'Topics')) {
    return redirectWithMessage(<Trans>You are not authorized to list topics.</Trans>)
  }

  const {
    year,
    isCalendarLoaded,
    topicsCalendar,
    getTopicsCalendar,
    createTopic,
    editTopic,
    deleteTopic,
    updateLocalLoader,
  } = props

  const [openCreate, setOpenCreate] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [editId, setEditId] = useState(null)
  const [warningDialog, setWarningDialog] = useState({visible: false, topic: null})
  const [createMonth, setCreateMonth] = useState(null)

  const fetchTopicsCalendar = useCallback(() => {
    updateLocalLoader(true)
    return getTopicsCalendar(year).finally(() => {
      updateLocalLoader(false)
    })
  }, [getTopicsCalendar, updateLocalLoader, year])

  useEffect(() => {
    fetchTopicsCalendar()
  }, [fetchTopicsCalendar, year])

  const handleOpenCreate = (month) => (event) => {
    setCreateMonth(month)
    setOpenCreate(true)
  }
  const handleOpenEdit = (topic) => () => {
    setOpenEdit(true)
    setEditId(topic.id)
  }

  const prepareData = (data) => {
    return {
      ...data,
      from: moment(data.from).format(DATABASE_DATE_FORMAT),
      to: moment(data.to).format(DATABASE_DATE_FORMAT),
    }
  }

  const handleTopicCreate = (data) => {
    const dataToSubmit = prepareData(data)
    createTopic(dataToSubmit).then(() => {
      setOpenCreate(false)
      fetchTopicsCalendar()
      fireSuccessToast(<Trans>New topic created.</Trans>)
    })
  }

  const handleTopicEdit = (editId) => (data) => {
    const dataToSubmit = prepareData(data)
    editTopic(editId, dataToSubmit).then(() => {
      setOpenEdit(false)
      fetchTopicsCalendar()
      fireSuccessToast(<Trans>Topic updated.</Trans>)
    })
  }

  const confirmDeleteTopic = () => {
    updateLocalLoader(true)
    deleteTopic(editId)
      .then(() => {
        setOpenEdit(false)
        setWarningDialog({visible: false, topic: null})
        updateLocalLoader(false)
        fetchTopicsCalendar()
        fireSuccessToast(<Trans>Topic deleted.</Trans>)
      })
      .catch(() => {
        updateLocalLoader(false)
      })
  }

  const setWarningDialogData = (topic) => (event) => {
    setWarningDialog({visible: true, topic: topic})
  }

  return (
    <AdminPageWrapper>
      <WarningDialog
        open={warningDialog.visible}
        handleClose={() => setWarningDialog({visible: false, topic: null})}
        handleConfirm={confirmDeleteTopic}
        message={
          warningDialog.topic && <Trans>You will delete topic {warningDialog.topic.name}!</Trans>
        }
      />

      <TopicCreateDialog
        year={year}
        month={createMonth}
        openCreate={openCreate}
        closeCreate={() => setOpenCreate(false)}
        handleCreateSubmit={handleTopicCreate}
      />

      <TopicEditDialog
        openEdit={openEdit}
        closeEdit={() => setOpenEdit(false)}
        handleEditSubmit={handleTopicEdit(editId)}
        editId={editId}
        handleDeleteItem={setWarningDialogData}
      />
      <ContentAdminPageWrapper pageTitle={<Trans>Topics</Trans>} iconPageHeader={<Assignment />}>
        <GridContainer>
          <YearSwitcher />
          {isCalendarLoaded && (
            <TopicCalendarItems
              items={topicsCalendar.data}
              actualYear={year}
              openCreateItem={handleOpenCreate}
              openEditItem={handleOpenEdit}
            />
          )}
        </GridContainer>
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

TopicsPage.propTypes = {
  year: PropTypes.node,
  isCalendarLoading: PropTypes.bool,
  topicsCalendar: PropTypes.object,
  getTopicsCalendar: PropTypes.func,
  createTopic: PropTypes.func,
  editTopic: PropTypes.func,
  deleteTopic: PropTypes.func,
  updateLocalLoader: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateLocalLoader,
      getTopicsCalendar,
      createTopic,
      editTopic,
      deleteTopic,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      year: store.AppFilterStates.topicsCalendarFilters.year,
      topicsCalendar: store.TopicsCalendar.data,
      isCalendarLoaded: !store.TopicsCalendar.isLoading,
    }
  }, mapDispatchToProps)
)(TopicsPage)
