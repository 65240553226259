import React from 'react'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import {horizontalStepperStyle} from 'asset/global/jss/component/stepper/horizontalStepperStyle'
import GridItem from 'component/global/common/grid/GridItem'
import GridContainer from 'component/global/common/grid/GridContainer'

const HorizontalStepFormWrapper = (props) => {
  const {children, classes} = props

  return (
    <GridContainer justifyContent="center">
      <GridItem sm={11} md={10} xl={9}>
        <div className={classes.horizontalStepForm}>{children}</div>
      </GridItem>
    </GridContainer>
  )
}

HorizontalStepFormWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default withStyles(HorizontalStepFormWrapper, horizontalStepperStyle)
