import React from 'react'
import DetailGridItem from 'component/global/common/grid/DetailGridItem'
import {AccountCircleOutlined, ImageOutlined} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import DetailTableWrapper from 'component/global/common/wrapper/DetailTableWrapper'
import imagePlaceholder from 'asset/global/img/image_placeholder.jpg'
import GridContainer from 'component/global/common/grid/GridContainer'
import organizationDetailStyle from 'asset/projectSpecific/jss/organization/organizationDetailStyle'
import {withStyles} from 'tss-react/mui'
import PropTypes from 'prop-types'

const OrganizationDetailTab = (props) => {
  const {detailOrganizationData, classes, logo, organizationLoading} = props
  return (
    <GridContainer spacing={4}>
      <DetailGridItem
        xs={12}
        sm={6}
        icon={<AccountCircleOutlined />}
        title={<Trans>Organization administrator</Trans>}
      >
        <DetailTableWrapper data={detailOrganizationData} />
      </DetailGridItem>

      <DetailGridItem
        xs={12}
        sm={6}
        icon={<ImageOutlined />}
        title={<Trans>Organization logo</Trans>}
      >
        <div className={classes.logoWrapper}>
          {!organizationLoading && (
            <img className={classes.logo} src={logo ? logo : imagePlaceholder} alt="logo" />
          )}
        </div>
      </DetailGridItem>
    </GridContainer>
  )
}

OrganizationDetailTab.propTypes = {
  classes: PropTypes.object.isRequired,
  organizationLoading: PropTypes.bool,
  detailOrganizationData: PropTypes.array,
  logo: PropTypes.string,
}

export default withStyles(OrganizationDetailTab, organizationDetailStyle)
