import {
  BORDER_RADIUS,
  grayColor,
  primaryColor,
  validationErrorFontColor,
  whiteColor,
} from 'asset/global/jss/styleHelpers'

export const stepperFormStyle = (theme) => ({
  spaceConfigurationContent: {
    boxSizing: 'border-box',
    backgroundColor: grayColor[3] + '30',
    border: `1px solid ${grayColor[3]}`,
    color: whiteColor,
    borderRadius: BORDER_RADIUS,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '40px',
    height: '100%',
    padding: '5px',
    '& img': {
      height: 'auto',
      width: '100%',
    },
  },
  spaceConfigurationItemClickable: {
    cursor: 'pointer',
  },
  spaceConfigurationItem: {
    padding: '5px !important',
    height: '100%',
  },
  spaceConfigurationItemSelected: {
    border: `1px solid ${primaryColor[0]}`,
    WebkitBoxShadow: '0px 0px 4px 0px rgba(226,0,116,1)',
    MozBoxShadow: '0px 0px 4px 0px rgba(226,0,116,1)',
    boxShadow: '0px 0px 4px 0px rgba(226,0,116,1)',
  },
  spaceConfigurationItemNonSelected: {
    border: `1px solid ${grayColor[3]}`,
  },
  spaceConfigurationLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '1rem',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& svg': {
      color: primaryColor[0],
      cursor: 'pointer',
    },
  },
  spaceConfigurationContainer: {
    margin: '10px 0',
  },
  spaceConfigurationError: {
    ...validationErrorFontColor,
  },
  privacyPadding: {
    padding: '0px 0px 0px 20px!important',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  requirementBox: {
    border: `1px solid ${primaryColor[0]}`,
    borderRadius: BORDER_RADIUS,
    padding: '10px 20px',
    marginBottom: '20px',
    '&:last-of-type': {
      marginBottom: '0px',
    },
  },
  paddingRight: {
    padding: '0px 10px 0px 0px!important',
  },
  paddingLeft: {
    padding: '0px 0px 0px 10px!important',
  },
  recurrenceHeaders: {
    color: primaryColor[0],
  },
  proposalPlatformSwitcher: {
    marginBottom: '20px',
  },
})
