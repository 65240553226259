import React, {useState} from 'react'
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker'
import FormHelperText from '@mui/material/FormHelperText'
import {withStyles} from 'tss-react/mui'
import {globalFormFieldStyles} from 'asset/global/jss/component/common/formField/globalFormFieldStyles'
import {DATE_FORMAT} from 'helper/global/constants'
import cx from 'classnames'
import {Trans} from '@lingui/macro'
import {ensureMoment} from 'helper/projectSpecific/calendarFunctions'

const DateInput = (props) => {
  const {
    helperText,
    id,
    meta,
    label,
    classes,
    onCustomChange,
    classNames,
    clearable = false,
    input,
    ...rest
  } = props

  const [isValid, setIsValid] = useState(true)

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)

  return (
    <div className={cx(classes.dateTimePicker, classNames)}>
      <DesktopDatePicker
        {...input}
        format={DATE_FORMAT}
        label={label}
        value={input.value ? ensureMoment(input.value) : undefined}
        onChange={(value) => {
          if (value?.isValid()) {
            !isValid && setIsValid(true)
            input.onChange?.(value)
            if (input.name && onCustomChange) {
              onCustomChange(value, input.name)
            }
          } else {
            isValid && setIsValid(false)
          }
        }}
        slotProps={{
          field: {fullWidth: true},
          textField: {
            fullWidth: true,
            variant: 'standard',
            clearable: clearable,
            error: !!errorMessage || !isValid,
          },
        }}
        {...rest}
      />
      <FormHelperText>
        {!isValid ? <Trans>Invalid date format</Trans> : errorMessage || helperText || ' '}
      </FormHelperText>
    </div>
  )
}

export default withStyles(DateInput, globalFormFieldStyles)
