import React from 'react'
import FullTextSearchForm from 'component/global/form/FullTextSearchForm'
import GlobalButton from 'component/global/common/button/GlobalButton'
import {Add, TableChart} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {Can} from 'App/provider/authorizedAbility'
import {withStyles} from 'tss-react/mui'
import globalButtonStyle from 'asset/global/jss/component/common/button/globalButtonStyle'
import DropDownButton from 'component/global/common/button/DropDownButton'
import {Box} from '@mui/material'

const OrganizationListActionsHeader = (props) => {
  const {handleSearch, openDialog, classes, exportAllContacts, exportAllAddresses} = props
  return (
    <>
      <FullTextSearchForm handleSubmit={handleSearch} />
      <Can I="create" an="Organizations">
        <Box pl={1.5} pt={'5px'}>
          <GlobalButton
            onClick={openDialog}
            backgroundColor="primary"
            startIcon={<Add />}
            className={classes.newButton}
          >
            <div>
              <Trans>New organization</Trans>
            </div>
          </GlobalButton>
        </Box>
      </Can>
      <Can I="read" an="Organization">
        <Box pl={1.5} pt={'5px'}>
          <DropDownButton
            backgroundColor="primary"
            label={<Trans>Export</Trans>}
            dropDownItems={[
              {
                label: (
                  <>
                    <TableChart />
                    <Trans>All contacts to XLS</Trans>
                  </>
                ),
                onClick: exportAllContacts,
              },
              {
                label: (
                  <>
                    <TableChart />
                    <Trans>All addresses to XLS</Trans>
                  </>
                ),
                onClick: exportAllAddresses,
              },
            ]}
          />
        </Box>
      </Can>
    </>
  )
}

OrganizationListActionsHeader.propTypes = {
  classes: PropTypes.object,
  handleSearch: PropTypes.func,
  openDialog: PropTypes.func,
  exportAllContacts: PropTypes.func,
  exportAllAddresses: PropTypes.func,
}

export default withStyles(OrganizationListActionsHeader, globalButtonStyle)
