import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'
import UserChangePasswordForm from 'component/projectSpecific/auth/UserChangePasswordForm'

const UserChangePasswordDialog = (props) => {
  const {openDialog, closeDialog, handleSubmit, ...rest} = props

  return (
    <DialogWrapper
      fullWidth={true}
      open={openDialog}
      handleClose={closeDialog}
      title={<Trans>Change password</Trans>}
      maxWidth="sm"
    >
      <UserChangePasswordForm onSubmit={handleSubmit} form="edit" {...rest} />
    </DialogWrapper>
  )
}

UserChangePasswordDialog.propTypes = {
  openDialog: PropTypes.bool,
  closeDialog: PropTypes.func,
  handleSubmit: PropTypes.func,
}

export default UserChangePasswordDialog
