import {
  FETCH_ORGANIZATION_ADDRESS_REQUEST,
  FETCH_ORGANIZATION_ADDRESS_SUCCESS,
  FETCH_ORGANIZATION_ADDRESSES_REQUEST,
  FETCH_ORGANIZATION_ADDRESSES_SUCCESS,
} from 'redux/action/projectSpecific/types'

const initListOrganizationAddressesState = {
  isLoading: true,
  data: [],
  meta: {},
}
const initOrganizationAddressState = {
  isLoading: true,
  data: {},
}

export const ListOrganizationAddressesReducer = (
  state = initListOrganizationAddressesState,
  action = null
) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_ADDRESSES_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORGANIZATION_ADDRESSES_SUCCESS:
      return {
        ...state,
        data: action.data.objects,
        meta: action.data.meta,
        isLoading: false,
      }
    default:
      return state
  }
}

export const OrganizationAddressReducer = (state = initOrganizationAddressState, action = null) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
      }
    case FETCH_ORGANIZATION_ADDRESS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
      }

    default:
      return state
  }
}
