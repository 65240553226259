import React from 'react'
import cx from 'classnames'
import Gradients from 'component/projectSpecific/common/Gradients'
import AdminSidebar from 'component/global/layoutElement/AdminSidebar'
import AdminNavbar from 'component/global/layoutElement/AdminNavbar'
import Footer from 'component/global/layoutElement/Footer'
import PropTypes from 'prop-types'
import {withStyles} from 'tss-react/mui'
import globalLayoutStyle from 'asset/global/jss/layout/globalLayoutStyle'

const AdminLoadingPage = (props) => {
  const {classes} = props
  const openedDrawer = window.innerWidth > 960

  const mainPanelClasses = cx(classes.mainAdminPanel, {
    [classes.mainAdminPanelSidebarMini]: !openedDrawer,
    [classes.mainAdminPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
  })

  return (
    <div className={classes.adminLayoutWrapper}>
      <Gradients size={[400, 800]} />
      <AdminSidebar openedDrawer={openedDrawer} disabled={true} />
      <div className={mainPanelClasses} id="content-wrapper">
        <AdminNavbar disabled={true} />
        <div className={classes.contentAdminLayout}>
          <div className={classes.containerAdminLayout}></div>
        </div>
        <Footer />
      </div>
    </div>
  )
}

AdminLoadingPage.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(AdminLoadingPage, globalLayoutStyle)
