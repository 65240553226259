import {UPDATE_LOADER_SUCCESS, UPDATE_LOCAL_LOADER_SUCCESS} from 'redux/action/global/types'

const initState = {
  global: false,
  local: false,
}

export const LoaderReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_LOADER_SUCCESS) {
    return {
      ...state,
      global: action.data,
    }
  } else if (action.type === UPDATE_LOCAL_LOADER_SUCCESS) {
    return {
      ...state,
      local: action.data,
    }
  }

  return state
}
