import React from 'react'
import {t, Trans} from '@lingui/macro'
import {RRule} from 'rrule'
import i18nProvider from 'App/provider/i18n'

import SpaceConfigA from 'asset/projectSpecific/img/planek_A.png'
import SpaceConfigA1 from 'asset/projectSpecific/img/planek_A1.png'
import SpaceConfigA2 from 'asset/projectSpecific/img/planek_A2.png'
import SpaceConfigB from 'asset/projectSpecific/img/planek_B.png'
import SpaceConfigAB from 'asset/projectSpecific/img/planek_AB.png'
import SpaceConfigA2B from 'asset/projectSpecific/img/planek_A2B.png'

export const ROLES_LIST = [
  {value: 'admin', label: i18nProvider._(t`Administrátor`)},
  {value: 'partner', label: i18nProvider._(t`Partner`)},
  {value: 'podcast_manager', label: i18nProvider._(t`Podcast manažer`)},
  {value: 'technical_manager', label: i18nProvider._(t`Technický manažer`)},
]

export const TIME_SLOTS_LIST = [
  {value: 'MORNING', label: <Trans>9.00 – 12.00 morning</Trans>},
  {value: 'AFTERNOON', label: <Trans>13.00 – 17.00 afternoon</Trans>},
  {value: 'EVENING', label: <Trans>18.00 – 21.00 evening</Trans>},
  {value: 'CUSTOM', label: <Trans>other</Trans>},
]

export const TIME_SLOTS_MAP = {
  MORNING: <Trans>Morning</Trans>,
  AFTERNOON: <Trans>Afternoon</Trans>,
  EVENING: <Trans>Evening</Trans>,
  CUSTOM: <Trans>Custom</Trans>,
}

export const EVENT_TYPES = [
  {value: 'PRESENTATION', label: <Trans>Presentation</Trans>},
  {value: 'WORKSHOP', label: <Trans>Workshop</Trans>},
  {value: 'LECTURE', label: <Trans>Lecture</Trans>},
  {value: 'SEMINAR', label: <Trans>Seminar</Trans>},
  {value: 'TRAINING', label: <Trans>Training</Trans>},
  {value: 'CONFERENCE', label: <Trans>Conference</Trans>},
  {value: 'TALKSHOW', label: <Trans>Talk show</Trans>},
  {value: 'COURSE', label: <Trans>Course</Trans>},
  {value: 'TEAMBUILDING', label: <Trans>Team building</Trans>},
  {value: 'NETWORKING', label: <Trans>Networking</Trans>},
  {value: 'VIRTUAL_EVENT', label: <Trans>Virtual event</Trans>},
  {value: 'INTERNAL_EVENT', label: <Trans>Internal event</Trans>},
  {value: 'CUSTOM', label: <Trans>Custom</Trans>},
]

export const EVENT_ZONES = [
  {value: 'COWORKING_CAFE', label: <Trans>Co-working café</Trans>},
  {value: 'COWORKING_KITCHEN', label: <Trans>Co-working kitchen</Trans>},
  {value: 'PODCAST', label: <Trans>Podcast</Trans>},
  {value: 'MAGENTA_HOME', label: <Trans>Magenta home</Trans>},
  {value: 'GAMING', label: <Trans>Gaming</Trans>},
  {value: 'TUNING', label: <Trans>Tuning stage</Trans>},
  {value: 'EASY_EDU', label: <Trans>EasyEdu</Trans>},
  {value: 'ALL', label: <Trans>All zones</Trans>},
]

export const EVENT_PLATFORMS = [
  {value: 'ONLINE', label: <Trans>Online</Trans>},
  {value: 'OFFLINE', label: <Trans>Offline</Trans>},
]

export const PROPOSAL_STATES = [
  {value: 'CONFIRMED', label: <Trans>Confirmed</Trans>, color: 'success'},
  {value: 'DENIED', label: <Trans>Denied</Trans>, color: 'danger'},
  {value: 'COUNTER_PROPOSAL', label: <Trans>Counter proposal given</Trans>, color: 'secondary'},
  {value: 'PENDING_CONFIRMATION', label: <Trans>Pending confirmation</Trans>, color: 'warning'},
]

export const EVENT_SPACE_CONFIGURATIONS = [
  {id: 'A', configuration: 'A', image: SpaceConfigA},
  {id: 'A1', configuration: 'A1', image: SpaceConfigA1},
  {id: 'A2', configuration: 'A2', image: SpaceConfigA2},
  {id: 'B', configuration: 'B', image: SpaceConfigB},
  {id: 'AB', configuration: 'AB', image: SpaceConfigAB},
  {id: 'A2B', configuration: 'A2+B', image: SpaceConfigA2B},
]

export const TECHNICAL_REQUIREMENTS = [
  {value: 'SOUNDSYSTEM', label: <Trans>Sound system</Trans>},
  {value: 'PROJECTION', label: <Trans>Projection</Trans>},
  {value: 'RECORD', label: <Trans>Record</Trans>},
  {value: 'WIFI', label: <Trans>WiFi</Trans>},
]

export const ORGANIZATIONAL_REQUIREMENTS = [
  {value: 'BACKGROUND', label: <Trans>Background</Trans>},
  {value: 'DRESSING_SERVICE', label: <Trans>Dressing room and Service</Trans>},
]

export const ADDITIONAL_REQUIREMENTS = [
  {value: 'CATERING', label: <Trans>Catering</Trans>},
  {value: 'PHOTOGRAPHER', label: <Trans>Photographer</Trans>},
  {value: 'STREAM', label: <Trans>Stream</Trans>},
]

export const ORGANIZATIONAL_SETUP = [
  {value: 'EMPTY', label: <Trans>Empty hall</Trans>},
  {value: 'THEATER', label: <Trans>Theater (armchairs)</Trans>},
  {value: 'RECEPTION', label: <Trans>Reception (tables)</Trans>},
  {value: 'LECTURE', label: <Trans>Lecture (seating stools)</Trans>},
]

export const PROPOSAL_REQUIREMENTS = [
  {
    header: <Trans>Technical requirements</Trans>,
    group: 'technicalRequirements',
    requirements: TECHNICAL_REQUIREMENTS,
    note: {
      name: 'technicalNote',
      label: <Trans>Technical note</Trans>,
    },
  },
  {
    header: <Trans>Organizational requirements</Trans>,
    group: 'organizationalRequirements',
    requirements: ORGANIZATIONAL_REQUIREMENTS,
    note: {
      name: 'organizationalNote',
      label: <Trans>Organizational note</Trans>,
    },
    setUp: {
      name: 'organizationalSetup',
      label: <Trans>Organizational setup</Trans>,
    },
  },
  {
    header: <Trans>Additional requirements</Trans>,
    group: 'additionalRequirements',
    requirements: ADDITIONAL_REQUIREMENTS,
    note: {
      name: 'additionalNote',
      label: <Trans>Additional note</Trans>,
    },
  },
]

export const PRIVACY_LIST = [
  {value: 'PRIVATE', label: <Trans>Private event</Trans>},
  {value: 'PUBLIC', label: <Trans>Public event</Trans>},
  {value: 'REGISTRATION', label: <Trans>Public event with Registration</Trans>},
]

export const MONTHS_MAP = {
  1: <Trans>January</Trans>,
  2: <Trans>February</Trans>,
  3: <Trans>March</Trans>,
  4: <Trans>April</Trans>,
  5: <Trans>May</Trans>,
  6: <Trans>June</Trans>,
  7: <Trans>July</Trans>,
  8: <Trans>August</Trans>,
  9: <Trans>September</Trans>,
  10: <Trans>October</Trans>,
  11: <Trans>November</Trans>,
  12: <Trans>December</Trans>,
}

export const DAYS = [
  <Trans>Monday</Trans>,
  <Trans>Tuesday</Trans>,
  <Trans>Wednesday</Trans>,
  <Trans>Thursday</Trans>,
  <Trans>Friday</Trans>,
  <Trans>Saturday</Trans>,
  <Trans>Sunday</Trans>,
]

export const EVENT_METHODS = [
  {value: 'ALL_OCCURENCES', label: <Trans>All occurrences</Trans>},
  {value: 'SINGLE_OCCURENCE', label: <Trans>Single occurrence</Trans>},
  {value: 'FROM_OCCURENCE', label: <Trans>From occurrence</Trans>},
]

export const RR_CONSTANTS = {
  repeatOptions: [
    {value: RRule.MONTHLY, label: <Trans>Monthly</Trans>},
    {value: RRule.WEEKLY, label: <Trans>Weekly</Trans>},
    {value: RRule.DAILY, label: <Trans>Daily</Trans>},
  ],
  daysOptions: [
    {value: RRule.MO, label: <Trans>Monday</Trans>},
    {value: RRule.TU, label: <Trans>Tuesday</Trans>},
    {value: RRule.WE, label: <Trans>Wednesday</Trans>},
    {value: RRule.TH, label: <Trans>Thursday</Trans>},
    {value: RRule.FR, label: <Trans>Friday</Trans>},
    {value: RRule.SA, label: <Trans>Saturday</Trans>},
    {value: RRule.SU, label: <Trans>Sunday</Trans>},
  ],
  daysOptionsExt: [
    {
      value: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR].toString(),
      label: <Trans>Weekday</Trans>,
    },
    {value: [RRule.SA, RRule.SU].toString(), label: <Trans>Weekend day</Trans>},
  ],
  dayInMonthPosition: [
    {value: 1, label: <Trans>First</Trans>},
    {value: 2, label: <Trans>Second</Trans>},
    {value: 3, label: <Trans>Third</Trans>},
    {value: 4, label: <Trans>Fourth</Trans>},
    {value: -1, label: <Trans>Last</Trans>},
  ],
  endOptions: [
    {value: 'never', label: <Trans>Never to end</Trans>},
    {value: 'after', label: <Trans>End after X occurrences</Trans>},
    {value: 'until', label: <Trans>End on date</Trans>},
  ],
  monthlyRepetitionType: [
    {value: 'onDay', label: <Trans>On the</Trans>},
    {value: 'onThe', label: <Trans>On day</Trans>},
  ],
  daysInMonthNumbers: Array(31)
    .fill(1)
    .map((x, y) => x + y),
  dayByKey: {
    MO: i18nProvider._(t`Monday`),
    TU: i18nProvider._(t`Tuesday`),
    WE: i18nProvider._(t`Wednesday`),
    TH: i18nProvider._(t`Thursday`),
    FR: i18nProvider._(t`Friday`),
    SA: i18nProvider._(t`Saturday`),
    SU: i18nProvider._(t`Sunday`),
  },
}

export const ADDRESS_TYPES = [
  {value: 'INVOICING', label: <Trans>Invoicing</Trans>},
  {value: 'CORRESPONDENCE', label: <Trans>Correspondence</Trans>},
  {value: 'INVOICING_AND_CORRESPONDENCE', label: <Trans>Invoicing / correspondence</Trans>},
]

export const RECURRING_EVENT_PAYMENT_TYPES = [
  {
    value: 'SINGLE_PAYMENT',
    label: <Trans>Single payment</Trans>,
  },
  {
    value: 'PAYMENT_PER_EVENT',
    label: <Trans>Payment per event</Trans>,
  },
]
