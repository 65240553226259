import React from 'react'
import {Trans} from '@lingui/macro'
import ContentAuthPageWrapper from 'component/global/common/wrapper/ContentAuthPageWrapper'
import AuthPageWrapper from 'component/global/common/wrapper/AuthPageWrapper'
import {Field, Form} from 'react-final-form'
import InputAdornment from '@mui/material/InputAdornment'
import {Box, IconButton} from '@mui/material'
import {Person} from '@mui/icons-material'
import TextInput from 'component/global/common/formField/TextInput'
import PasswordInput from 'component/global/common/formField/PasswordInput'
import CustomCheckbox from 'component/global/common/formField/CustomCheckbox'
import GlobalButton from 'component/global/common/button/GlobalButton'
import GlobalLink from 'component/global/common/button/GlobalLink'
import {withStyles} from 'tss-react/mui'
import globalLayoutElementsStyle from 'asset/global/jss/component/layoutElement/globalLayoutElementsStyle'

const AuthLoadingPage = ({classes}) => {
  return (
    <AuthPageWrapper>
      <ContentAuthPageWrapper pageTitle={<Trans>experience center</Trans>}>
        <Form onSubmit={() => {}}>
          {(formProps) => (
            <form onSubmit={formProps.handleSubmit}>
              <Field
                name="email"
                label={<Trans>Username / e-mail</Trans>}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton disabled size="large">
                        <Person />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                component={TextInput}
                autoComplete="email"
              />
              <Field
                name="password"
                label={<Trans>Password</Trans>}
                component={PasswordInput}
                autoComplete="current-password"
              />
              <Field
                name="tos_agreement"
                type="checkbox"
                label={
                  <>
                    <Trans>I agree with</Trans>{' '}
                    <a className={classes.tosLink} href="/tos.pdf" target="_blank">
                      <Trans>Terms Of Service</Trans>
                    </a>
                  </>
                }
                component={CustomCheckbox}
              />
              <Box pt={1}>
                <GlobalButton
                  backgroundColor="primary"
                  fullWidth
                  type="submit"
                  loading={formProps.submitting}
                >
                  <Trans>Login</Trans>
                </GlobalButton>
              </Box>
              <Box pt={1} textAlign={'center'}>
                <GlobalLink>
                  <Trans>Forgotten password</Trans>
                </GlobalLink>
              </Box>
            </form>
          )}
        </Form>
      </ContentAuthPageWrapper>
    </AuthPageWrapper>
  )
}

export default withStyles(AuthLoadingPage, globalLayoutElementsStyle)
