import React from 'react'
import PropTypes from 'prop-types'
import GridContainer from 'component/global/common/grid/GridContainer'

const AdminPageWrapper = ({children}) => {
  return <GridContainer>{children}</GridContainer>
}

AdminPageWrapper.propTypes = {
  children: PropTypes.node,
}

export default AdminPageWrapper
