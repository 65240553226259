import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import DialogWrapper from 'component/global/common/wrapper/DialogWrapper'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import {required} from 'helper/projectSpecific/validations'
import TextInput from 'component/global/common/formField/TextInput'
import DialogCreateFormButtons from 'component/global/common/dialog/DialogCreateFormButton'
import {getOrganizationAddress} from 'redux/action/projectSpecific/organizationAddressAction'
import {useParams} from 'react-router-dom'
import SelectField from 'component/global/common/formField/SelectField'
import {ADDRESS_TYPES} from 'helper/projectSpecific/constants'

const OrganizationAddressDialog = (props) => {
  const {open, close, handleSubmit, form, getOrganizationAddress, address, organizationAddress} =
    props

  const {id} = useParams()

  const initValues = () => {
    return {
      ...organizationAddress,
    }
  }

  useEffect(() => {
    if (form === 'edit' && address?.id) {
      getOrganizationAddress(id, address?.id)
    }
  }, [form, address])

  return (
    <DialogWrapper
      fullWidth={true}
      open={open}
      handleClose={close}
      title={form === 'new' ? <Trans>Create new address</Trans> : <Trans>Edit address</Trans>}
      maxWidth="sm"
    >
      <Form onSubmit={handleSubmit} initialValues={form === 'new' ? {} : initValues}>
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit}>
              <GridContainer spacing={4}>
                <GridItem xs={12}>
                  <Field
                    name="name"
                    label={<Trans>Address name</Trans>}
                    validate={required}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="street"
                    label={<Trans>Street</Trans>}
                    validate={required}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="postalCode"
                    label={<Trans>Postal code</Trans>}
                    validate={required}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="city"
                    label={<Trans>City</Trans>}
                    validate={required}
                    component={TextInput}
                  />
                </GridItem>
                <GridItem xs={12}>
                  <Field
                    name="addressType"
                    label={<Trans>Address description</Trans>}
                    validate={required}
                    component={SelectField}
                    options={ADDRESS_TYPES}
                  />
                </GridItem>
                <GridItem xs={12} pt={1}>
                  <DialogCreateFormButtons
                    buttonText={form === 'new' ? <Trans>Create</Trans> : <Trans>Edit</Trans>}
                  />
                </GridItem>
              </GridContainer>
            </form>
          )
        }}
      </Form>
    </DialogWrapper>
  )
}

OrganizationAddressDialog.propTypes = {
  form: PropTypes.string,
  open: PropTypes.bool,
  close: PropTypes.func,
  handleSubmit: PropTypes.func,
  getOrganizationAddress: PropTypes.func,
  organizationAddress: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOrganizationAddress,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      organizationAddress: store.OrganizationAddress.data,
    }
  }, mapDispatchToProps)
)(OrganizationAddressDialog)
