import React from 'react'
import GridItem from 'component/global/common/grid/GridItem'
import WarningMessage from 'component/projectSpecific/common/WarningMessage'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import {SIMPLE_DATE_FORMAT_SPACED} from 'helper/global/constants'

const TopicWarningMessage = (props) => {
  const {topic} = props
  return (
    topic && (
      <GridItem xs={12}>
        <WarningMessage
          warningMessage={
            <Trans>
              {`You have selected topic ${topic.name}, which is valid from ${
                topic.from && moment(topic.from).format(SIMPLE_DATE_FORMAT_SPACED)
              } to ${
                topic.to && moment(topic.to).format(SIMPLE_DATE_FORMAT_SPACED)
              } The event cannot be carried out of this range. If another appointment is already full, the calendar will not allow you to select it.`}
            </Trans>
          }
        />
      </GridItem>
    )
  )
}

export default TopicWarningMessage
