import {MOCK_API, MOCK_PORT_MAPPING} from 'config/configConstant'
import {resolveEnvironmentParam} from 'helper/global/envParams'

const getServiceUrl = (name) => {
  if (process.env.NODE_ENV !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  const GATEWAY_URL = resolveEnvironmentParam('GATEWAY_URL')
  return `${GATEWAY_URL}/api/${name}`
}

const endpoints = {
  login: `${getServiceUrl('auth')}/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  forgottenPassword: `${getServiceUrl('auth')}/forgotten-password`,
  resetPassword: `${getServiceUrl('auth')}/reset-password`,
  myAccount: `${getServiceUrl('auth')}/my-account`,
  changePassword: `${getServiceUrl('auth')}/my-account/change-password`,
  groups: `${getServiceUrl('auth')}/groups`,
  organizations: `${getServiceUrl('events')}/organizations/`,
  myOrganization: `${getServiceUrl('events')}/my-organization/`,
  logo: `${getServiceUrl('events')}/logo`,
  topicsCalendar: `${getServiceUrl('events')}/topics/calendar/`,
  topics: `${getServiceUrl('events')}/topics/`,
  proposals: `${getServiceUrl('events')}/proposals/`,
  events: `${getServiceUrl('events')}/events/`,
  statistics: `${getServiceUrl('events')}/statistics`,
  dateAvailability: `${getServiceUrl('events')}/date-availability`,
  roles: `${getServiceUrl('auth')}/roles`,
  calendar: `${getServiceUrl('events')}/events/calendar`,
  users: `${getServiceUrl('auth')}/users/`,
  isTimeFree: `${getServiceUrl('events')}/events/is-time-free`,
  satisfaction: `${getServiceUrl('events')}/satisfaction`,
  print: `${getServiceUrl('print')}`,
}

export default endpoints
