import {
  BORDER_RADIUS,
  dangerColor,
  infoColor,
  successColor,
  warningColor,
} from 'asset/global/jss/styleHelpers'

const globalToastStyle = () => ({
  containerStyle: {
    '& .Toastify__toast--success': {
      backgroundColor: successColor[0],
    },
    '& .Toastify__toast--warning': {
      backgroundColor: warningColor[0],
    },
    '& .Toastify__toast--info': {
      backgroundColor: infoColor[0],
    },
    '& .Toastify__toast--error': {
      backgroundColor: dangerColor[0],
    },
    '& .Toastify__toast': {
      height: 'auto',
      minHeight: '48px',
      borderRadius: BORDER_RADIUS,
      padding: '12px 15px',
      fontSize: '12px',
      color: 'white',
      '& svg': {
        fill: 'white !important',
      },
    },
    '& .Toastify__toast-container': {
      minWidth: '400px',
    },
    '& .Toastify__toast-body': {
      '& strong': {
        maxWidth: 250,
        width: 250,
        wordBreak: 'break-word',
        whiteSpace: 'pre-wrap',
      },
    },
  },
})
export default globalToastStyle
