import React from 'react'
import {Tab, Tabs} from '@mui/material'
import {withStyles} from 'tss-react/mui'
import PropTypes from 'prop-types'
import globalTabsStyle from 'asset/global/jss/component/common/tab/globalTabsStyle'

const GlobalTabs = (props) => {
  const {tabsValue, handleChangeTab, tabList, classes} = props
  return (
    <Tabs
      value={tabsValue.toString()}
      onChange={handleChangeTab}
      indicatorColor="primary"
      textColor="primary"
      className={classes.tabs}
    >
      {tabList.map((tab, index) => (
        <Tab value={tab.value} key={index} label={tab.label} index={index} />
      ))}
    </Tabs>
  )
}

Tabs.propTypes = {
  classes: PropTypes.object,
  tabsValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleChangeTab: PropTypes.func,
  tabList: PropTypes.array,
}

export default withStyles(GlobalTabs, globalTabsStyle)
