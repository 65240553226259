import React from 'react'
import {withStyles} from 'tss-react/mui'
import warningMessageStyle from 'asset/projectSpecific/jss/common/warningMessageStyle'
import GridItem from 'component/global/common/grid/GridItem'
import {WarningRounded} from '@mui/icons-material'

const WarningMessage = (props) => {
  const {warningMessage, classes} = props
  return (
    <GridItem xs={12} className={classes.warningMessageWrapper}>
      <div className={classes.warningMessage}>
        <div className={classes.toast}>
          <WarningRounded className={classes.toastIcon} />
          <strong>{warningMessage}</strong>
        </div>
      </div>
    </GridItem>
  )
}

export default withStyles(WarningMessage, warningMessageStyle)
