import React from 'react'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'
import {withStyles} from 'tss-react/mui'

const DetailItemWrapper = (props) => {
  const {classes, children} = props

  return <div className={classes.detailItemWrapper}>{children}</div>
}

export default withStyles(DetailItemWrapper, globalWrapperStyles)
