import {
  blackColor,
  BORDER_RADIUS,
  dangerCardHeader,
  dangerColor,
  grayColor,
  hexToRgb,
  infoCardHeader,
  infoColor,
  primaryCardHeader,
  primaryColor,
  successCardHeader,
  successColor,
  warningCardHeader,
  warningColor,
  whiteColor,
} from 'asset/global/jss/styleHelpers'

const globalCardStyle = {
  card: {
    border: '0',
    marginBottom: '30px',
    marginTop: '30px',
    borderRadius: `2 * ${BORDER_RADIUS}`,
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.87)',
    background: whiteColor,
    width: '100%',
    minHeight: '70px',
    boxShadow: '0 1px 4px 0 rgba(' + hexToRgb(blackColor) + ', 0.14)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem',
  },
  primary: {
    background: 'linear-gradient(60deg,' + primaryColor[0] + ',' + primaryColor[0] + ')',
    '& h1 small': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    },
    color: whiteColor,
  },
  info: {
    background: 'linear-gradient(60deg,' + infoColor[1] + ',' + infoColor[4] + ')',
    '& h1 small': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    },
    color: whiteColor,
  },
  success: {
    background: 'linear-gradient(60deg,' + successColor[1] + ',' + successColor[4] + ')',
    '& h1 small': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    },
    color: whiteColor,
  },
  warning: {
    background: 'linear-gradient(60deg,' + warningColor[1] + ',' + warningColor[4] + ')',
    '& h1 small': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    },
    color: whiteColor,
  },
  danger: {
    background: 'linear-gradient(60deg,' + dangerColor[1] + ',' + dangerColor[4] + ')',
    '& h1 small': {
      color: 'rgba(' + hexToRgb(whiteColor) + ', 0.8)',
    },
    color: whiteColor,
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center',
  },
  cardBody: {
    padding: '0 20px 20px',
    flex: '1 1 auto',
    WebkitBoxFlex: '1',
    position: 'relative',
  },
  cardBodyProfile: {
    marginTop: '15px',
  },
  cardFooter: {
    padding: '10px',
    margin: '0 15px 10px',
    borderRadius: '0',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'transparent',
    borderTop: '1px solid' + grayColor[8],
  },
  cardFooterProfile: {
    marginTop: '-15px',
  },
  cardHeader: {
    padding: '20px',
    minHeight: '40px',
    marginBottom: '0',
    borderBottom: 'none',
    background: 'transparent',
    zIndex: '3 !important',
    '&:first-of-type': {
      borderRadius: 'calc(.25rem - 1px) calc(.25rem - 1px) 0 0',
    },
  },
  cardHeaderImage: {
    position: 'relative',
    padding: '0',
    zIndex: '1',
    '& img': {
      width: '100%',
      borderRadius: '6px',
      pointerEvents: 'none',
      boxShadow:
        '0 5px 15px -8px rgba(' +
        hexToRgb(blackColor) +
        ', 0.24), 0 8px 10px -5px rgba(' +
        hexToRgb(blackColor) +
        ', 0.2)',
    },
    '& a': {
      display: 'block',
    },
  },
  cardHeaderIcon: {
    color: whiteColor,
    '& svg': {
      width: '24px',
      height: '24px',
      textAlign: 'center',
      lineHeight: '33px',
      margin: '5px 4px 0px',
    },
  },
  cardIcon: {
    borderRadius: BORDER_RADIUS,
    backgroundColor: grayColor[0],
    padding: '15px',
    position: 'absolute',
    top: '-15px',
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
}

export default globalCardStyle
