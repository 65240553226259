import {UPDATE_FILTERS_STATE} from 'redux/action/global/types'
import moment from 'moment'

const initState = {
  topicsCalendarFilters: {
    year: moment().year(),
  },
  listOrganizationsFilters: {
    filter: null,
    orderDirection: 'asc',
    orderBy: 'name',
  },
  listOrganizationUsersFilters: {
    orderDirection: 'asc',
    orderBy: 'name',
  },
  listOrganizationAddressesFilters: {
    orderDirection: 'asc',
    orderBy: 'name',
  },
  listOrganizationContactsFilters: {
    orderDirection: 'asc',
    orderBy: 'name',
  },
  listEventsFilters: {
    filter: null,
    orderDirection: 'asc',
    orderBy: null,
  },
  listEventParticipantsFilters: {
    orderDirection: 'asc',
    orderBy: 'createdAt',
  },
  listProposalsFilters: {
    filter: null,
    orderDirection: 'desc',
    orderBy: 'createdAt',
  },
  statisticsFilters: {
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    period: 'thisMonth',
  },
}

export const AppFilterStatesReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_FILTERS_STATE) {
    return {
      ...state,
      ...action.data,
    }
  }
  return state
}
