import React, {useState} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMaxValue} from 'helper/projectSpecific/validations'
import GridContainer from 'component/global/common/grid/GridContainer'
import GridItem from 'component/global/common/grid/GridItem'
import DateTimeInput from 'component/global/common/formField/DateTimeInput'
import SpaceConfiguration from 'component/projectSpecific/proposal/proposalForm/SpaceConfiguration'
import {Box} from '@mui/material'
import GlobalButton from 'component/global/common/button/GlobalButton'
import WarningMessage from 'component/projectSpecific/common/WarningMessage'
import NumberInput from 'component/global/common/formField/NumberInput'
import InputAdornment from '@mui/material/InputAdornment'
import SelectField from 'component/global/common/formField/SelectField'
import {EVENT_ZONES} from 'helper/projectSpecific/constants'

const ProposalConfirmForm = (props) => {
  const {onSubmitConfirm, initialValues, timeFree} = props
  const [selected, setSelected] = useState(initialValues['spaceConfiguration'])

  const prepareInitialValues = () => {
    // minimal length of an event is 30minutes, so if the length is less than 12,
    // it means that the length is in hours and must be converted
    return {
      ...initialValues,
      length:
        initialValues.length >= 12
          ? Math.round(initialValues.length)
          : Math.round(initialValues.length * 60),
      preparationLength: Math.round(initialValues.preparationLength * 60),
    }
  }

  return (
    <Form onSubmit={onSubmitConfirm} initialValues={prepareInitialValues()}>
      {(formProps) => {
        const handleSelectConfigurations = (id) => () => {
          setSelected(id)
          formProps.form.change('spaceConfiguration', id)
        }

        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <WarningMessage
                  warningMessage={
                    <Trans>First specify space configuration and date of event.</Trans>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="from"
                  label={<Trans>Date and time of event</Trans>}
                  component={DateTimeInput}
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="length"
                  label={<Trans>Length of event</Trans>}
                  component={NumberInput}
                  type="number"
                  max={720}
                  min={30}
                  step={1}
                  validate={composeValidators(required, validateMaxValue(720))}
                  endAdornment={
                    <InputAdornment position="end">
                      <Trans>Min.</Trans>
                    </InputAdornment>
                  }
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="zone"
                  label={<Trans>Zone</Trans>}
                  component={SelectField}
                  options={EVENT_ZONES}
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="preparationLength"
                  label={<Trans>Length of preparation</Trans>}
                  component={NumberInput}
                  type="number"
                  max={720}
                  min={0}
                  step={1}
                  validate={validateMaxValue(720)}
                  endAdornment={
                    <InputAdornment position="end">
                      <Trans>Min.</Trans>
                    </InputAdornment>
                  }
                />
              </GridItem>

              {formProps.values.zone === 'EASY_EDU' && (
                <GridItem xs={12}>
                  <SpaceConfiguration
                    selected={selected}
                    selectConfigurations={handleSelectConfigurations}
                  />
                </GridItem>
              )}

              {!timeFree && (
                <GridItem xs={12}>
                  <WarningMessage
                    warningMessage={
                      <Trans>The time of this event overlaps with another event!</Trans>
                    }
                  />
                </GridItem>
              )}

              <GridItem xs={12}>
                <Box display="flex" justifyContent={'flex-end'} flexGrow={1}>
                  <GlobalButton type="submit" backgroundColor="primary">
                    <Trans>Confirm</Trans>
                  </GlobalButton>
                </Box>
              </GridItem>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

ProposalConfirmForm.propTypes = {
  onSubmitConfirm: PropTypes.func,
}

export default ProposalConfirmForm
