import React, {useContext} from 'react'
import PropTypes from 'prop-types'
import {AddCircle} from '@mui/icons-material'
import {Trans} from '@lingui/macro'
import AdminPageWrapper from 'component/global/common/wrapper/AdminPageWrapper'
import ContentAdminPageWrapper from 'component/global/common/wrapper/ContentAdminPageWrapper'
import {bindActionCreators, compose} from 'redux'
import {fireSuccessToast, redirectWithMessage} from 'helper/global/functions'
import {createEvent} from 'redux/action/projectSpecific/eventActions'
import {routesMap} from 'config/routesMap'
import EventStepper from 'component/projectSpecific/event/eventForm/EventStepper'
import {connect} from 'react-redux'
import {updateLocalLoader} from 'redux/action/global/loaderActions'
import {AbilityContext} from 'App/provider/authorizedAbility'
import {useNavigate} from 'react-router-dom'

const EventCreatePage = (props) => {
  const ability = useContext(AbilityContext)
  if (ability.cannot('create', 'Events')) {
    return redirectWithMessage(<Trans>You are not authorized to create event.</Trans>)
  }

  const {createEvent, updateLocalLoader} = props

  const navigate = useNavigate()

  const handleCreateEvent = (values) => {
    updateLocalLoader(true)
    createEvent(values)
      .then(() => {
        fireSuccessToast(<Trans>Event sent.</Trans>)
        navigate(routesMap.admin.calendar)
      })
      .finally(() => {
        updateLocalLoader(false)
      })
  }

  return (
    <AdminPageWrapper>
      <ContentAdminPageWrapper
        pageTitle={<Trans>Create new event</Trans>}
        iconPageHeader={<AddCircle />}
      >
        <EventStepper
          handleSubmitEvent={handleCreateEvent}
          handleBack={() => navigate(routesMap.admin.calendar)}
        />
      </ContentAdminPageWrapper>
    </AdminPageWrapper>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createEvent,
      updateLocalLoader,
    },
    dispatch
  )
}

EventCreatePage.propTypes = {
  createEvent: PropTypes.func,
}

export default compose(connect(null, mapDispatchToProps))(EventCreatePage)
