import React from 'react'
import PropTypes from 'prop-types'
import GridContainer from 'component/global/common/grid/GridContainer'
import {withStyles} from 'tss-react/mui'
import {globalWrapperStyles} from 'asset/global/jss/component/common/wrapper/globalWrapperStyles'

const AuthPageWrapper = (props) => {
  const {children, classes} = props

  return (
    <GridContainer justifyContent="center" alignItems="center" className={classes.authPageWrapper}>
      {children}
    </GridContainer>
  )
}

AuthPageWrapper.propTypes = {
  children: PropTypes.node,
}

export default withStyles(AuthPageWrapper, globalWrapperStyles)
