import React from 'react'
import {Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {composeValidators, required, validateMinValue} from 'helper/projectSpecific/validations'
import GridItem from 'component/global/common/grid/GridItem'
import {RR_CONSTANTS} from 'helper/projectSpecific/constants'
import MultiSelectField from 'component/global/common/formField/MultiSelectField'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import NumberInput from 'component/global/common/formField/NumberInput'

const WeeklyRecurrenceFields = ({havePartnerPermission}) => {
  return (
    <>
      <GridItem xs={12} sm={6}>
        <Field
          name={`rruleConfig.interval`}
          label={<Trans>Every X week(s)</Trans>}
          component={NumberInput}
          type="number"
          min={1}
          validate={composeValidators(required, validateMinValue(1))}
          disabled={havePartnerPermission}
        />
      </GridItem>

      <GridItem xs={12} sm={6}>
        <Field
          name={`rruleConfig.byweekday`}
          label={<Trans>Days</Trans>}
          component={MultiSelectField}
          options={RR_CONSTANTS.daysOptions}
          validate={required}
          renderValue={(selected) => {
            const sortByOrder = R.sortBy(R.prop('weekday'))
            let selectedToRender = sortByOrder(selected)
            selectedToRender = selectedToRender.map((item) => {
              return RR_CONSTANTS.dayByKey[item.toString()]
            })
            return selectedToRender.join(', ')
          }}
          disabled={havePartnerPermission}
        />
      </GridItem>
    </>
  )
}

WeeklyRecurrenceFields.propTypes = {
  havePartnerPermission: PropTypes.bool,
}

export default WeeklyRecurrenceFields
