import React from 'react'
import Cell from 'component/global/common/table/Cell'
import {TableRow, Tooltip} from '@mui/material'
import {Can} from 'App/provider/authorizedAbility'
import TableActionsButton from 'component/global/common/button/TableActionsButton'
import {Trans} from '@lingui/macro'
import {DeleteForever, Edit} from '@mui/icons-material'
import PropTypes from 'prop-types'
import {ADDRESS_TYPES} from 'helper/projectSpecific/constants'

const OrganizationAddressRow = (props) => {
  const {row, onEditClick, onDeleteClick} = props
  return (
    <TableRow>
      <Cell align="left">{row.name}</Cell>
      <Cell>{row.street}</Cell>
      <Cell>{row.postalCode}</Cell>
      <Cell>{row.city}</Cell>
      <Cell>{ADDRESS_TYPES.find((type) => type?.value === row?.addressType)?.label}</Cell>
      <Cell isActions={true} isRowAction>
        <Can I="update" an="Organizations">
          <TableActionsButton onClick={onEditClick(row)} backgroundColor="success">
            <Tooltip title={<Trans>Edit</Trans>}>
              <Edit />
            </Tooltip>
          </TableActionsButton>
        </Can>
        <Can I="delete" an="Organizations">
          <TableActionsButton onClick={onDeleteClick(row)} backgroundColor="warning">
            <Tooltip title={<Trans>Delete</Trans>}>
              <DeleteForever />
            </Tooltip>
          </TableActionsButton>
        </Can>
      </Cell>
    </TableRow>
  )
}

OrganizationAddressRow.propTypes = {
  row: PropTypes.object,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
}

export default OrganizationAddressRow
