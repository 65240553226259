import React from 'react'
import Pagination from 'component/global/common/table/Pagination'
import TableWrapper from 'component/global/common/wrapper/TableWrapper'
import PropTypes from 'prop-types'
import OrganizationContactRow from 'component/projectSpecific/organization/organizationList/OrganizationContactRow'
import {ORGANIZATION_CONTACTS_LIST_COLUMNS} from 'component/projectSpecific/organization/organizationDetail/_organizationDetailContactTableColumns'

const OrganizationContactDetailTab = (props) => {
  const {
    order,
    orderBy,
    contacts,
    requestSort,
    count,
    page,
    onChangePage,
    rowsPerOrganizationPage,
    onEditContactClick,
    onDeleteContactClick,
  } = props

  return (
    <TableWrapper
      onRequestSort={requestSort}
      columns={ORGANIZATION_CONTACTS_LIST_COLUMNS}
      orderDirection={order}
      orderBy={orderBy}
      tablePagination={
        <Pagination
          count={count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerOrganizationPage}
        />
      }
    >
      {contacts?.map((row) => (
        <OrganizationContactRow
          key={row.id}
          row={row}
          onEditClick={onEditContactClick}
          onDeleteClick={onDeleteContactClick}
        />
      ))}
    </TableWrapper>
  )
}

OrganizationContactDetailTab.propTypes = {
  onEditContactClick: PropTypes.func,
  onDeleteContactClick: PropTypes.func,
}

export default OrganizationContactDetailTab
