import React from 'react'
import {Business} from '@mui/icons-material'
import {getTime} from 'helper/projectSpecific/calendarFunctions'
import {EVENT_SPACE_CONFIGURATIONS, EVENT_ZONES} from 'helper/projectSpecific/constants'
import moment from 'moment'
import {TIME_FORMAT} from 'helper/global/constants'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import {t} from '@lingui/macro'
import i18nProvider from 'App/provider/i18n'

const CalendarDayProposal = (props) => {
  const {proposal, openEventDetail} = props

  const getPreparationFromTime = moment(proposal.from)
    .subtract(Math.round(proposal.preparationLength * 60), 'minute')
    .format(TIME_FORMAT)

  return (
    <div className="calendar-event-wrapper proposal-wrapper" onClick={openEventDetail(proposal)}>
      <div className="time-name">
        {!!proposal.preparationLength && <span>{`(${getPreparationFromTime})`}</span>}
        <span>{proposal?.from && getTime(proposal?.from)}</span>
        <span>{proposal?.name && proposal?.name}</span>
      </div>
      {proposal?.expiresInDays && (
        <div className="topic">
          <AccessTimeIcon />
          {`${i18nProvider._(t`Remains: `)} ${proposal?.expiresInDays}`}
        </div>
      )}
      <div className="organization">
        <Business />
        {proposal?.organizationName}
      </div>
      <div className="configuration">
        <span>
          {proposal.spaceConfiguration
            ? EVENT_SPACE_CONFIGURATIONS.find((config) => config.id === proposal.spaceConfiguration)
                .configuration
            : EVENT_ZONES.find((zone) => zone.value === proposal.zone)?.label}
        </span>
      </div>
    </div>
  )
}

export default CalendarDayProposal
